import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;

    > .image {
        padding: 32px;

        @media (max-width: 320px) {
            padding: 0.5rem;
        }
    }

    > .text {
        text-align: center;
        h3 {
            margin-bottom: 16px;
        }
    }

    > .button {
        padding: 32px;

        .icon {
            margin-left: 1rem;
        }
    }
`;
