import styled from 'styled-components';

export const Container = styled.div`
    h2 {
        white-space: pre-line;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 0;
        padding: 1rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 2rem;

        .svg-container {
            padding: 2rem 0;
        }

        > h2 {
            padding-top: 40px;
        }
    }

    .buttons {
        div:nth-child(1),
        div:nth-child(2) {
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 320px) {
        .close {
            padding-bottom: 0;
        }
        .content {
            .svg-container {
                padding-top: 0;
            }
            > h2 {
                padding-top: 0px;
            }
        }
        .buttons {
            padding-top: 0.5rem;

            div:nth-child(1),
            div:nth-child(2) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &.republish {
        .content {
            text-align: unset;
        }
        @media (max-width: 320px) {
            .close {
                padding-bottom: 0;
            }
            .content {
                > h2 {
                    padding-top: 1rem;
                }
            }
            .buttons {
                padding-top: 1rem;
            }
        }
    }
`;

export const UrlBoxSection = styled.div`
    width: 100%;
    background-color: var(--color-neutral-dark);
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;
`;
