import styled, { css } from 'styled-components';

type Props = {
    disabled?: boolean;
    block?: boolean;
    size: 'LG' | 'MD' | 'SM';
    type: 'DANGER' | 'INFO' | 'WHITE' | 'NEUTRAL' | 'OUTLINE' | 'LEMON';
    uppercase?: boolean;
    nonClickable?: boolean;
};

const sizesCss = {
    LG: css`
        padding: 1rem 1.5rem;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
    `,
    MD: css`
        padding: 0.5rem 1rem;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
    `,
    SM: css`
        padding: 0.5rem 1rem;
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 1rem;
    `,
};

const typesCss = {
    DANGER: css`
        background-color: var(--color-feedback-danger);
    `,
    INFO: css`
        background-color: var(--color-brand-blue);
    `,
    WHITE: css`
        background-color: var(--color-neutral-white);
        color: var(--color-neutral-darkness);
    `,
    NEUTRAL: css`
        background-color: var(--color-neutral-base);
    `,
    OUTLINE: css`
        background-color: transparent;
        border: 2px solid var(--color-neutral-light);
    `,
    LEMON: css`
        background-color: var(--color-lemon);
        color: var(--color-brand-blue);
    `,
};

export const Button = styled.div<Props>`
    cursor: ${(props) => !props.nonClickable && 'pointer'};
    cursor: ${(props) => props.disabled && 'not-allowed'};

    color: ${(props) =>
        props.disabled
            ? 'var(--color-neutral-base)'
            : 'var(--color-neutral-white)'};

    ${(props) => typesCss[props.type]};
    ${(props) => sizesCss[props.size]};

    background-color: ${(props) =>
        props.disabled && 'var(--color-neutral-dark)'};

    border-radius: 6.25rem;
    font-family: 'Inter', sans-serif;

    text-transform: ${(props) => props.uppercase && 'uppercase'};

    display: ${(props) => (props.block ? 'flex' : 'inline-block')};
    align-items: center;
    justify-content: center;
    display: flex;

    width: ${(props) => props.block && '100%'};
`;

export const LinkButton = styled.h6`
    cursor: pointer;
    color: var(--color-neutral-white);
    display: flex;
    justify-content: center;
`;
