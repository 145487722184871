import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../components/Button';
import Input from '../../../../components/Form/Input/input';
import Label from '../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import { AppState, useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import { Site } from '../../../../model/site';
import { api } from '../../../../services/api';
import CategoriesInputSelect from '../../components/CategoriesInputSelect';

const TitlePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();
    const { site, setSite } = useSite();

    const [title, setTitle] = useState<string>(site?.title || '');
    const [category, setCategory] = useState<string>(site?.category || '');

    const saveHandler = () => {
        const copySite = { ...site, title, category } as Site;
        setSite(copySite);

        const copyAppState = {
            ...appState,
            editingSiteModified: true,
        } as AppState;

        api.saveDraft(copyAppState.editingSiteId as string, copySite);
        setAppState(copyAppState);

        navigate('/editor/sections');
    };

    const backPageHandler = () => {
        navigate(-1);
    };

    useEffect(() => {
        setAppState({ ...appState, notRedirectOnEmptySites: true } as AppState);
         
    }, []);

    return (
        <div>
            <Header onClickBack={backPageHandler} />
            <Box>
                <h1>{t('pages.editor.title.title')}</h1>
            </Box>
            <Form>
                <Box>
                    <FormGroup>
                        <Input
                            type="text"
                            id="title"
                            maxLength={24}
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            label={t('pages.editor.title.form.title')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CategoriesInputSelect
                            id="category"
                            value={category}
                            onChange={setCategory}
                        />
                        <Label
                            htmlFor="category"
                            text={t('pages.editor.title.form.category')}
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <Button
                        onClick={() => saveHandler()}
                        type="INFO"
                        size="LG"
                        disabled={!title.length}
                    >
                        {t('pages.editor.title.form.button')}
                    </Button>
                </Box>
            </Form>
        </div>
    );
};

export default TitlePage;
