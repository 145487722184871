import { createGlobalStyle } from 'styled-components';
import reset from '../../styles/reset';

interface TailwindResetProps {
    children: React.ReactNode;
}

const ResetStyles = createGlobalStyle`
    ${reset}`;

export function TailwindReset({ children }: TailwindResetProps) {
    return (
        <>
            <ResetStyles />
            {children}
        </>
    );
}
