import { Navigate, Route, Routes } from 'react-router-dom';

import { Site } from '../../model/site';
import { SubscriptionDetailPage } from './pages/Detail';
import { FeaturesPage } from './pages/Features';
import { SubscriptionListPage } from './pages/List';
import { SiteSelection } from './pages/SiteSelection';
import { SuccessPage } from './pages/Success';

type Props = {
    setSite: (site: Site) => void;
};

export const SubscriptionPage = ({ setSite }: Props) => {
    return (
        <Routes>
            <Route path="features" element={<FeaturesPage />} />
            <Route path="selection" element={<SiteSelection />} />
            <Route path="success" element={<SuccessPage setSite={setSite} />} />
            <Route
                path="detail"
                element={<SubscriptionDetailPage setSite={setSite} />}
            />
            <Route path="list" element={<SubscriptionListPage />} />
            <Route
                path="*"
                element={<Navigate to="/subscription/features" />}
            />
        </Routes>
    );
};
