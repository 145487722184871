import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowRightIcon from '../../../../icons/ArrowRight';
import GiftIcon from '../../../../icons/Gift';
import { cn } from '../../../../utils/cn';

interface PremiumBannerProps {
    visible: boolean;
    draftAvailable: boolean;
}

export const PremiumBanner = ({
    visible,
    draftAvailable,
}: PremiumBannerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div
            className={cn('float-banner', {
                visible: visible,
                'draft-available': draftAvailable,
            })}
        >
            <button
                hidden={!visible}
                onClick={() => navigate('/subscription/features')}
            >
                <div className="content">
                    <div className="text-container">
                        <div className="svg-gift">
                            <GiftIcon />
                        </div>
                        <div className="text-content">
                            <h4>{t('components.bannerPremium.title')}</h4>
                            <p>{t('components.bannerPremium.description')}</p>
                        </div>
                    </div>

                    <ArrowRightIcon className="svg-arrow" />
                </div>
            </button>
        </div>
    );
};
