import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const AlignOptionsContainer = styled.div`
    display: flex;
`;

type AlignOptionContainerProps = {
    $active: boolean;
    $align: 'CENTER' | 'LEFT';
};

export const AlignOptionContainer = styled.div<AlignOptionContainerProps>`
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: 2px solid;
    border-color: ${(props) =>
        props.$active
            ? 'var(--color-neutral-base)'
            : 'var(--color-neutral-dark)'};

    border-radius: 12px;
    background-color: ${(props) =>
        props.$active && 'var(--color-neutral-base)'};

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    /* margin: 0 8px; */
    align-items: ${(props) => props.$align === 'CENTER' && 'center'};
    box-sizing: border-box;
    padding: 0 12px;

    :last-child {
        margin-left: 1rem;
    }

    span {
        width: 20px;
        height: 3px;
        background: var(--color-neutral-white);
        border-radius: 4px;
        display: inline-block;
        margin: 2px 0;

        &:nth-child(3) {
            width: 10px;
        }
    }
`;
