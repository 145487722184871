export const EmptyIcon = () => {
    return (
        <svg
            width="166"
            height="183"
            viewBox="0 0 166 183"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4097 150.118C122.3 150.118 153.83 118.588 153.83 79.5588C153.83 40.5292 122.3 9 83.4097 9C44.519 9 12.9897 40.5292 12.9897 79.5588C12.9897 118.588 44.519 150.118 83.4097 150.118Z"
                fill="#3A3F50"
            />
            <path
                d="M135.668 92.5697V134.139C135.668 142.776 128.644 149.8 119.893 149.8H46.8866C38.2502 149.8 31.226 142.891 31.1108 134.255C31.1108 134.139 31.1108 134.139 31.1108 134.024V92.5697C31.1108 92.4545 31.1108 92.4545 31.1108 92.3394C31.1108 92.1091 31.1108 91.8788 31.226 91.6485C31.3411 91.303 31.4563 91.0727 31.5714 90.7273L51.1472 46.303C51.8381 44.8061 53.3351 44 54.9472 44H111.717C113.329 44 114.711 44.8061 115.517 46.303L135.093 90.7273C135.208 90.9576 135.323 91.303 135.438 91.6485C135.668 91.8788 135.668 92.2242 135.668 92.5697Z"
                fill="#6F778E"
            />
            <g filter="url(#filter0_d_1770:10385)">
                <path
                    d="M135.668 92.5696V138.4C135.668 144.733 130.602 149.8 124.153 149.8H42.626C36.2927 149.8 31.1108 144.733 31.1108 138.4V92.3393C31.1108 92.109 31.1108 91.8787 31.226 91.6484H57.4805C61.3957 91.6484 64.6199 94.7575 64.6199 98.7878C64.6199 100.745 65.426 102.588 66.6927 103.854C68.0745 105.236 69.6866 105.927 71.7593 105.927H95.1351C99.0502 105.927 102.274 102.818 102.274 98.7878C102.274 96.8303 103.081 94.9878 104.347 93.7212C105.729 92.3393 107.341 91.6484 109.299 91.6484H135.438C135.668 91.8787 135.668 92.2242 135.668 92.5696Z"
                    fill="url(#paint0_linear_1770:10385)"
                />
            </g>
            <path
                d="M71.284 123.589C72.5511 123.589 73.5783 122.561 73.5783 121.294C73.5783 120.027 72.5511 119 71.284 119C70.0169 119 68.9897 120.027 68.9897 121.294C68.9897 122.561 70.0169 123.589 71.284 123.589Z"
                fill="#B8C5DA"
            />
            <path
                d="M94.1029 123.589C95.37 123.589 96.3972 122.561 96.3972 121.294C96.3972 120.027 95.37 119 94.1029 119C92.8358 119 91.8086 120.027 91.8086 121.294C91.8086 122.561 92.8358 123.589 94.1029 123.589Z"
                fill="#B8C5DA"
            />
            <path
                d="M86.414 138.657H79.2211C78.1049 138.657 77.1748 137.727 77.1748 136.611C77.1748 135.495 78.1049 134.564 79.2211 134.564H86.352C87.4681 134.564 88.3983 135.495 88.3983 136.611C88.4603 137.727 87.5301 138.657 86.414 138.657Z"
                fill="#B8C5DA"
            />
            <path
                d="M156.79 40.6C158.336 40.6 159.59 39.3464 159.59 37.8C159.59 36.2536 158.336 35 156.79 35C155.243 35 153.99 36.2536 153.99 37.8C153.99 39.3464 155.243 40.6 156.79 40.6Z"
                fill="#3A3F50"
            />
            <path
                d="M5.48974 128C7.97503 128 9.98975 125.985 9.98975 123.5C9.98975 121.015 7.97503 119 5.48974 119C3.00446 119 0.989746 121.015 0.989746 123.5C0.989746 125.985 3.00446 128 5.48974 128Z"
                fill="#3A3F50"
            />
            <path
                d="M149.19 27.4C152.062 27.4 154.39 25.0719 154.39 22.2C154.39 19.3281 152.062 17 149.19 17C146.318 17 143.99 19.3281 143.99 22.2C143.99 25.0719 146.318 27.4 149.19 27.4Z"
                fill="#3A3F50"
            />
            <path
                d="M86.991 26.5C103.327 38 104.49 59 103.327 68.4182C102.366 71.2868 101.405 74.4285 99.3456 76.6141C96.4629 80.029 91.2465 81.5316 86.991 80.4388C82.5982 79.346 79.0291 75.5213 78.2055 70.877C77.5191 68.0084 78.48 64.5935 80.951 62.8177C83.5592 61.1786 87.2655 61.5884 89.4619 63.6373C91.9328 65.6863 92.8938 68.828 92.7565 71.8332C92.6192 74.8383 91.521 77.8435 90.011 80.4388C86.5975 86.8204 85.1923 86.797 78.2055 97.278"
                stroke="#ABB5CC"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeDasharray="4 4"
            />
            <path
                d="M87.3642 17.3311C88.3722 19.0283 87.4216 21.0112 85.1007 21.6724C82.7836 22.579 81.074 23.1049 80.0698 21.6531C79.1863 20.076 80.5106 18.4536 82.0765 16.5808C83.88 14.2123 86.2354 15.7591 87.3642 17.3311Z"
                fill="#4D5368"
            />
            <path
                d="M76.8976 30.6761C78.6225 31.1315 81.0604 30.0998 81.1466 27.7665C81.2291 25.188 81.4438 23.2202 79.5943 22.6447C77.8695 22.1893 77.2772 23.5512 75.4737 25.9197C74.157 28.0328 74.9275 30.2258 76.8976 30.6761Z"
                fill="#4D5368"
            />
            <path
                d="M77.6013 20.7222C78.3219 19.7257 79.6654 19.33 80.6544 19.8005C81.0242 19.9156 81.5187 20.1509 81.7678 20.3912C83.7534 21.8229 84.6445 23.8906 83.8031 25.0123C82.9656 26.3793 80.6332 26.3045 78.893 24.8677C78.3947 24.3871 78.1455 24.1468 77.7718 23.7864C77.0206 22.8202 76.8807 21.7187 77.6013 20.7222C77.6013 20.7222 77.722 20.597 77.6013 20.7222Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_1770:10385"
                    x="9.11084"
                    y="80.6484"
                    width="148.558"
                    height="102.151"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1770:10385"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1770:10385"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1770:10385"
                    x1="83.3556"
                    y1="90.3033"
                    x2="83.3556"
                    y2="150.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FDFEFF" />
                    <stop offset="0.9964" stopColor="#ECF0F5" />
                </linearGradient>
            </defs>
        </svg>
    );
};
