import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

export const configureAxiosInterceptors = () => {
    axios.interceptors.request.use(
        async (config) => {
            if (
                !config.url?.startsWith('https://s3.') &&
                !config.url?.includes('static.keepo.io')
            ) {
                const { tokens } = await fetchAuthSession();
                if (config.headers && tokens?.accessToken) {
                    config.headers['Authorization'] =
                        tokens?.accessToken.toString();
                }
            }
            return config;
        },
        (e) => Promise.reject(e)
    );
};
