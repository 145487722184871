import { signInWithRedirect } from 'aws-amplify/auth';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQueryUrl } from '../../hooks/QueryUrl';
import { useSiteRef } from '../../hooks/SiteRef';
import SymbolKeepoIcon from '../../icons/SymbolKeepo';
import { AuthSocialButton } from '../../components/auth/authSocialButton';
import { CustomIcons } from '../../components/icons/custom';
import { SocialProof } from '../../components/socialProof';
import { Background } from '../../components/auth/background';

const valid_actions = ['plus', 'login', 'start'];

const LoginPage = () => {
    const { t } = useTranslation();
    const queryUrl = useQueryUrl();
    const { setRef } = useSiteRef();

    const action = (useQueryUrl().get('action') || 'start').toLowerCase();

    const messages = useMemo(() => {
        const safeAction = valid_actions.includes(action) ? action : 'start';
        return {
            title: t(`pages.login.${safeAction}.title`),
            description: t(`pages.login.${safeAction}.description`),
        };
    }, [action, t]);

    const startFFederatedLogin = async (provider: 'Google' | 'Facebook') => {
        await signInWithRedirect({
            provider: provider,
        });
    };

    useEffect(() => {
        setRef(queryUrl.get('ref'));
    }, [queryUrl, setRef]);

    return (
        <div className="auth-screen">
            <div className="content">
                <header>
                    <SymbolKeepoIcon />
                </header>
                <div className="space-y-8">
                    <div className="">
                        <h3 className="typography-h3 mb-1">{messages.title}</h3>
                        <p className="typography-p">{messages.description}</p>
                    </div>
                    <div className="flex flex-col gap-4">
                        <AuthSocialButton
                            onClick={() => startFFederatedLogin('Google')}
                        >
                            <CustomIcons.Google />
                            {t('pages.login.loginButton.google')}
                        </AuthSocialButton>
                        <AuthSocialButton
                            onClick={() => startFFederatedLogin('Facebook')}
                        >
                            <CustomIcons.Facebook />
                            {t('pages.login.loginButton.facebook')}
                        </AuthSocialButton>
                    </div>
                    <div>
                        <p className="typography-p text-shark-600">
                            {t('pages.login.legal.text')}{' '}
                            <Link
                                to="legal"
                                className="text-shark-400 underline underline-offset-2 hover:text-shark-300"
                            >
                                {t('pages.login.legal.linkText')}
                            </Link>
                        </p>
                    </div>
                </div>
                <footer className="">
                    <SocialProof />
                </footer>
            </div>
            <Background />
        </div>
    );
};

export default LoginPage;
