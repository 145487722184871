type Props = {
    width?: number;
    height?: number;
    color: string;
};

const ArrowUpIcon = ({ width = 25, height = 24, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.495 3.00001C12.2164 2.99865 11.9374 3.11267 11.737 3.34203L4.74697 11.342C4.38358 11.7579 4.42615 12.3897 4.84204 12.753C5.25793 13.1164 5.88967 13.0739 6.25306 12.658L11.5 6.6529V20C11.5 20.5523 11.9477 21 12.5 21C13.0523 21 13.5 20.5523 13.5 20V6.66998L18.7479 12.659C19.1119 13.0744 19.7437 13.1161 20.159 12.7521C20.5744 12.3881 20.6161 11.7563 20.2521 11.341L13.3276 3.43845C13.1477 3.17382 12.8441 3 12.5 3L12.495 3.00001Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowUpIcon;
