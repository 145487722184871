const key = 'post_login_page';

export const usePosLoginContinue = () => {
    return {
        getPageAndClear: (): string | undefined => {
            try {
                return localStorage.getItem(key) || undefined;
            } catch (e) {
                return undefined;
            } finally {
                localStorage.removeItem(key);
            }
        },
        setPage: (ref: string | undefined | null) => {
            if (ref) {
                localStorage.setItem(key, ref);
            }
        },
    };
};
