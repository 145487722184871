import { Box } from '../../../../components/Layout';
import {
    Container,
    HeaderBox,
    LoadingBox,
    LoadingCircle,
    Site,
    SitesSection,
} from './styles';
const LoadingState = () => {
    return (
        <Container>
            <HeaderBox>
                <LoadingBox />
                <LoadingBox />
                <LoadingBox />
            </HeaderBox>
            <Box>
                <LoadingBox />
                <SitesSection>
                    <Site>
                        <div>
                            <LoadingBox />
                            <LoadingBox />
                        </div>
                        <div>
                            <LoadingBox />
                            <div>
                                <LoadingCircle />
                                <LoadingCircle />
                            </div>
                        </div>
                    </Site>
                    <Site>
                        <div>
                            <LoadingBox />
                            <LoadingBox />
                        </div>
                        <div>
                            <LoadingBox />
                            <div>
                                <LoadingCircle />
                                <LoadingCircle />
                            </div>
                        </div>
                    </Site>
                </SitesSection>
            </Box>
        </Container>
    );
};

export default LoadingState;
