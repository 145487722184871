import styled from 'styled-components';

type Props = {
    hideLine?: boolean;
    hideBox?: boolean;
};

export const Container = styled.div<Props>`
    background: ${(props) => !props.hideBox && 'var(--color-neutral-darkness)'};

    position: fixed;
    bottom: 0;
    width: min(480px, 100%);
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const ContentSection = styled.div<Props>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 96px;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
`;

export const OutsiteComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -0.5rem;
    z-index: 9999;
`;
