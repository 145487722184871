import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../components/Button';

type Props = {
    connectTitle: string;
    onConnect: () => void;
    onDisconnect: () => void;
    status: 'CONNECTED' | 'DISCONNECTED';
};

export const IntegrationConnectionButton = ({
    connectTitle,
    onConnect,
    onDisconnect,
    status,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Button
            type={status === 'CONNECTED' ? 'WHITE' : 'INFO'}
            size="LG"
            onClick={status === 'CONNECTED' ? onDisconnect : onConnect}
        >
            {status === 'CONNECTED'
                ? t(
                      'pages.editor.form.form.storage.actions.disconnectIntegration.title'
                  )
                : connectTitle}
        </Button>
    );
};
