import styled from 'styled-components';

export const Container = styled.div`
    min-width: 38px;
    height: 16px;
    border-radius: 24px;
    color: var(--color-neutral-darkness);
    background-color: var(--color-lemon);

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &.disabled {
        color: var(--color-neutral-light);
        background-color: var(--color-neutral-base);
    }
`;
