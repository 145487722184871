import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from 'react-router-dom';

import { LoadingModal } from './components/LoadingModal';
import { ProtectedRoute } from './components/ProtectedRoute';
import ScrollTop from './components/ScrollTop';
import { AppContextProvider, AppState, defaultAppState } from './contexts/App';
import { SiteContextProvider } from './contexts/Site';
import { usePosLoginContinue } from './hooks/PosLogin';
import { useQueryUrl } from './hooks/QueryUrl';
import { useSiteRef } from './hooks/SiteRef';
import { Site } from './model/site';
import { AccountPage } from './pages/Account';
import EditorPage from './pages/Editor';
import { FeedbackPage } from './pages/Feedback';
import { HelpPage } from './pages/Help';
import { LegalTermsPage } from './pages/LegalTerms';
import LoginPage from './pages/Login';
import { OnboardingPage } from './pages/Onboarding';
import SettingsPage from './pages/Settings';
import { FacebookPixelPage } from './pages/Settings/pages/FacebookPixel';
import { GoogleAnalytics } from './pages/Settings/pages/GoogleAnalytics';
import { TagManager } from './pages/Settings/pages/TagManager';
import SitesPage from './pages/Sites';
import StatisticsPage from './pages/Statistics';
import { SubscriptionPage } from './pages/Subscription';
import { keepoInternalAnalyticsService } from './services/analytics';
import { api } from './services/api';
import * as authenticationService from './services/auth';

import { Container } from './components/Container';
import { TailwindReset } from './components/tailwindReset';

const MainAuthenticatedComponent = () => {
    const [nextPage, setNextPage] = useState<string>();
    const { getPageAndClear } = usePosLoginContinue();

    const loadUser = useCallback(async () => {
        const [user, sites] = await Promise.all([
            api.getUser(),
            api.getSites(),
        ]);

        keepoInternalAnalyticsService.notifyLoginEvent(user);
        const posLoginPage = getPageAndClear();

        setNextPage(
            posLoginPage
                ? posLoginPage
                : !user.onboardingCompleted && sites.length === 0
                  ? '/onboarding'
                  : '/sites'
        );
    }, [getPageAndClear]);

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    return !nextPage ? <LoadingModal /> : <Navigate to={nextPage} replace />;
};

type MainComponentProps = {
    authenticated: boolean;
};

const MainComponent = ({ authenticated }: MainComponentProps) => {
    const action = (useQueryUrl().get('action') || 'start').toLowerCase();
    const plusPlan = useQueryUrl().get('plan');
    const { setPage } = usePosLoginContinue();

    if (
        action == 'plus' &&
        plusPlan &&
        ['ANNUALLY', 'MONTHLY'].includes(plusPlan.toUpperCase())
    ) {
        setPage(`/subscription/selection?plan=${plusPlan.toUpperCase()}`);
    }
    return authenticated ? <MainAuthenticatedComponent /> : <LoginPage />;
};

function App() {
    const { i18n } = useTranslation();
    const { getRefAndClear } = useSiteRef();

    const [authenticated, setAuthenticated] = useState(false);
    const [loadingAuthenticatedState, setLoadingAuthenticationState] =
        useState(true);

    const [appState, setAppState] = useState<AppState>(defaultAppState);
    const [site, setSite] = useState<Site>();

    const handleAuthenticatedState = useCallback(async () => {
        const authenticated = await authenticationService.isAuthenticated();
        setAuthenticated(authenticated);
        if (authenticated) {
            await api.getInitialUser({
                defaultLang: i18n.language,
                ref: getRefAndClear(),
            });
        }
        setLoadingAuthenticationState(false);
    }, [getRefAndClear, i18n.language]);

    useEffect(() => {
        handleAuthenticatedState();
    }, [handleAuthenticatedState]);

    return loadingAuthenticatedState ? (
        <LoadingModal />
    ) : (
        <AppContextProvider value={{ appState, setAppState }}>
            <Router>
                <ScrollTop />
                <Routes>
                    <Route
                        path="legal"
                        element={
                            <Container>
                                <LegalTermsPage />
                            </Container>
                        }
                    />
                    <Route
                        path="onboarding"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <OnboardingPage setSite={setSite} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="sites"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <SitesPage setSite={setSite} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="account"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <AccountPage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="feedback"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <FeedbackPage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="help"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <HelpPage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="subscription/*"
                        element={
                            <ProtectedRoute authenticated={authenticated}>
                                <SubscriptionPage setSite={setSite} />
                            </ProtectedRoute>
                        }
                    />

                    {site && (
                        <Route
                            path="*"
                            element={
                                <SiteContextProvider
                                    value={{ site: site!, setSite }}
                                >
                                    <Routes>
                                        <Route
                                            path="editor/*"
                                            element={
                                                <ProtectedRoute
                                                    authenticated={
                                                        authenticated
                                                    }
                                                >
                                                    <EditorPage />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="analytics"
                                            element={
                                                <ProtectedRoute
                                                    authenticated={
                                                        authenticated
                                                    }
                                                >
                                                    <StatisticsPage />
                                                </ProtectedRoute>
                                            }
                                        />

                                        <Route
                                            path="settings/*"
                                            element={
                                                <ProtectedRoute
                                                    authenticated={
                                                        authenticated
                                                    }
                                                >
                                                    <Routes>
                                                        <Route
                                                            path="facebook-pixel"
                                                            element={
                                                                <ProtectedRoute
                                                                    authenticated={
                                                                        authenticated
                                                                    }
                                                                >
                                                                    <FacebookPixelPage />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="google-analytics"
                                                            element={
                                                                <ProtectedRoute
                                                                    authenticated={
                                                                        authenticated
                                                                    }
                                                                >
                                                                    <GoogleAnalytics />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="tag-manager"
                                                            element={
                                                                <ProtectedRoute
                                                                    authenticated={
                                                                        authenticated
                                                                    }
                                                                >
                                                                    <TagManager />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path=""
                                                            element={
                                                                <ProtectedRoute
                                                                    authenticated={
                                                                        authenticated
                                                                    }
                                                                >
                                                                    <SettingsPage />
                                                                </ProtectedRoute>
                                                            }
                                                        />{' '}
                                                        <Route
                                                            path="*"
                                                            element={
                                                                <ProtectedRoute
                                                                    authenticated={
                                                                        authenticated
                                                                    }
                                                                >
                                                                    <SettingsPage />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                    </Routes>
                                                </ProtectedRoute>
                                            }
                                        ></Route>
                                    </Routes>
                                </SiteContextProvider>
                            }
                        />
                    )}
                    <Route
                        path="/"
                        element={
                            <TailwindReset>
                                <MainComponent authenticated={authenticated} />
                            </TailwindReset>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <TailwindReset>
                                <MainComponent authenticated={authenticated} />
                            </TailwindReset>
                        }
                    />
                </Routes>
            </Router>
        </AppContextProvider>
    );
}

export default App;
