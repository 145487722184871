import { Site } from './site';

export type Theme = {
    id: string;
    primary: string;
    secondary: string;
    buttons: string;
    textButtons: string;
    editorPrimary?: string;
    cardColor: string;
    cardText: string;
    iconsColor: string;
    iconsBackground: string;
};

export const extraPredefinedTheme: Theme = {
    id: '10',
    primary: '#3E2E2E',
    secondary: '#C18D5D',
    buttons: '#C18D5D',
    textButtons: '#3E2E2E',
    cardColor: '#C18D5D',
    cardText: '#3E2E2E',
    iconsColor: '#3E2E2E',
    iconsBackground: '#C18D5D',
};

export const preDefinedThemes: Theme[] = [
    {
        id: '1',
        primary: '#FFFFFF',
        secondary: '#181A20',
        buttons: '#181A20',
        textButtons: '#FFFFFF',
        cardColor: '#181A20',
        cardText: '#FFFFFF',
        iconsColor: '#FFFFFF',
        iconsBackground: '#181A20',
    },
    {
        id: '6',
        primary: '#181A20',
        secondary: '#FFFFFF',
        buttons: '#FFFFFF',
        textButtons: '#181A20',
        editorPrimary: '#262A34',
        cardColor: '#FFFFFF',
        cardText: '#181A20',
        iconsColor: '#181A20',
        iconsBackground: '#FFFFFF',
    },
    {
        id: '4',
        primary: '#FDFFE7',
        secondary: '#FF4040',
        buttons: '#FF4040',
        textButtons: '#FDFFE7',
        cardColor: '#FF4040',
        cardText: '#FDFFE7',
        iconsColor: '#FDFFE7',
        iconsBackground: '#FF4040',
    },
    {
        id: '7',
        primary: '#FF4040',
        secondary: '#FFFFFF',
        buttons: '#FFFFFF',
        textButtons: '#FF4040',
        cardColor: '#FFFFFF',
        cardText: '#FF4040',
        iconsColor: '#FF4040',
        iconsBackground: '#FFFFFF',
    },
    {
        id: '5',
        primary: '#61174C',
        secondary: '#FFFFFF',
        buttons: '#FFFFFF',
        textButtons: '#61174C',
        cardColor: '#FFFFFF',
        cardText: '#61174C',
        iconsColor: '#61174C',
        iconsBackground: '#FFFFFF',
    },
    {
        id: '2',
        primary: '#1DA6A8',
        secondary: '#FFFFFF',
        buttons: '#FFFFFF',
        textButtons: '#1DA6A8',
        cardColor: '#FFFFFF',
        cardText: '#1DA6A8',
        iconsColor: '#1DA6A8',
        iconsBackground: '#FFFFFF',
    },
    {
        id: '8',
        primary: '#1B723D',
        secondary: '#FDD6D6',
        buttons: '#FDD6D6',
        textButtons: '#1B723D',
        cardColor: '#FDD6D6',
        cardText: '#1B723D',
        iconsColor: '#1B723D',
        iconsBackground: '#FDD6D6',
    },
    {
        id: '9',
        primary: '#FFEE51',
        secondary: '#030303',
        buttons: '#030303',
        textButtons: '#FFEE51',
        cardColor: '#030303',
        cardText: '#FFEE51',
        iconsColor: '#FFEE51',
        iconsBackground: '#030303',
    },
    {
        id: '3',
        primary: '#0F1D41',
        secondary: '#DCFFE6',
        buttons: '#DCFFE6',
        textButtons: '#0F1D41',
        cardColor: '#DCFFE6',
        cardText: '#0F1D41',
        iconsColor: '#0F1D41',
        iconsBackground: '#DCFFE6',
    },
];

const sanitizeTheme = (theme: Theme): Theme => {
    return {
        ...theme,
        cardColor: theme.secondary,
        cardText: theme.primary,
        iconsColor: theme.primary,
        iconsBackground: theme.secondary,
    };
};

const sanitizeCustomTheme = (site: Site): Theme => {
    return {
        id: 'custom',
        primary: site.theme.primary || preDefinedThemes[0].primary,
        secondary: site.theme.secondary || preDefinedThemes[0].secondary,
        textButtons: site.theme.textButtons || preDefinedThemes[0].textButtons,
        buttons: site.theme.buttons || preDefinedThemes[0].buttons,
        cardColor:
            site.theme.cardColor ||
            site.theme.secondary ||
            preDefinedThemes[0].cardColor,
        cardText:
            site.theme.cardText ||
            site.theme.primary ||
            preDefinedThemes[0].cardText,
        iconsColor:
            site.theme.iconsColor ||
            site.theme.primary ||
            preDefinedThemes[0].iconsColor,
        iconsBackground:
            site.theme.iconsBackground ||
            site.theme.secondary ||
            preDefinedThemes[0].iconsBackground,
    };
};

export const getThemeFromSite = (site: Site): Theme => {
    const theme = [...preDefinedThemes, extraPredefinedTheme].find(
        ({ id }) => id === site.theme.id
    );
    return theme ? sanitizeTheme(theme) : sanitizeCustomTheme(site);
};

export const getCustomThemeFromSite = (site: Site): Theme => {
    return site.theme.id === 'custom'
        ? sanitizeCustomTheme(site)
        : { ...preDefinedThemes[0], id: 'custom' };
};
