import styled from 'styled-components';

export const Label = styled.label`
    display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ span {
        background-color: var(--color-brand-blue);

        &:after {
            left: 21px;
        }
    }

    input:disabled ~ span {
        opacity: 0.5;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 40px;
        border-radius: 12px;
        background-color: var(--color-neutral-dark);
        transition: background-color 0.06s ease-in;

        &:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 5px;
            width: 14px;
            height: 14px;
            border-radius: 12px;
            background: var(--color-neutral-white);
            transition: left 0.06s ease-in;
        }
    }
`;
