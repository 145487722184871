type Props = {
    color: string;
};

const ContentIcon = ({ color }: Props) => {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2H13C13.5523 2 14 2.44772 14 3V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V3C2 2.44772 2.44772 2 3 2ZM0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V15C16 16.6569 14.6569 18 13 18H3C1.34315 18 0 16.6569 0 15V3ZM12 4H4V6H12V4ZM4 8H12V10H4V8ZM9 12H4V14H9V12Z"
                fill={color}
            />
        </svg>
    );
};

export default ContentIcon;
