import { Player } from '@keepoinc/site-page-react-component';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleSpinner } from '../../../../../../components/CircleSpinner';
import Input from '../../../../../../components/Form/Input/input';
import Label from '../../../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import SaveBar from '../../../../../../components/SaveBar';
import { SectionHelp } from '../../../../../../components/SectionHelp';
import { MediaSection } from '../../../../../../model/site';
import { useSection } from '../../../../hooks/Section';
import { AudioIcon } from '../../../Sections/icons/Audio';
import { VideoIcon } from '../../../Sections/icons/Video';
import { Container, PlayerContainer, PreviewContainer } from './styles';

type Props = {
    helpMessage: string;
    category: 'AUDIO' | 'VIDEO';
    headerPageTitle: string;
};

export const EmbeddedMediaPage = ({
    helpMessage,
    category,
    headerPageTitle,
}: Props) => {
    const { t } = useTranslation();

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<MediaSection>('media');

    const [hasErrorLink, setHasErrorLink] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const saveHandler = useCallback(() => {
        saveSection({ ...section, subType: category.toLowerCase() });
    }, [section, saveSection, category]);

    const updateLinkHandler = (value: string) => {
        setHasErrorLink(false);
        setSection({ ...section, link: value });
    };

    const onProviderNotFound = useCallback(() => {
        setHasErrorLink(true);
        setLoading(false);
    }, []);

    const onError = useCallback(() => {
        setHasErrorLink(true);
        setLoading(false);
    }, []);

    return (
        <Container>
            <Header
                title={headerPageTitle}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <SectionHelp message={helpMessage} />
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            type="text"
                            id="title"
                            value={section.pageTitle}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                            label={t(
                                'pages.editor.media.embeddedMedia.form.title'
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="text"
                            id="link"
                            value={section.link}
                            required
                            onChange={(e) => updateLinkHandler(e.target.value)}
                            label={t(
                                'pages.editor.media.embeddedMedia.form.url.label'
                            )}
                            infoMessage={
                                hasErrorLink && !!section.link
                                    ? t(
                                          'pages.editor.media.embeddedMedia.form.url.error'
                                      )
                                    : undefined
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        {section.link && (
                            <PlayerContainer hide={isLoading || hasErrorLink}>
                                <Player
                                    link={section.link}
                                    category={category}
                                    onUpdateLoading={setLoading}
                                    onError={onError}
                                    onProviderNotFound={onProviderNotFound}
                                />
                            </PlayerContainer>
                        )}
                        {(!section.link || isLoading || hasErrorLink) && (
                            <PreviewContainer>
                                {isLoading ? (
                                    <CircleSpinner size="40px" />
                                ) : category === 'VIDEO' ? (
                                    <VideoIcon
                                        color="var(--color-neutral-base)"
                                        width={67}
                                        height={56}
                                    />
                                ) : (
                                    <AudioIcon
                                        color="var(--color-neutral-base)"
                                        width={67}
                                        height={56}
                                    />
                                )}
                            </PreviewContainer>
                        )}
                        <Label text="Preview" htmlFor="" />
                    </FormGroup>
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    disabled={!section.link || hasErrorLink}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
        </Container>
    );
};
