export const EmptyIcon = () => {
    return (
        <svg
            width="166"
            height="161"
            viewBox="0 0 166 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4099 150.618C122.301 150.618 153.83 119.088 153.83 80.0588C153.83 41.0292 122.301 9.5 83.4099 9.5C44.5192 9.5 12.99 41.0292 12.99 80.0588C12.99 119.088 44.5192 150.618 83.4099 150.618Z"
                fill="#3A3F50"
            />
            <path
                d="M124.99 43.5C129.408 43.5 132.99 47.0817 132.99 51.5V130.317C120.337 142.897 102.817 150.5 83.49 150.5C64.3018 150.5 46.7821 142.758 33.99 130.317V51.5C33.99 47.0817 37.5717 43.5 41.99 43.5H124.99Z"
                fill="white"
            />
            <path
                d="M68.99 94.5C71.7514 94.5 73.99 92.2614 73.99 89.5C73.99 86.7386 71.7514 84.5 68.99 84.5C66.2286 84.5 63.99 86.7386 63.99 89.5C63.99 92.2614 66.2286 94.5 68.99 94.5Z"
                fill="#CED6E2"
            />
            <path
                d="M97.99 94.5C100.751 94.5 102.99 92.2614 102.99 89.5C102.99 86.7386 100.751 84.5 97.99 84.5C95.2286 84.5 92.99 86.7386 92.99 89.5C92.99 92.2614 95.2286 94.5 97.99 94.5Z"
                fill="#CED6E2"
            />
            <path
                d="M90.8568 100.585C90.3118 100.585 89.7668 100.767 89.4035 101.313C87.9502 103.313 85.7702 104.404 83.2268 104.404C80.8651 104.404 78.5035 103.313 77.2318 101.313C76.6868 100.404 75.5968 100.222 74.6884 100.949C73.7801 101.495 73.5984 102.585 74.3251 103.494C76.3234 106.404 79.7751 108.222 83.4085 108.222C87.0418 108.222 90.3118 106.404 92.4919 103.494C93.0369 102.585 92.8552 101.495 92.1285 100.949C91.5835 100.585 91.2202 100.585 90.8568 100.585Z"
                fill="#CED6E2"
            />
            <path
                d="M145.5 149.5C148.538 149.5 151 147.038 151 144C151 140.962 148.538 138.5 145.5 138.5C142.462 138.5 140 140.962 140 144C140 147.038 142.462 149.5 145.5 149.5Z"
                fill="#3A3F50"
            />
            <path
                d="M156.8 134.1C158.346 134.1 159.6 132.846 159.6 131.3C159.6 129.754 158.346 128.5 156.8 128.5C155.254 128.5 154 129.754 154 131.3C154 132.846 155.254 134.1 156.8 134.1Z"
                fill="#3A3F50"
            />
            <path
                d="M16.8 24.1C18.3464 24.1 19.6 22.8464 19.6 21.3C19.6 19.7536 18.3464 18.5 16.8 18.5C15.2536 18.5 14 19.7536 14 21.3C14 22.8464 15.2536 24.1 16.8 24.1Z"
                fill="#3A3F50"
            />
            <path
                d="M19.5 150.5C21.9853 150.5 24 148.485 24 146C24 143.515 21.9853 141.5 19.5 141.5C17.0147 141.5 15 143.515 15 146C15 148.485 17.0147 150.5 19.5 150.5Z"
                fill="#5E6272"
            />
            <path
                d="M160 41.5C161.657 41.5 163 40.1569 163 38.5C163 36.8431 161.657 35.5 160 35.5C158.343 35.5 157 36.8431 157 38.5C157 40.1569 158.343 41.5 160 41.5Z"
                fill="#5E6272"
            />
        </svg>
    );
};
