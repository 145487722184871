export const Step3Icon = () => {
    return (
        <svg
            width="209"
            height="203"
            viewBox="0 0 209 203"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M101.42 159.118C140.311 159.118 171.84 127.588 171.84 88.5588C171.84 49.5292 140.311 18 101.42 18C62.5292 18 31 49.5292 31 88.5588C31 127.588 62.5292 159.118 101.42 159.118Z"
                fill="#3A3F50"
            />
            <g filter="url(#filter0_d_1770:9893)">
                <path
                    d="M149.962 18.5001V159.889C149.962 162.808 147.582 165.195 144.675 165.195H65.2868C62.3789 165.195 60.0002 162.808 60.0002 159.889V18.5001C60.0002 15.5831 62.3789 13.1953 65.2868 13.1953H144.543C147.45 13.1953 149.962 15.5831 149.962 18.5001Z"
                    fill="white"
                />
                <circle cx="129" cy="34" r="11" fill="#E7EBF1" />
                <path
                    d="M100.693 37H71.3079C70.4906 37 70.0002 36.5122 70.0002 36.0244V32.9756C70.0002 32.3659 70.6541 32 71.3079 32H100.693C101.51 32 102 32.4878 102 32.9756V36.0244C102 36.6341 101.51 37 100.693 37Z"
                    fill="#E7EBF1"
                />
            </g>
            <path
                d="M141.212 148.62C141.212 151.008 140.286 153.13 138.7 154.722C137.114 156.313 135 157.241 132.62 157.241H76.5905C71.8332 157.241 68 153.396 68 148.62C68 146.233 68.9253 144.111 70.5107 142.52C72.0972 140.929 74.2118 140 76.5905 140H132.488C137.246 140 141.212 143.846 141.212 148.62Z"
                fill="#4B10FF"
            />
            <g filter="url(#filter1_d_1770:9893)">
                <rect
                    x="125"
                    y="86"
                    width="58"
                    height="30"
                    rx="8"
                    fill="white"
                />
            </g>
            <rect
                width="21.4116"
                height="21.4116"
                transform="translate(129.758 90)"
                fill="white"
            />
            <rect
                x="129.758"
                y="90"
                width="21.4116"
                height="21.4116"
                rx="5.40945"
                fill="#767B8F"
            />
            <path
                d="M145.97 97.6634H135.285C134.916 97.6634 134.617 97.3046 134.617 96.862C134.617 96.4193 134.916 96.0605 135.285 96.0605H145.97C146.339 96.0605 146.638 96.4193 146.638 96.862C146.638 97.3046 146.339 97.6634 145.97 97.6634Z"
                fill="white"
            />
            <path
                d="M135.285 101.67H145.97C146.339 101.67 146.638 101.312 146.638 100.869C146.638 100.426 146.339 100.068 145.97 100.068H135.285C134.916 100.068 134.617 100.426 134.617 100.869C134.617 101.312 134.916 101.67 135.285 101.67Z"
                fill="white"
            />
            <path
                d="M140.627 105.677H135.285C134.916 105.677 134.617 105.319 134.617 104.876C134.617 104.433 134.916 104.075 135.285 104.075H140.627C140.996 104.075 141.295 104.433 141.295 104.876C141.295 105.319 140.996 105.677 140.627 105.677Z"
                fill="white"
            />
            <path
                d="M172.415 97.6634H161.73C161.361 97.6634 161.062 97.3046 161.062 96.862C161.062 96.4193 161.361 96.0605 161.73 96.0605H172.415C172.784 96.0605 173.083 96.4193 173.083 96.862C173.083 97.3046 172.784 97.6634 172.415 97.6634Z"
                fill="#CED6E2"
            />
            <path
                d="M161.73 101.67H172.415C172.784 101.67 173.083 101.312 173.083 100.869C173.083 100.426 172.784 100.068 172.415 100.068H161.73C161.361 100.068 161.062 100.426 161.062 100.869C161.062 101.312 161.361 101.67 161.73 101.67Z"
                fill="#CED6E2"
            />
            <path
                d="M169.477 105.677H164.134C163.765 105.677 163.466 105.318 163.466 104.876C163.466 104.433 163.765 104.074 164.134 104.074H169.477C169.846 104.074 170.145 104.433 170.145 104.876C170.145 105.318 169.846 105.677 169.477 105.677Z"
                fill="#CED6E2"
            />
            <g filter="url(#filter2_d_1770:9893)">
                <rect
                    x="26"
                    y="53"
                    width="72"
                    height="72"
                    rx="8"
                    fill="white"
                />
            </g>
            <rect
                opacity="0.5"
                x="32"
                y="59"
                width="60"
                height="60"
                rx="4"
                fill="url(#paint0_linear_1770:9893)"
            />
            <path
                d="M87.0007 72.1602C91.4179 72.1602 95 68.5778 95 64.1612C95 59.7425 91.4179 56.1602 87.0007 56.1602C82.5817 56.1602 79 59.7425 79 64.1612C79 68.5778 82.5817 72.1602 87.0007 72.1602Z"
                fill="#767B8F"
            />
            <path
                d="M87.0006 68C89.209 68 91 66.209 91 64C91 61.791 89.209 60 87.0006 60C84.7902 60 83 61.791 83 64C83 66.209 84.7902 68 87.0006 68Z"
                fill="#F1F4FC"
            />
            <path
                d="M167.077 32.1538C168.776 32.1538 170.154 30.7763 170.154 29.0769C170.154 27.3776 168.776 26 167.077 26C165.378 26 164 27.3776 164 29.0769C164 30.7763 165.378 32.1538 167.077 32.1538Z"
                fill="#373C4C"
            />
            <path
                d="M30.5 151C33.5376 151 36 148.538 36 145.5C36 142.462 33.5376 140 30.5 140C27.4624 140 25 142.462 25 145.5C25 148.538 27.4624 151 30.5 151Z"
                fill="#373C4C"
            />
            <defs>
                <filter
                    id="filter0_d_1770:9893"
                    x="34.9617"
                    y="0.67605"
                    width="140.039"
                    height="202.077"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="12.5193" />
                    <feGaussianBlur stdDeviation="12.5193" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1770:9893"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1770:9893"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_1770:9893"
                    x="99.9615"
                    y="73.4807"
                    width="108.077"
                    height="80.077"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="12.5193" />
                    <feGaussianBlur stdDeviation="12.5193" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1770:9893"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1770:9893"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_1770:9893"
                    x="0.961475"
                    y="40.4807"
                    width="122.077"
                    height="122.077"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="12.5193" />
                    <feGaussianBlur stdDeviation="12.5193" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1770:9893"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1770:9893"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1770:9893"
                    x1="96"
                    y1="124"
                    x2="29"
                    y2="56.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#969EB5" />
                    <stop offset="1" stopColor="#EAEEF9" stopOpacity="0.5" />
                </linearGradient>
            </defs>
        </svg>
    );
};
