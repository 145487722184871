export const Step2Icon = () => {
    return (
        <svg
            width="166"
            height="160"
            viewBox="0 0 166 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4197 150.118C122.31 150.118 153.84 118.588 153.84 79.5588C153.84 40.5292 122.31 9 83.4197 9C44.529 9 12.9998 40.5292 12.9998 79.5588C12.9998 118.588 44.529 150.118 83.4197 150.118Z"
                fill="#3A3F50"
            />
            <path
                d="M5.49975 69C7.98503 69 9.99976 66.9853 9.99976 64.5C9.99976 62.0147 7.98503 60 5.49975 60C3.01447 60 0.999756 62.0147 0.999756 64.5C0.999756 66.9853 3.01447 69 5.49975 69Z"
                fill="#5E6272"
            />
            <path
                d="M50.6602 160C52.3595 160 53.7371 158.622 53.7371 156.923C53.7371 155.223 52.3595 153.846 50.6602 153.846C48.9608 153.846 47.5833 155.223 47.5833 156.923C47.5833 158.622 48.9608 160 50.6602 160Z"
                fill="#3A3F50"
            />
            <path
                d="M160.889 50.7781C163.037 50.7781 164.778 49.0369 164.778 46.8891C164.778 44.7412 163.037 43 160.889 43C158.741 43 157 44.7412 157 46.8891C157 49.0369 158.741 50.7781 160.889 50.7781Z"
                fill="#3A3F50"
            />
            <path
                d="M159.5 135C162.537 135 165 132.538 165 129.5C165 126.462 162.537 124 159.5 124C156.462 124 154 126.462 154 129.5C154 132.538 156.462 135 159.5 135Z"
                fill="#5E6272"
            />
            <path
                d="M130.144 52.5295H9.7222C7.08319 52.5295 4.99976 50.446 4.99976 47.807V20.7224C4.99976 18.0834 7.08319 16 9.7222 16H130.006C132.645 16 134.728 18.0834 134.728 20.7224V47.807C134.867 50.446 132.645 52.5295 130.144 52.5295Z"
                fill="white"
            />
            <rect
                x="13.55"
                y="24.542"
                width="20.2874"
                height="20.2874"
                rx="3.51648"
                fill="#CED6E2"
            />
            <path
                d="M75.4528 36.643H43.7026C42.8757 36.643 42.3796 36.0874 42.3796 35.5318V32.0594C42.3796 31.3649 43.0411 30.9482 43.7026 30.9482H75.4528C76.2796 30.9482 76.7757 31.5038 76.7757 32.0594V35.5318C76.7757 36.2263 76.2796 36.643 75.4528 36.643Z"
                fill="#CED6E2"
            />
            <path
                d="M155.5 98.007H35.2166C32.5776 98.007 30.4941 95.9236 30.4941 93.2846V66.2C30.4941 63.561 32.5776 61.4775 35.2166 61.4775H155.5C158.139 61.4775 160.222 63.561 160.222 66.2V93.2846C160.361 95.9236 158.139 98.007 155.5 98.007Z"
                fill="white"
            />
            <rect
                x="39.0361"
                y="70.0537"
                width="20.2874"
                height="20.2874"
                rx="3.51648"
                fill="#CED6E2"
            />
            <path
                d="M130.683 83H69.2979C68.4806 83 67.9902 82.5122 67.9902 82.0244V78.9756C67.9902 78.3659 68.6441 78 69.2979 78H130.683C131.5 78 131.99 78.4878 131.99 78.9756V82.0244C131.99 82.6341 131.5 83 130.683 83Z"
                fill="#CED6E2"
            />
            <path
                d="M137.144 143.967H16.7222C14.0832 143.967 11.9998 141.884 11.9998 139.245V112.16C11.9998 109.521 14.0832 107.438 16.7222 107.438H137.006C139.645 107.438 141.728 109.521 141.728 112.16V139.245C141.867 141.884 139.645 143.967 137.144 143.967Z"
                fill="white"
            />
            <rect
                x="20.55"
                y="116.369"
                width="20.2874"
                height="20.2874"
                rx="3.51648"
                fill="#CED6E2"
            />
            <path
                d="M103.605 130H50.3746C49.5092 130 48.99 129.415 48.99 128.829V125.171C48.99 124.439 49.6823 124 50.3746 124H103.605C104.471 124 104.99 124.585 104.99 125.171V128.829C104.99 129.561 104.471 130 103.605 130Z"
                fill="#CED6E2"
            />
            <path
                d="M151.033 20.7847C150.682 22.2792 150.154 23.8617 149.539 25.1803C147.869 28.4331 145.231 30.9825 141.979 32.6529C138.638 34.3232 134.682 35.0265 130.726 34.1474C121.407 32.2133 115.429 23.0705 117.363 13.7518C119.297 4.43309 128.352 -1.63284 137.671 0.389137C141.012 1.09243 143.913 2.76276 146.374 5.04847C150.506 9.18034 152.264 15.1584 151.033 20.7847Z"
                fill="#4B10FF"
            />
            <path
                d="M139.693 15.7738H135.737V11.8178C135.737 11.0265 135.122 10.3232 134.242 10.3232C133.451 10.3232 132.748 10.9386 132.748 11.8178V15.7738H128.792C128.001 15.7738 127.297 16.3892 127.297 17.2683C127.297 18.1474 127.913 18.7628 128.792 18.7628H132.748V22.7188C132.748 23.5101 133.363 24.2134 134.242 24.2134C135.034 24.2134 135.737 23.598 135.737 22.7188V18.7628H139.693C140.484 18.7628 141.187 18.1474 141.187 17.2683C141.187 16.3892 140.484 15.7738 139.693 15.7738Z"
                fill="white"
            />
        </svg>
    );
};
