export const EditPencilIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20" cy="20" r="20" fill="#3A3F50" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.861 15.5961L26.9757 14.4813L24.5187 12.0243L23.4039 13.139L25.861 15.5961ZM24.4542 17.0029L21.9971 14.5458L12.8057 23.7373L12.2401 26.7599L15.2627 26.1943L24.4542 17.0029ZM28.4419 13.134C29.186 13.8781 29.186 15.0846 28.4419 15.8287L16.4517 27.8189C16.3099 27.9607 16.1285 28.0564 15.9313 28.0933L11.1782 28.9826C10.4908 29.1112 9.88885 28.5092 10.0174 27.8218L10.9067 23.0687C10.9436 22.8715 11.0393 22.6901 11.1811 22.5483L23.1713 10.5581C23.9154 9.81397 25.1219 9.81397 25.866 10.5581L28.4419 13.134ZM28.0099 29H19.9901C18.67 29 18.67 27 19.9901 27H28.0099C29.33 27 29.33 29 28.0099 29Z"
                fill="white"
            />
        </svg>
    );
};
