import { useTranslation } from 'react-i18next';

import { MediumCircleContainer } from '../../../../components/CircleContainer/styles';
import PlusIcon from '../../../../icons/Plus';
import { Container } from './styles';

type Props = {
    onAdd: () => void;
};

export const Footer = ({ onAdd }: Props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <div className="new-site-container">
                <MediumCircleContainer
                    title={t('pages.sites.actions.createNew.button.title')}
                    $color="var(--color-brand-blue)"
                    cursor="pointer"
                    className="button"
                    onClick={onAdd}
                >
                    <PlusIcon
                        width={16}
                        height={16}
                        color="var(--color-neutral-white)"
                    />
                </MediumCircleContainer>
            </div>
        </Container>
    );
};
