type Props = {
    color: string;
    height?: number;
    width?: number;
};

export const VideoIcon = ({ color, width = 24, height = 24 }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5C9.52285 5 7.40237 5.177 5.79332 5.37813C4.49282 5.54069 3.50429 6.51403 3.32102 7.81553C3.14238 9.08418 3 10.5541 3 12C3 13.4459 3.14238 14.9158 3.32102 16.1845C3.50429 17.486 4.49282 18.4593 5.79332 18.6219C7.40238 18.823 9.52285 19 12 19C14.4771 19 16.5976 18.823 18.2067 18.6219C19.5072 18.4593 20.4957 17.486 20.679 16.1845C20.8576 14.9158 21 13.4459 21 12C21 10.5541 20.8576 9.08418 20.679 7.81553C20.4957 6.51403 19.5072 5.54069 18.2067 5.37813C16.5976 5.177 14.4771 5 12 5ZM5.54525 3.39358C3.34649 3.66842 1.64953 5.34244 1.34056 7.53666C1.15369 8.86378 1 10.4319 1 12C1 13.5681 1.15369 15.1362 1.34056 16.4633C1.64953 18.6576 3.34649 20.3316 5.54525 20.6064C7.22455 20.8163 9.42861 21 12 21C14.5714 21 16.7755 20.8163 18.4547 20.6064C20.6535 20.3316 22.3505 18.6576 22.6594 16.4633C22.8463 15.1362 23 13.5681 23 12C23 10.4319 22.8463 8.86378 22.6594 7.53666C22.3505 5.34244 20.6535 3.66842 18.4547 3.39358C16.7755 3.18367 14.5714 3 12 3C9.4286 3 7.22455 3.18367 5.54525 3.39358Z"
                fill={color}
            />
            <path
                d="M10.5301 7.95621L15.6433 11.152C16.2699 11.5436 16.2699 12.4563 15.6433 12.848L10.5301 16.0437C9.86403 16.46 9.00008 15.9812 9.00008 15.1957L9.00008 8.80421C9.00008 8.01878 9.86403 7.53993 10.5301 7.95621Z"
                fill={color}
            />
        </svg>
    );
};
