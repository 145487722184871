import { ChartDataset } from 'chart.js';
import { MillifyOptions } from 'millify/dist/options';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsSummary } from '../../services/api/models';
import { getBarOptions } from './barOptions';

export type Metric = {
    type: 'access' | 'visitors' | 'clicks';
    title: string;
    listType: 'refferer' | 'locations' | 'clicks';
    listDescription: string;
};

export type Period = {
    type: 'sevenDays' | 'thirtyDays';
    title: string;
    days: number;
};

export type DataPresentation = {
    chart: {
        labels: string[];
        datasets: ChartDataset[];
    };
    list: {
        count: number;
        value: string;
    }[];
    bigNumbers: {
        count: number;
        variation: {
            value: number;
            positive: boolean;
        };
    };
};

const isPtLang = (language: string) => language.toUpperCase().includes('PT');

export const useMillifyOptions = () => {
    const { t, i18n } = useTranslation();
    return useMemo(() => {
        return {
            units: [
                '',
                t('numbers.thousand'),
                t('numbers.millions'),
                t('numbers.billion'),
            ],
            space: true,
            decimalSeparator: isPtLang(i18n.language) ? ',' : '.',
        };
    }, [t, i18n]);
};

export const useBarOptions = (millifyOptions: Partial<MillifyOptions>) => {
    const { i18n } = useTranslation();
    const locale = isPtLang(i18n.language) ? 'pt-BR' : 'en-US';
    return useMemo(
        () => getBarOptions(locale, millifyOptions),
        [locale, millifyOptions]
    );
};

export const useMetrics = (): Metric[] => {
    const { t } = useTranslation();
    return useMemo(
        () => [
            {
                type: 'access',
                title: t('pages.analytics.metrics.access'),
                listType: 'refferer',
                listDescription: t('pages.analytics.listTable.refferer'),
            },
            {
                type: 'visitors',
                title: t('pages.analytics.metrics.visitors'),
                listType: 'locations',
                listDescription: t('pages.analytics.listTable.location'),
            },
            {
                type: 'clicks',
                title: t('pages.analytics.metrics.clicks'),
                listType: 'clicks',
                listDescription: t('pages.analytics.listTable.clicks'),
            },
        ],
        [t]
    );
};

export const usePeriods = (): Period[] => {
    const { t } = useTranslation();
    return useMemo(
        () => [
            {
                type: 'sevenDays',
                title: t('pages.analytics.periods.sevenDays'),
                days: 7,
            },
            {
                type: 'thirtyDays',
                title: t('pages.analytics.periods.thirtyDays'),
                days: 30,
            },
        ],
        [t]
    );
};

export const useDataPresentation = (
    metrics: Metric[],
    periods: Period[],
    activeMetric: number,
    activePeriod: number,
    dataLoaded: boolean,
    loadingCallback: (loading: boolean) => void,
    summary?: AnalyticsSummary
): DataPresentation | undefined => {
    const { t } = useTranslation();
    const others = t('pages.analytics.words.others');
    return useMemo(() => {
        if (!dataLoaded) {
            return;
        }
        if (!summary) {
            loadingCallback(false);
            return undefined;
        }
        const metric = metrics[activeMetric];
        const period = periods[activePeriod];

        const days = summary.days.slice(0, period.days).reverse();
        const labels = days.map((day) => day.date.split('-')[2]);

        const datasets = [
            {
                data: days.map((day) => day[metric.type]),
                backgroundColor: '#a3ffc8',
                borderWidth: 0,
                minBarLength: 1,
            },
        ];

        const bigNumbers = summary.periods[period.type].bigNumbers[metric.type];

        const list = summary.periods[period.type][metric.listType].map(
            (item: { value: string; count: number }) => {
                return {
                    value: item.value || others,
                    count: item.count,
                };
            }
        );

        loadingCallback(false);

        return {
            chart: {
                labels,
                datasets,
            },
            list,
            bigNumbers,
        };
    }, [
        activeMetric,
        activePeriod,
        loadingCallback,
        dataLoaded,
        metrics,
        periods,
        summary,
        others,
    ]);
};
