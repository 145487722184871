import styled from 'styled-components';

export const Badge = styled.div`
    padding: 4px 16px;
    border-radius: 24px;
    color: var(--color-neutral-white);
    background-color: var(--color-neutral-base);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;

    text-align: center;
    justify-content: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const PremiumBadge = styled(Badge)`
    background-color: var(--color-lemon);
    color: var(--color-neutral-darkness);
`;
