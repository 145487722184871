type GetTokeFromModalProps = {
    url: string;
    target: string;
};

export class AuthenticationFlow {
    private listener: ((event: MessageEvent) => void) | undefined = undefined;

    getCodeFromModal({ url, target }: GetTokeFromModalProps): Promise<string> {
        this.removeListener();

        return new Promise((resolve) => {
            this.listener = this.createListener(resolve);
            window.addEventListener('message', this.listener);
            window.open(url, target, this.getModalProps());
        });
    }

    reset() {
        this.removeListener();
    }

    private createListener(resolve: (value: string) => void) {
        return (event: MessageEvent) => {
            const data = event.data as string;
            if (data.startsWith && data.startsWith('code:')) {
                window.removeEventListener(
                    'message',
                    this.listener as (event: MessageEvent) => void
                );
                resolve(data.replace('code:', ''));
            }
        };
    }

    private removeListener() {
        if (this.listener) {
            window.removeEventListener('message', this.listener);
        }
    }

    private getModalProps() {
        const width = 700;
        const height = 700;

        const left = screen.width / 2 - width / 2;
        const top = screen.height / 2 - height / 2;

        return `toolbar=no, location=no, 
                directories=no, status=no, menubar=no, 
                scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    }
}

export const authenticationFlow = new AuthenticationFlow();
