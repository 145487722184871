import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../../../components/Button';
import { MediumCircleContainer } from '../../../../../../components/CircleContainer/styles';
import FixedFooterBar from '../../../../../../components/FixedFooterBar';
import * as Fonts from '../../../../../../components/Fonts';
import { LoadingModal } from '../../../../../../components/LoadingModal';
import { useAppState } from '../../../../../../contexts/App';
import { useSite } from '../../../../../../contexts/Site';
import BackIcon from '../../../../../../icons/Back';
import InkIcon from '../../../../../../icons/Ink';
import { Site } from '../../../../../../model/site';
import { api } from '../../../../../../services/api';
import { FooterButtonsSection, TextedCircleContainer } from './styles';

const FixedBar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { appState } = useAppState();
    const { site } = useSite();

    const [isPublishing, setPublishing] = useState(false);

    const continueHandler = async () => {
        if (appState?.published) {
            setPublishing(true);
            try {
                await api.publish(
                    appState.editingSiteId as string,
                    site as Site
                );
                navigate('/editor/publish/success?first=false');
            } catch (e) {
                setPublishing(false);
            }
        } else {
            navigate('/editor/publish');
        }
    };

    return (
        <div style={{ position: 'fixed', zIndex: 9999 }}>
            {isPublishing && <LoadingModal />}
            <FixedFooterBar>
                <FooterButtonsSection>
                    <div>
                        <TextedCircleContainer>
                            <MediumCircleContainer
                                $color="var(--color-neutral-white)"
                                cursor="pointer"
                                onClick={() => navigate(-1)}
                            >
                                <BackIcon color="var(--color-neutral-darkness)" />
                            </MediumCircleContainer>
                            <Fonts.Label>
                                {t(
                                    'pages.editor.preview.footer.actions.sections'
                                )}
                            </Fonts.Label>
                        </TextedCircleContainer>
                        <TextedCircleContainer>
                            <MediumCircleContainer
                                $color="var(--color-neutral-white)"
                                cursor="pointer"
                                onClick={() => navigate('/editor/style')}
                            >
                                <InkIcon />
                            </MediumCircleContainer>
                            <Fonts.Label>
                                {t(
                                    'pages.editor.preview.footer.actions.settings'
                                )}
                            </Fonts.Label>
                        </TextedCircleContainer>
                    </div>
                    <div>
                        <Button
                            type="INFO"
                            block
                            size="LG"
                            onClick={() => continueHandler()}
                        >
                            {isPublishing
                                ? t(
                                      'pages.editor.preview.footer.actions.publishing'
                                  )
                                : appState?.published
                                  ? t(
                                        'pages.editor.preview.footer.actions.publish'
                                    )
                                  : t(
                                        'pages.editor.preview.footer.actions.continue'
                                    )}
                        </Button>
                    </div>
                </FooterButtonsSection>
            </FixedFooterBar>
        </div>
    );
};

export default FixedBar;
