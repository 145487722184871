type Props = {
    color: string;
};

const ChartIcon = ({ color }: Props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2H14C15.1046 2 16 2.89543 16 4V11H20C21.1046 11 22 11.8954 22 13V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V8.5C2 7.39543 2.89543 6.5 4 6.5H8V4C8 2.89543 8.89543 2 10 2ZM10 20H14V4H10V20ZM8 8.5H4V20H8V8.5ZM16 20H20V13H16V20Z"
                fill={color}
            />
        </svg>
    );
};

export default ChartIcon;
