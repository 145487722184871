const removeSlashAndAtSignAndSpace = (value: string) => {
    return value.replace('/', '').replace('@', '').trim();
};

export const sanitizeSocialLink = (socialPath: string, link?: string) => {
    if (!link || link.startsWith('http') || link.startsWith('www')) {
        return link;
    }
    if (link.includes(socialPath)) {
        return `https://${link}`;
    }
    return `https://${socialPath}${removeSlashAndAtSignAndSpace(link)}`;
};

export const socialPaths = {
    facebook: 'facebook.com/',
    instagram: 'instagram.com/',
    twitter: 'twitter.com/',
    youtube: 'youtube.com/channel/',
    linkedin: 'linkedin.com/in/',
    behance: 'behance.net/',
    dribbble: 'dribbble.com/',
    medium: 'medium.com/@',
    twitch: 'twitch.tv/',
    tiktok: 'tiktok.com/@',
    vimeo: 'vimeo.com/',
};
