import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from '../../../../components/radixTabs';
import { useTranslation } from 'react-i18next';
import constants from '../../../../constants';

interface CustomTabsTriggerProps
    extends React.ComponentProps<typeof TabsTrigger> {}

const CustomTabsTrigger = ({ ...rest }: CustomTabsTriggerProps) => (
    <TabsTrigger
        className="group w-full rounded-full py-2 text-center uppercase data-[state=active]:bg-shark-950 data-[state=inactive]:text-shark-950"
        {...rest}
    />
);

interface PriceValueProps extends React.ComponentProps<'h1'> {}

const PriceValue = ({ ...rest }: PriceValueProps) => (
    <h1 className="text-shark-950" {...rest} />
);

type PlanType = 'MONTHLY' | 'ANNUALLY';

interface BillingTabsProps {
    plan: PlanType;
    setPlan: React.Dispatch<React.SetStateAction<PlanType>>;
}

export function BillingTabs({ plan = 'ANNUALLY', setPlan }: BillingTabsProps) {
    const { t } = useTranslation();
    return (
        <Tabs
            defaultValue={plan}
            onValueChange={(value) => setPlan(value as PlanType)}
            value={plan}
            className="flex w-full flex-col items-center gap-8 px-6"
        >
            <TabsList className="flex w-full justify-between rounded-full font-inter text-xs font-bold ring-[1px] ring-shark-950">
                <CustomTabsTrigger value="ANNUALLY">
                    {t('pages.subscription.features.plans.annually.title')}
                    <span className="text-yellow-400 group-data-[state=inactive]:text-white">
                        {' '}
                        {constants.price.year.discount}
                    </span>
                </CustomTabsTrigger>
                <CustomTabsTrigger value="MONTHLY">
                    {t('pages.subscription.features.plans.monthly.title')}
                </CustomTabsTrigger>
            </TabsList>

            <TabsContent className="text-center" value="MONTHLY">
                <PriceValue>{constants.price.month.value}</PriceValue>
                <p className="font-bold text-shark-950">
                    {t('pages.subscription.features.plans.monthly.description')}
                </p>
            </TabsContent>
            <TabsContent className="text-center" value="ANNUALLY">
                <PriceValue>{constants.price.year.perMonth}</PriceValue>
                <p className="text-shark-950">
                    {t('pages.subscription.features.plans.annually.month')}{' '}
                    <span className="font-bold">{`${constants.price.year.value} ${t('pages.subscription.features.plans.annually.description')}`}</span>
                </p>
            </TabsContent>
        </Tabs>
    );
}
