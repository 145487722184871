import styled from 'styled-components';

type Props = {
    backgroundColor: string;
};

export const Card = styled.div<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 0.5rem;

    background-color: ${(props) => props.backgroundColor};
`;
