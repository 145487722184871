const GoogleDriveIcon = () => {
    return (
        <svg
            width="41"
            height="37"
            viewBox="0 0 41 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.0232 25.0696H13.0229L6.59961 36.335C7.37235 36.7295 8.24986 36.9825 8.8174 36.9825C14.7181 36.9825 26.7606 36.8948 32.7386 36.8948C33.3533 36.8948 33.9287 36.6844 34.4447 36.3548L28.0232 25.0696Z"
                fill="#4185F3"
            />
            <path
                d="M6.60064 36.3355L13.024 25.0701H0.00792604C0.00137742 25.8529 0.278602 26.5692 0.712994 27.3863C1.92318 29.6595 3.97071 33.2934 5.17347 35.1914C5.46991 35.6591 6.64691 36.3591 6.60064 36.3355Z"
                fill="#1767D1"
            />
            <path
                d="M28.0234 25.0697L34.4472 36.353C35.2797 35.8218 35.9582 34.9801 36.3987 34.2052C37.3932 32.4575 39.0749 29.5473 40.2348 27.577C40.774 26.6608 40.9836 25.8381 40.9705 25.0688L28.0234 25.0697Z"
                fill="#E94235"
            />
            <path
                d="M13.0249 25.0705L20.5174 11.9922L13.8212 0.782898C13.0969 1.26113 12.4774 1.91031 12.1963 2.40476C9.27341 7.55177 3.38533 18.1005 0.424485 23.3141C0.121092 23.8476 -0.0241684 24.457 0.00581031 25.0705H13.0249Z"
                fill="#30A753"
            />
            <path
                d="M28.0216 25.0705L20.5156 11.9922L27.028 0.795593C27.7523 1.27426 28.5473 1.9103 28.8284 2.40518C31.7513 7.55176 37.6394 18.1005 40.6015 23.3141C40.9071 23.8502 40.9993 24.4568 40.9696 25.0705H28.0216Z"
                fill="#F9BC00"
            />
            <path
                d="M13.8203 0.784624L20.5174 11.9921L27.0297 0.795583C26.3574 0.401075 25.4052 0.167439 24.4832 0.13807C21.9179 0.052155 17.7591 0.0175259 15.5208 0.115276C14.9689 0.139385 13.8273 0.779803 13.8203 0.784624V0.784624Z"
                fill="#0F8038"
            />
        </svg>
    );
};

export default GoogleDriveIcon;
