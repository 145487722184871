interface Props {
    color?: string;
}
const LinksIcon = ({ color }: Props) => {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33333 14C0.59696 14 0 13.5523 0 13L0 9C0 8.4477 0.59696 8 1.33333 8H14.6667C15.4031 8 16 8.4477 16 9V13C16 13.5523 15.4031 14 14.6667 14L1.33333 14ZM2 12L2 10H14V12H2ZM1.33333 6C0.59696 6 0 5.5523 0 5L0 1C0 0.447701 0.59696 0 1.33333 0L14.6667 0C15.4031 0 16 0.447701 16 1V5C16 5.5523 15.4031 6 14.6667 6L1.33333 6ZM2 4L2 2H14V4H2Z"
                fill={color}
            />
        </svg>
    );
};

export default LinksIcon;
