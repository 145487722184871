import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MicroCircleContainer } from '../../../../../../components/CircleContainer/styles';
import { useAppState } from '../../../../../../contexts/App';
import { useSite } from '../../../../../../contexts/Site';
import { useKeyPress } from '../../../../../../hooks/KeyPress';
import CloseIcon from '../../../../../../icons/Close';
import { Section } from '../../../../../../model/site';
import { stopScroll } from '../../../../../../utils/modal';
import { classNames } from '../../../../../../utils/styles';
import { availableSections, SectionDefinition } from '../../sections';
import IconStatusCard from '../IconStatusCard';
import { Background, Body } from './styles';

const SectionLine = ({ type, subType, multiple, pro }: SectionDefinition) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sections = useSite().site?.sections as Section[];
    const { appState } = useAppState();

    const isAlreadyUsed = ({ type, subType }: SectionDefinition) =>
        sections.find(
            (section) => section.type === type && section.subType === subType
        );
    const safeSubType = subType ? `.${subType}` : '';
    const safeSubTypePath = subType ? `/${subType}` : '';
    const active =
        (multiple && appState.editingSiteSubscription.type === 'PLUS') ||
        !isAlreadyUsed({ type, subType, multiple, pro });

    return (
        <div
            onClick={() => {
                if (active) {
                    navigate(`/editor/${type}${safeSubTypePath}`);
                }
            }}
            className={classNames('section', {
                active,
            })}
        >
            <IconStatusCard
                sectionType={type}
                sectionSubtype={subType}
                cardColor="var(--color-neutral-lightness)"
                iconColor="var(--color-neutral-dark)"
            />
            <h6>{t(`site.sections.${type}${safeSubType}`)}</h6>
        </div>
    );
};

const MemoizedSectionLine = memo(SectionLine);

type Props = {
    onClose: () => void;
};

export const AddSectionModal = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const { appState } = useAppState();

    const sectionsRef = useRef<HTMLDivElement>(null);
    const [showBottomBox, setShowBottomBox] = useState(true);

    const isPlus = useMemo(
        () => appState.editingSiteSubscription.type === 'PLUS',
        [appState]
    );

    const filteredAvailabelSections = useMemo(
        () => availableSections.filter((section) => !section.pro || isPlus),
        [isPlus]
    );

    useKeyPress('Escape', onClose);

    const wheelHandler = () => {
        const el = sectionsRef.current;
        if (!!el && el.offsetHeight + el.scrollTop + 40 >= el.scrollHeight) {
            setShowBottomBox(false);
        } else {
            setShowBottomBox(true);
        }
    };

    useEffect(() => {
        return stopScroll();
    }, []);

    useEffect(() => {
        wheelHandler();
    }, [sectionsRef]);

    return (
        <Background>
            <Body>
                <div
                    className={classNames('container', {
                        pro: isPlus,
                    })}
                >
                    <div className="header">
                        <h6>
                            {t('pages.editor.sections.actions.add.modal.title')}
                        </h6>
                        <MicroCircleContainer
                            $color=""
                            cursor="pointer"
                            onClick={onClose}
                        >
                            <CloseIcon
                                height={9}
                                width={9}
                                color="var(--color-neutral-dark)"
                            />
                        </MicroCircleContainer>
                    </div>
                    <div
                        className={classNames('bottom-box', {
                            hide: !showBottomBox,
                        })}
                    />
                    <div
                        tabIndex={-1}
                        className="sections"
                        ref={sectionsRef}
                        onScroll={wheelHandler}
                    >
                        {isPlus ? (
                            <>
                                <div
                                    className={classNames('sections-group', {
                                        spb: isPlus,
                                    })}
                                >
                                    {filteredAvailabelSections
                                        .filter(({ multiple }) => !multiple)
                                        .map((section) => (
                                            <MemoizedSectionLine
                                                {...section}
                                                key={`${section.type}-${section.subType}`}
                                            />
                                        ))}
                                </div>
                                <div className="sections-group spt nbb">
                                    {filteredAvailabelSections
                                        .filter(({ multiple }) => multiple)
                                        .map((section) => (
                                            <MemoizedSectionLine
                                                {...section}
                                                key={`${section.type}-${section.subType}`}
                                            />
                                        ))}
                                </div>
                            </>
                        ) : (
                            <div className="sections-group spb no-border">
                                {filteredAvailabelSections.map((section) => (
                                    <MemoizedSectionLine
                                        {...section}
                                        key={`${section.type}-${section.subType}`}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </Body>
        </Background>
    );
};
