import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '../../../../../../components/ConfirmModal';
import Input from '../../../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import SaveBar from '../../../../../../components/SaveBar';
import { isEmailValid } from '../../../../../../utils/email';
import { navigationState } from '../../../Sections/navigationState';
import StorageIntegrationServiceInfo from '../../components/StorageIntegrationServiceInfo';
import { FormNavigationState } from '../../formNavigationState';
import MailIcon from '../../icons/Mail';

type UIState = {
    isShowModalConfirmRemove: boolean;
    isShowModalConfirmEmailChanged: boolean;
};

const EmailStoragePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [uiState, setUIState] = useState<UIState>({
        isShowModalConfirmRemove: false,
        isShowModalConfirmEmailChanged: false,
    });

    const formNavigationState = navigationState.get() as FormNavigationState;

    const section = formNavigationState.section;
    const storage = section.storage;

    const [email, setEmail] = useState(storage.email?.value || '');

    const isEmailChanged =
        storage.email.active && (storage.email?.value || '') !== email;

    const saveHandler = () => {
        if (isEmailChanged) {
            setUIState({ ...uiState, isShowModalConfirmEmailChanged: true });
        } else {
            confirmSave();
        }
    };

    const confirmSave = () => {
        updateSectionAndBack(true, email);
    };

    const confirmDelete = () => {
        updateSectionAndBack(false, undefined);
        setUIState({ ...uiState, isShowModalConfirmRemove: false });
    };

    const deleteHandler = () => {
        setUIState({ ...uiState, isShowModalConfirmRemove: true });
    };

    const updateSectionAndBack = (active: boolean, email?: string) => {
        const state: FormNavigationState = {
            ...formNavigationState,
            section: {
                ...section,
                storage: {
                    ...section.storage,
                    email: { active, value: email },
                },
            },
        };
        navigationState.set(state);
        navigate(-1);
    };

    return (
        <div>
            <Header
                title="E-mail"
                hasUnsavedChanges={
                    isEmailChanged || (!storage.email.active && !!email)
                }
                onConfirmExitUnsavedChanges={() => navigate(-1)}
            />
            <StorageIntegrationServiceInfo
                LogoIcon={MailIcon}
                logoContainerColor="var(--color-feedback-danger)"
                info={t('pages.editor.form.form.storage.email.info')}
            />
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            autoCapitalize="none"
                            type="email"
                            value={email}
                            label={t(
                                'pages.editor.form.form.storage.email.form.email.label'
                            )}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    disabled={!isEmailValid(email)}
                    showTrashAction={storage.email.active}
                    onTrashClick={deleteHandler}
                    cancelTitle={t('actions.goBack')}
                    hasUnsavedChanges={!storage.email.active && !!email}
                    onConfirmExitUnsavedChanges={() => navigate(-1)}
                />
            </Form>
            {uiState.isShowModalConfirmRemove && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.form.form.storage.email.actions.remove.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.form.form.storage.email.actions.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.form.form.storage.email.actions.remove.confirmModal.confirmButton'
                    )}
                    onConfirm={confirmDelete}
                    onCancel={() =>
                        setUIState({
                            ...uiState,
                            isShowModalConfirmRemove: false,
                        })
                    }
                />
            )}
            {uiState.isShowModalConfirmEmailChanged && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.form.form.storage.email.actions.save.confirmEmailChanged.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.form.form.storage.email.actions.save.confirmEmailChanged.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.form.form.storage.email.actions.save.confirmEmailChanged.confirmButton'
                    )}
                    onConfirm={confirmSave}
                    onCancel={() =>
                        setUIState({
                            ...uiState,
                            isShowModalConfirmEmailChanged: false,
                        })
                    }
                />
            )}
        </div>
    );
};

export default EmailStoragePage;
