import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as Fonts from '../../../../components/Fonts';
import Input from '../../../../components/Form/Input/input';
import { TextArea } from '../../../../components/Form/Input/textArea';
import Label from '../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { SelectableCardItem } from '../../../../components/SelectableCardItem';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import { FormSection } from '../../../../model/site';
import { clone, deepEqual } from '../../../../utils/objects';
import { sanitizeUrl } from '../../../../utils/url';
import { useSection } from '../../hooks/Section';
import { navigationState } from '../Sections/navigationState';
import { FormNavigationState } from './formNavigationState';
import {
    Container,
    FieldRow,
    FieldsLabelSection,
    FieldsSection,
    HelpMessageSection,
    IntegrationItemsSection,
} from './styles';

const FormPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formNavigationState = useRef(
        navigationState.get() as FormNavigationState
    );

    const restoreState = useCallback(
        (newly: unknown, section: FormSection) => {
            if (formNavigationState.current?.section) {
                return formNavigationState.current.section;
            } else if (newly) {
                section.pageTitle = t(
                    'pages.editor.form.form.pageTitle.defaultValue'
                );
                section.fields = {
                    name: { active: true },
                    email: { active: true },
                    phone: { active: true },
                    message: { active: true },
                };
                section.storage = {
                    email: {
                        active: false,
                    },
                    mailChimp: {
                        active: false,
                    },
                    googleDrive: {
                        active: false,
                    },
                };
                section.buttonText = t(
                    'pages.editor.form.form.buttonTitle.defaultValue'
                );
            }
            return section;
        },
        [t, formNavigationState]
    );

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<FormSection>('form', restoreState);

    const saveHandler = () => {
        saveSection({
            ...section,
            successRedirectLink: sanitizeUrl(section.successRedirectLink),
        });
    };

    const editStorageIntegration = (path: string) => {
        const state: FormNavigationState = {
            section: clone(section),
            defaultSection:
                formNavigationState.current?.defaultSection ||
                clone<FormSection>(section),
        };
        navigationState.set(state);
        navigate(path);
    };

    const hasUnsavedChangesWrapper = (): boolean => {
        return formNavigationState?.current?.defaultSection
            ? !deepEqual(section, formNavigationState.current.defaultSection)
            : hasUnsavedChanges();
    };

    const saveDisabled =
        !section.buttonText ||
        (!section.fields.email.active && !section.fields.phone.active) ||
        (!section.storage.email.active &&
            !section.storage.googleDrive.active &&
            !section.storage.mailChimp.active);

    return (
        <Container>
            <Header
                title={t('pages.editor.form.header.title')}
                hasUnsavedChanges={hasUnsavedChangesWrapper}
            />
            <Box borderBottom>
                <HelpMessageSection>
                    <p>{t('pages.editor.form.help')}</p>
                </HelpMessageSection>
            </Box>
            <Form>
                <Box>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            type="text"
                            value={section.pageTitle}
                            label={t('pages.editor.form.form.pageTitle.label')}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <FieldsLabelSection>
                    <Fonts.Label>
                        {t('pages.editor.form.form.fields.label')}
                    </Fonts.Label>
                </FieldsLabelSection>

                <Box borderBottom>
                    <FieldsSection>
                        <FieldRow active={section.fields.name.active}>
                            <div>
                                <h6>
                                    {t('pages.editor.form.form.fields.name')}
                                </h6>
                            </div>

                            <div>
                                <ToggleSwitch
                                    value={section.fields.name.active}
                                    setValue={(active) =>
                                        setSection({
                                            ...section,
                                            fields: {
                                                ...section.fields,
                                                name: { active },
                                            },
                                        })
                                    }
                                />
                            </div>
                        </FieldRow>
                        <FieldRow active={section.fields.email.active}>
                            <div>
                                <h6>
                                    {t('pages.editor.form.form.fields.email')}
                                </h6>
                            </div>

                            <div>
                                <ToggleSwitch
                                    value={section.fields.email.active}
                                    setValue={(active) =>
                                        setSection({
                                            ...section,
                                            fields: {
                                                ...section.fields,
                                                email: { active },
                                            },
                                        })
                                    }
                                />
                            </div>
                        </FieldRow>
                        <FieldRow active={section.fields.phone.active}>
                            <div>
                                <h6>
                                    {t('pages.editor.form.form.fields.phone')}
                                </h6>
                            </div>

                            <div>
                                <ToggleSwitch
                                    value={section.fields.phone.active}
                                    setValue={(active) =>
                                        setSection({
                                            ...section,
                                            fields: {
                                                ...section.fields,
                                                phone: { active },
                                            },
                                        })
                                    }
                                />
                            </div>
                        </FieldRow>
                        <FieldRow active={section.fields.message.active}>
                            <div>
                                <h6>
                                    {t('pages.editor.form.form.fields.message')}
                                </h6>
                            </div>

                            <div>
                                <ToggleSwitch
                                    value={section.fields.message.active}
                                    setValue={(active) =>
                                        setSection({
                                            ...section,
                                            fields: {
                                                ...section.fields,
                                                message: { active },
                                            },
                                        })
                                    }
                                />
                            </div>
                        </FieldRow>
                    </FieldsSection>
                </Box>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            type="text"
                            required
                            value={section.buttonText}
                            label={t(
                                'pages.editor.form.form.buttonTitle.label'
                            )}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    buttonText: e.target.value,
                                })
                            }
                            maxLength={24}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="successRedirectLink"
                            autoCapitalize="none"
                            placeholder="https://"
                            type="text"
                            value={section.successRedirectLink}
                            label={t(
                                'pages.editor.form.form.successRedirectUrl'
                            )}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    successRedirectLink: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextArea
                            id="successMessage"
                            name="successMessage"
                            autoComplete="off"
                            rows={5}
                            value={section.successMessage}
                            placeholder={t(
                                'pages.editor.form.form.successMessage.defaultValue'
                            )}
                            // @ts-ignore
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    successMessage: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="successMessage"
                            text={t(
                                'pages.editor.form.form.successMessage.label'
                            )}
                        />
                    </FormGroup>
                </Box>
                <Box borderBottom>
                    <Fonts.Label>
                        {t('pages.editor.form.form.storage.label')}
                    </Fonts.Label>
                    <IntegrationItemsSection>
                        <SelectableCardItem
                            status={
                                section.storage.email.active
                                    ? 'ACTIVE'
                                    : 'DISABLED'
                            }
                            title="E-mail"
                            onClick={() =>
                                editStorageIntegration(
                                    '/editor/form/storage/email'
                                )
                            }
                            description={t(
                                'pages.editor.form.form.storage.email.menuTitle'
                            )}
                        />
                        <SelectableCardItem
                            status={
                                section.storage.mailChimp.active
                                    ? 'ACTIVE'
                                    : 'DISABLED'
                            }
                            title="Mailchimp"
                            onClick={() =>
                                editStorageIntegration(
                                    '/editor/form/storage/mailchimp'
                                )
                            }
                            description={t(
                                'pages.editor.form.form.storage.mailChimp.menuTitle'
                            )}
                        />
                        {/* <SelectableCardItem
                            status={
                                section.storage.googleDrive.active
                                    ? 'ACTIVE'
                                    : 'DISABLED'
                            }
                            title="Google Drive"
                            onClick={() =>
                                editStorageIntegration(
                                    '/editor/form/storage/google-drive'
                                )
                            }
                            description={t(
                                'pages.editor.form.form.storage.googleDrive.menuTitle'
                            )}
                        /> */}
                    </IntegrationItemsSection>
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    disabled={saveDisabled}
                    hasUnsavedChanges={hasUnsavedChangesWrapper}
                />
            </Form>
        </Container>
    );
};

export default FormPage;
