import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Input from '../../../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import SaveBar from '../../../../../../components/SaveBar';
import { ItemSectionLinks } from '../../../../../../model/site';
import { clone, deepEqual } from '../../../../../../utils/objects';
import { sanitizeUrl } from '../../../../../../utils/url';
import { uuidv4 } from '../../../../../../utils/uuid';
import { navigationState } from '../../../Sections/navigationState';
import { LinksNavigationState } from '../../linksNavigationState';
import { LinkThumbnail } from './components/Thumbnail';
import { Container, ThumbnailBox } from './styles';

const ItemLinkPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const linksNavigationState = navigationState.get() as LinksNavigationState;

    const headerTitle = useRef(
        linksNavigationState.editingLink?.id
            ? t('pages.editor.links.item.header.title.edit')
            : t('pages.editor.links.item.header.title.add')
    );

    const initialItemState = useRef<ItemSectionLinks>(
        clone<ItemSectionLinks>(
            linksNavigationState.editingLink || {
                id: uuidv4(),
            }
        )
    );

    const [item, setItem] = useState(initialItemState.current);

    const isSaveDisabled = !item.title || !item.link;

    const hasUnsavedChanges = () => !deepEqual(initialItemState.current, item);

    const saveHandler = () => {
        const items = linksNavigationState.currentSection.items || [];
        const itemIndex = items.findIndex(({ id }) => id === item.id);

        if (itemIndex >= 0) {
            items[itemIndex] = {
                ...item,
                link: sanitizeUrl(item.link) as string,
                thumbnailType: item.thumbnailType || 'IMAGE',
            };
        } else {
            items.push({
                ...item,
                link: sanitizeUrl(item.link) as string,
                thumbnailType: item.thumbnailType || 'IMAGE',
            });
        }

        linksNavigationState.currentSection.items = items;
        navigate(-1);
    };

    return (
        <Container>
            <Header
                title={headerTitle.current}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Box>
                <Form>
                    <FormGroup>
                        <Input
                            id="title"
                            required
                            type="text"
                            value={item.title}
                            autoCapitalize={'on'}
                            maxLength={56}
                            onChange={(e) =>
                                setItem({ ...item, title: e.target.value })
                            }
                            label={t('pages.editor.links.item.form.title')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="link"
                            required
                            type="link"
                            autoCapitalize="none"
                            placeholder="https://"
                            value={item.link}
                            onChange={(e) =>
                                setItem({ ...item, link: e.target.value })
                            }
                            label={t('pages.editor.links.item.form.link')}
                        />
                    </FormGroup>
                </Form>
            </Box>
            <ThumbnailBox borderBottom>
                <LinkThumbnail
                    thumbnailType={item.thumbnailType || 'IMAGE'}
                    onThumbnailChange={(thumbnailType) =>
                        setItem({ ...item, thumbnailType })
                    }
                    image={item.image}
                    emoji={item.emoji}
                    onChangeEmoji={(emoji) => setItem({ ...item, emoji })}
                    onChangeImage={(image) => setItem({ ...item, image })}
                />
            </ThumbnailBox>
            <SaveBar
                onSave={saveHandler}
                disabled={isSaveDisabled}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        </Container>
    );
};

export default ItemLinkPage;
