import styled from 'styled-components';

import { Button } from '../../../../components/Button';

type ContainerProps = {
    active: boolean;
};

export const Container = styled.div<ContainerProps>`
    background-color: ${(props) => props.active && 'var(--color-neutral-dark)'};
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 1.5rem;

    border: ${(props) =>
        !props.active && '2px dashed var(--color-neutral-dark)'};

    cursor: ${(props) => props.active && 'pointer'};

    h4 {
        color: ${(props) => !props.active && 'var(--color-neutral-light)'};
    }

    p {
        margin-top: 0.25rem;
        margin-bottom: 1.5rem;
        color: ${(props) =>
            props.active
                ? 'var(--color-brand-pink)'
                : 'var(--color-neutral-light)'};
    }
`;

export const FirstLineSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const DescriptionSection = styled.div`
    display: flex;
    flex-direction: column;
`;

type IconStatusProps = {
    active: boolean;
};

export const IconStatus = styled.div<IconStatusProps>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.active ? 'var(--color-brand-blue)' : 'var(--color-neutral-base)'};
`;

export const SecondLineSection = styled.div`
    display: flex;
`;

export const ThirdLineSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const EditButton = styled(Button)`
    border: 1px solid var(--color-neutral-white);
`;

export const RightButtonsSection = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 350px) {
        margin-left: 0.5rem;
    }

    > div {
        margin-left: 1rem;

        @media (max-width: 350px) {
            margin-left: 0.5rem;
        }
    }
`;
