import { ReactNode } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { Button } from '../../../../components/Button';
import { MicroCircleContainer } from '../../../../components/CircleContainer/styles';
import * as Fonts from '../../../../components/Fonts';
import SortIcon from '../../../../icons/Sort';
import TrashIcon from '../../../../icons/Trash';
import { classNames } from '../../../../utils/styles';
import {
    AddItemBox,
    CardItem,
    CardItems,
    Container,
    ThumbnailImage,
} from './styles';

export interface Item {
    id: string;
    title: string;
    thumbnailType?: 'IMAGE' | 'EMOJI';
    image?: string;
    emoji?: string;
}

type Props = {
    title: string;
    addButtonTitle: string;
    items: Item[];
    onAddClick: () => void;
    onRemoveClick: (id: string) => void;
    onEditClick: (id: string) => void;
    onSortUpdate: (items: Item[]) => void;
};

const ItemsTable = ({
    title,
    addButtonTitle,
    items,
    onAddClick,
    onRemoveClick,
    onEditClick,
    onSortUpdate,
}: Props) => {
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const itemsClone = Array.from(items);
        const [reorderedItem] = itemsClone.splice(result.source.index, 1);
        itemsClone.splice(result.destination.index, 0, reorderedItem);

        onSortUpdate(itemsClone);
    };

    return (
        <Container>
            <Fonts.Label>{title}</Fonts.Label>
            <CardItems>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="items" direction="vertical">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {items.map(
                                    (
                                        {
                                            id,
                                            title,
                                            thumbnailType,
                                            image,
                                            emoji,
                                        },
                                        index
                                    ) => (
                                        <Draggable
                                            key={id}
                                            index={index}
                                            draggableId={id}
                                        >
                                            {(provided) => (
                                                <CardItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    onClick={() =>
                                                        onEditClick(id)
                                                    }
                                                >
                                                    <div
                                                        className={classNames(
                                                            'left-section',
                                                            {
                                                                'has-thumbnail':
                                                                    !!image ||
                                                                    !!emoji,
                                                            }
                                                        )}
                                                    >
                                                        <SortIcon />
                                                        <div className="title">
                                                            <span>{title}</span>
                                                        </div>
                                                    </div>
                                                    <div className="right-section">
                                                        {thumbnailType ===
                                                            'IMAGE' &&
                                                            !!image && (
                                                                <div className="thumbnail">
                                                                    <ThumbnailImage
                                                                        $image={
                                                                            image
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        {thumbnailType ===
                                                            'EMOJI' &&
                                                            !!emoji && (
                                                                <div className="thumbnail emoji">
                                                                    {emoji}
                                                                </div>
                                                            )}
                                                        <div className="trash-container">
                                                            <MicroCircleContainer
                                                                onClick={(
                                                                    e: React.MouseEvent<
                                                                        HTMLDivElement,
                                                                        MouseEvent
                                                                    >
                                                                ) => {
                                                                    onRemoveClick(
                                                                        id
                                                                    );
                                                                    e.stopPropagation();
                                                                }}
                                                                $color="var(--color-neutral-base)"
                                                                cursor="pointer"
                                                            >
                                                                <TrashIcon
                                                                    size={16}
                                                                    color="var(--color-neutral-white)"
                                                                />
                                                            </MicroCircleContainer>
                                                        </div>
                                                    </div>
                                                </CardItem>
                                            )}
                                        </Draggable>
                                    )
                                )}
                                {provided.placeholder as ReactNode}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </CardItems>
            <AddItemBox onClick={() => onAddClick()}>
                <Button uppercase type="NEUTRAL" size="SM">
                    {addButtonTitle}
                </Button>
            </AddItemBox>
        </Container>
    );
};

export default ItemsTable;
