import styled, { css } from 'styled-components';

import { Theme } from '../../../../model/theme';

export const forceBreakTextCss = css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: manual;
    -moz-hyphens: manual;
    -webkit-hyphens: manual;
    hyphens: manual;
`;

export const Container = styled.div``;

type ContentContainerProps = {
    theme: Theme;
};

export const ContentContainer = styled.div<ContentContainerProps>`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        color: ${(props) => props.theme.secondary};
    }
`;

export const Section = styled.div`
    padding: 2.5rem 2rem;
`;
