import { useTranslation } from 'react-i18next';

import { PremiumBadge } from '../../../../components/Badge';
import { Container } from './styles';

type Props = {
    onClick: () => void;
};

export const ActiveSubscriptionBanner = ({ onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <Container onClick={onClick}>
            <div className="text">
                <h6>
                    {t('pages.sites.components.pendingPremiumBanner.title')}
                </h6>
                <p>
                    {t('pages.sites.components.pendingPremiumBanner.subtitle')}
                </p>
            </div>
            <div className="button">
                <PremiumBadge className="badge">
                    <h6>
                        {t(
                            'pages.sites.components.pendingPremiumBanner.ctaButton'
                        )}
                    </h6>
                </PremiumBadge>
            </div>
        </Container>
    );
};
