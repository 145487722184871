import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;

    > div {
        width: 7px;
        height: 7px;
        background-color: var(--color-neutral-base);

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    > div:nth-child(1) {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    > div:nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`;
