import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import TrashIcon from '../../icons/Trash';
import { Button, LinkButton } from '../Button';
import { SmallCircleContainer } from '../CircleContainer/styles';
import { UnsavedChangesConfirmModal } from '../UnsavedChangesConfirmModal';
import { Box, Container } from './styles';

type Props = {
    disabled?: boolean;
    onSave: () => void;
    showTrashAction?: boolean;
    onTrashClick?: () => void;
    cancelTitle?: string;
    onCancelClick?: () => void;
    hasUnsavedChanges?: boolean | (() => boolean);
    onConfirmExitUnsavedChanges?: () => void;
};

const SaveBar = ({
    disabled,
    onSave,
    showTrashAction,
    onTrashClick,
    cancelTitle,
    onCancelClick,
    hasUnsavedChanges,
    onConfirmExitUnsavedChanges,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const hasUnsavedChangesFn = (): boolean => {
        return typeof hasUnsavedChanges === 'function'
            ? hasUnsavedChanges()
            : hasUnsavedChanges === true;
    };

    const [showConfirmUnsavedChangesModal, setShowConfirmUnsavedChangesModal] =
        useState(false);

    const onCancelDefaultEventHandler = () => {
        if (hasUnsavedChangesFn()) {
            setShowConfirmUnsavedChangesModal(true);
        } else {
            if (onCancelClick) {
                onCancelClick();
            } else navigate(-1);
        }
    };

    return (
        <>
            {showConfirmUnsavedChangesModal && (
                <UnsavedChangesConfirmModal
                    onConfirmExit={() => {
                        setShowConfirmUnsavedChangesModal(false);
                        const action = onConfirmExitUnsavedChanges
                            ? onConfirmExitUnsavedChanges
                            : () => navigate(-1)!;

                        action();
                    }}
                    onCloseModal={() =>
                        setShowConfirmUnsavedChangesModal(false)
                    }
                />
            )}
            <Box>
                <Container>
                    {showTrashAction ? (
                        <SmallCircleContainer
                            onClick={onTrashClick}
                            $color="var(--color-neutral-base)"
                            cursor="pointer"
                        >
                            <TrashIcon
                                color="var(--color-neutral-white)"
                                size={20}
                            />
                        </SmallCircleContainer>
                    ) : (
                        <LinkButton onClick={onCancelDefaultEventHandler}>
                            {cancelTitle ? cancelTitle : t('actions.cancel')}
                        </LinkButton>
                    )}
                    <Button
                        disabled={disabled}
                        type="INFO"
                        size="LG"
                        onClick={() => !disabled && onSave()}
                    >
                        {t('actions.save')}
                    </Button>
                </Container>
            </Box>
        </>
    );
};

export default SaveBar;
