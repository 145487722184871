import { Site } from '../../model/site';
import { createStrictContext } from '../utils';

export type SiteContextType = {
    site: Site;
    setSite: (site: Site) => void;
};

export const [SiteContextProvider, useSite] =
    createStrictContext<SiteContextType>();
