import { SpinnerContainer } from './styles';

type Props = {
    size: string;
};

export const CircleSpinner = ({ size }: Props) => {
    return (
        <SpinnerContainer size={size}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </SpinnerContainer>
    );
};
