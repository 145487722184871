type Props = {
    color: string;
    width?: number;
    height?: number;
};

const BackIcon = ({ color, width = 18, height = 16 }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0499 9.32174C16.701 9.23341 17.2028 8.67531 17.2028 7.99999C17.2028 7.26329 16.6056 6.66607 15.8689 6.66607L4.5633 6.66607L8.96966 2.27916L9.09914 2.12983C9.48772 1.60844 9.44624 0.86729 8.97368 0.392709C8.45386 -0.12933 7.60927 -0.131129 7.08723 0.388691L0.392696 7.05476L0.38936 7.05861C0.345598 7.10255 0.304878 7.14951 0.267546 7.19917L0.262875 7.20456C-0.126589 7.72758 -0.0833149 8.47125 0.392696 8.94524L7.08723 15.6113L7.23711 15.7401C7.76014 16.1265 8.50111 16.0819 8.97368 15.6073L9.10252 15.4574C9.48887 14.9344 9.44424 14.1934 8.96966 13.7208L4.56575 9.33392L15.8689 9.33392L16.0499 9.32174Z"
                fill={color}
            />
        </svg>
    );
};

export default BackIcon;
