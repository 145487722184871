export type SectionDefinition = {
    type: string;
    multiple: boolean;
    subType?: string;
    pro: boolean;
};

export const availableSections: SectionDefinition[] = [
    { type: 'welcome', multiple: false, pro: false },
    { type: 'about', multiple: true, pro: false },
    { type: 'form', multiple: false, pro: true },
    { type: 'catalog', multiple: true, pro: false },
    { type: 'button', multiple: true, pro: true },
    { type: 'links', multiple: true, pro: false },
    { type: 'social', multiple: false, pro: false },
    { type: 'media', subType: 'image', multiple: true, pro: true },
    { type: 'media', subType: 'video', multiple: true, pro: true },
    { type: 'media', subType: 'audio', multiple: true, pro: true },
];
