import styled, { css } from 'styled-components';

export const Container = styled.div`
    > div:nth-child(3) {
        > div:first-child {
            height: 16px;
            width: 40px;
        }
    }
`;

export const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 32px;
    border-bottom: 1px solid var(--color-neutral-base);

    > div:first-child {
        height: 38px;
        width: 38px;
    }

    > div:nth-child(2) {
        height: 38px;
        width: 155px;
    }

    > div:last-child {
        height: 32px;
        width: 22px;
    }
`;

export const SitesSection = styled.div`
    margin-top: 1rem;
    margin-bottom: 7.5rem;

    > div:first-child {
        margin-bottom: 1rem;
    }
`;

const loadingBoxCss = css`
    background: linear-gradient(
            104.66deg,
            var(--color-neutral-base) -7.77%,
            var(--color-neutral-dark) 102.05%
        ),
        var(--color-neutral-dark);
    opacity: 0.5;
    border-radius: 8px;
`;

export const LoadingBox = styled.div`
    ${loadingBoxCss};
`;

export const Site = styled.div`
    ${loadingBoxCss};
    padding: 1.5rem;

    display: flex;
    flex-direction: column;

    > div:first-child {
        > div:first-child {
            width: 240px;
            height: 24px;
            margin-bottom: 4px;
        }
        > div:last-child {
            width: 180px;
            height: 20px;
        }
    }

    > div:last-child {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;

        > div:first-child {
            width: 70px;
            height: 34px;
        }

        > div:last-child {
            display: flex;
            flex-direction: row;

            > div:last-child {
                margin-left: 1rem;
            }
        }
    }
`;

export const LoadingCircle = styled.div`
    background-color: var(--color-neutral-base);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    opacity: 0.5;
`;
