type Props = {
    color: string;
};

const StarIcon = ({ color }: Props) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9485 9.75082L14.0001 6.59001L9.79169 5.9402L8.00004 2.0826L6.20839 5.9402L2 6.59001L5.05157 9.75082L4.35238 14.0827L8.00004 12.0449L11.6477 14.0827L10.9485 9.75082ZM10.8517 15.691L8.00009 14.1301L5.14844 15.691C3.56023 16.5603 2.16813 15.5043 2.47102 13.6657L3.01564 10.3597L0.7086 8.01839C-0.576286 6.71441 -0.0418825 5.00967 1.73128 4.74141L4.91953 4.25908L6.34536 1.25118C6.74422 0.409763 7.37628 -0.00563581 8.00772 5.77351e-05C8.6368 0.00573002 9.26526 0.429355 9.66188 1.26606L11.0807 4.25908L14.2689 4.74141C16.0446 5.01005 16.5747 6.71625 15.2916 8.01839L12.9845 10.3597L13.5292 13.6657C13.8325 15.5069 12.4377 16.5591 10.8517 15.691Z"
                fill={color}
            />
        </svg>
    );
};

export default StarIcon;
