import styled from 'styled-components';

type ContainerProps = {
    $active: boolean;
};

export const Container = styled.div<ContainerProps>`
    opacity: ${(props) => !props.$active && '0.4'};
    filter: ${(props) => !props.$active && 'grayscale(1)'};
`;

export const InputColorsSection = styled.div`
    padding: 0 0 2rem 0;
    border-bottom: 1px solid var(--color-neutral-dark);
    margin-bottom: 2rem;

    .input-group {
        margin-top: 2rem;

        > div {
            margin-bottom: 1rem;
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-neutral-base);
        }

        &:last-child {
            > div:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
