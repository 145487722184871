import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: min(480px, 100%);
`;

export const CardContainer = styled.div`
    height: 172px;
    border-radius: 8px;
    background-color: var(--color-neutral-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: #303644;
    }

    &:first-child {
        margin-bottom: 1rem;
    }

    > p {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        color: var(--color-neutral-150);
    }

    > .cta-btn-plus {
        background-color: var(--color-lemon);
        color: var(--color-neutral-darkness);
    }
`;
