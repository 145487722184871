import styled from 'styled-components';

type ContainerProps = {
    status: 'ACTIVE' | 'DISABLED';
};

export const Container = styled.div<ContainerProps>`
    height: 96px;
    cursor: pointer;

    background-color: ${(props) =>
        props.status === 'ACTIVE'
            ? 'var(--color-neutral-base)'
            : 'var(--color-neutral-dark)'};

    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 1rem;

    p {
        color: ${(props) =>
            props.status !== 'ACTIVE' && 'var(--color-neutral-150)'};
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const Description = styled.div`
    margin-top: 0.5rem;
`;
