import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Box = styled.div`
    padding: 1.5rem 0rem;
    margin: 0rem 2rem;
`;
