import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import { LoadingModal } from '../../components/LoadingModal';
import { api } from '../../services/api';
import { Container } from './styles';

export const LegalTermsPage = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState<string>();
    const [loading, setLoading] = useState(true);

    const loadContent = useCallback(async () => {
        const lang = i18n.language.toUpperCase().includes('PT') ? 'pt' : 'en';
        const content = await api.getTerms(lang, '2021-10-12');
        setContent(content);
        setLoading(false);
    }, [i18n]);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    return loading || !content ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.legal.header.title')} />
            <div className="top-block">
                <h4>{t('pages.legal.header.subtitle')}</h4>
            </div>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Container>
    );
};
