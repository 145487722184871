import styled from 'styled-components';

export const Container = styled.div`
    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            margin-bottom: 2rem;
        }
        > p {
            text-align: center;
        }
    }
`;
