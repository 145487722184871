import styled from 'styled-components';

type Props = {
    size?: string;
    $color: string;
    cursor?: string;
};

const CircleContainer = styled.div<Props>`
    cursor: ${(props) => props.cursor && props.cursor};
    background-color: ${(props) => props.$color};

    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
`;

export const MicroCircleContainer = styled(CircleContainer)`
    height: 24px;
    width: 24px;
`;

export const SmallCircleContainer = styled(CircleContainer)`
    height: 32px;
    width: 32px;
`;

export const MediumCircleContainer = styled(CircleContainer)`
    height: 40px;
    width: 40px;
`;

export const LargeCircleContainer = styled(CircleContainer)`
    height: 48px;
    width: 48px;
`;
