import React from 'react';
import { useTranslation } from 'react-i18next';

import EyeIcon from '../../../../../../../../icons/Eye';
import SortIcon from '../../../../../../../../icons/Sort';
import TrashIcon from '../../../../../../../../icons/Trash';
import IconStatusCard from '../../../IconStatusCard';
import { ButtonsSection, Card, IconCircle, LeftColumnSection } from './styles';

type Props = {
    sectionType: string;
    sectionSubtype?: string;
    active: boolean;
    onActiveClick: (active: boolean) => void;
    onRemoveClick: () => void;
    onClick: () => void;
};

const CardItem = ({
    sectionType,
    sectionSubtype,
    active,
    onActiveClick,
    onRemoveClick,
    onClick,
}: Props) => {
    const { t } = useTranslation();

    const actionButtonColor = active
        ? 'var(--color-neutral-light)'
        : 'var(--color-neutral-lightness)';

    const statusCardBackgroundColor = active
        ? 'var(--color-brand-blue)'
        : 'var(--color-neutral-base)';

    const statusCardIconColor = active
        ? 'var(--color-neutral-white)'
        : 'var(--color-neutral-light)';

    const handleActiveClick = (e: React.MouseEvent) => {
        onActiveClick(!active);
        e.stopPropagation();
    };

    const handleRemoveClick = (e: React.MouseEvent) => {
        onRemoveClick();
        e.stopPropagation();
    };

    const title = sectionSubtype
        ? t(`site.sections.${sectionType}.${sectionSubtype}`)
        : t(`site.sections.${sectionType}`);

    return (
        <Card active={active} onClick={() => onClick()}>
            <LeftColumnSection>
                <SortIcon />
                <IconStatusCard
                    sectionType={sectionType}
                    sectionSubtype={sectionSubtype}
                    cardColor={statusCardBackgroundColor}
                    iconColor={statusCardIconColor}
                />
                <h6>{title}</h6>
            </LeftColumnSection>
            <ButtonsSection>
                <IconCircle onClick={handleActiveClick} active={active}>
                    <EyeIcon width={12} height={10} color={actionButtonColor} />
                </IconCircle>
                <IconCircle onClick={handleRemoveClick} active={active}>
                    <TrashIcon size={16} color={actionButtonColor} />
                </IconCircle>
            </ButtonsSection>
        </Card>
    );
};

export default CardItem;
