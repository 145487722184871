const constants = {
    release: process.env.REACT_APP_SENTRY_RELEASE,
    site: process.env.REACT_APP_SITE,
    nameSite: process.env.REACT_APP_SITE_NAME,
    instagram: 'https://www.instagram.com/keepo.io',
    api: process.env.REACT_APP_API,
    staticBucket: process.env.REACT_APP_BUCKET,
    env: process.env.REACT_APP_ENV,
    mailChimpClientId: process.env.REACT_APP_MAIL_CHIMP_CLIEND_ID,
    googleDriveFormIntegrationClientId:
        process.env.REACT_APP_GOOGLE_DRIVE_FORM_INTEGRATION_CLIENT_ID,
    price: {
        month: {
            value: 'R$ 15,90',
        },
        year: {
            oldValue: 'R$ 190,80',
            perMonth: 'R$ 9,99',
            value: 'R$ 119,90',
            discount: '37% OFF',
        },
    },
};

export default constants;
