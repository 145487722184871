import { useState } from 'react';
import { useNavigate } from 'react-router';

import BackIcon from '../../icons/Back';
import { SmallCircleContainer } from '../CircleContainer/styles';
import { UnsavedChangesConfirmModal } from '../UnsavedChangesConfirmModal';
import { Container, EmptySection, TitleSection } from './styles';

type Props = {
    title?: string;
    onClickBack?: () => void;
    hasUnsavedChanges?: boolean | (() => boolean);
    onConfirmExitUnsavedChanges?: () => void;
    isConfirmExitUnsavedChanges?: boolean;
    titleConfirmExitModal?: string;
    buttonTitleConfirmExitModal?: string;
    cancelTitleConfirmExitModal?: string;
    rightElement?: React.ReactNode;
};

const Header = ({
    title,
    onClickBack,
    hasUnsavedChanges,
    onConfirmExitUnsavedChanges,
    titleConfirmExitModal,
    buttonTitleConfirmExitModal,
    cancelTitleConfirmExitModal,
    rightElement,
}: Props) => {
    const navigate = useNavigate();

    const hasUnsavedChangesFn = (): boolean => {
        return typeof hasUnsavedChanges === 'function'
            ? hasUnsavedChanges()
            : hasUnsavedChanges === true;
    };

    const [showConfirmUnsavedChangesModal, setShowConfirmUnsavedChangesModal] =
        useState(false);

    const onClickBackDefaultEventHandler = () => {
        if (hasUnsavedChangesFn()) {
            setShowConfirmUnsavedChangesModal(true);
        } else {
            (onClickBack && onClickBack()) || navigate(-1);
        }
    };

    return (
        <>
            {showConfirmUnsavedChangesModal && (
                <UnsavedChangesConfirmModal
                    onConfirmExit={() => {
                        setShowConfirmUnsavedChangesModal(false);
                        if (onConfirmExitUnsavedChanges) {
                            onConfirmExitUnsavedChanges();
                        } else {
                            navigate(-1);
                        }
                    }}
                    onCloseModal={() =>
                        setShowConfirmUnsavedChangesModal(false)
                    }
                    title={titleConfirmExitModal}
                    confirmButtonTitle={buttonTitleConfirmExitModal}
                    cancelTitle={cancelTitleConfirmExitModal}
                />
            )}
            <Container>
                <SmallCircleContainer
                    onClick={onClickBackDefaultEventHandler}
                    $color="var(--color-neutral-base)"
                    cursor="pointer"
                >
                    <BackIcon color="var(--color-neutral-white)" />
                </SmallCircleContainer>
                <TitleSection>
                    <h3>{title}</h3>
                </TitleSection>
                {rightElement || <EmptySection />}
            </Container>
        </>
    );
};

export default Header;
