import CheckIcon from '../../icons/Check';
import { Container, Header, Description } from './styles';

type Props = {
    title: string;
    description: string;
    status: 'ACTIVE' | 'DISABLED';
    onClick: () => void;
};

export const SelectableCardItem = ({
    title,
    description,
    status,
    onClick,
}: Props) => {
    return (
        <Container status={status} onClick={() => onClick()}>
            <Header>
                <div>
                    <h6>{title}</h6>
                </div>
                {status === 'ACTIVE' && (
                    <div>
                        <CheckIcon
                            height={12}
                            width={16}
                            color="var(--color-lemon)"
                        />
                    </div>
                )}
            </Header>
            <Description>
                <p>{description}</p>
            </Description>
        </Container>
    );
};
