import styled from 'styled-components';

type Props = {
    $loading: boolean;
};

export const Container = styled.div<Props>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;

    cursor: ${(props) => !props.$loading && 'pointer'};

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    > img {
        display: ${(props) => (props.$loading ? 'none' : 'block')};
        width: 96px;
        height: 96px;
        object-fit: cover;
        border-radius: 4px;
    }
`;
