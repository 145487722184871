import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ConfirmModal from '../../../../components/ConfirmModal';
import Input from '../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { ItemSectionLinks, LinksSection } from '../../../../model/site';
import { clone, deepEqual } from '../../../../utils/objects';
import ItemsTable from '../../components/ItemsTable';
import { useSection } from '../../hooks/Section';
import { navigationState } from '../Sections/navigationState';
import { LinksNavigationState } from './linksNavigationState';
import { Container } from './styles';

const LinksPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const linksNavigationState = useRef(
        navigationState.get() as LinksNavigationState
    );

    const restoreState = useCallback(
        (newly: unknown, section: LinksSection) => {
            if (linksNavigationState.current) {
                section.pageTitle =
                    linksNavigationState.current.currentSection.pageTitle;
                section.items =
                    linksNavigationState.current.currentSection.items;
            } else if (newly) {
                section.pageTitle = t('pages.editor.links.header.title');
                section.items = [];
            }
            return section;
        },
        [t, linksNavigationState]
    );

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<LinksSection>('links', restoreState);

    const [idRemoving, setIdRemoving] = useState<string>();

    const onAddClick = () => {
        goEditItemPage();
    };

    const onEditClick = (id: string) => {
        goEditItemPage(id);
    };

    const goEditItemPage = (id?: string) => {
        const state: LinksNavigationState = {
            currentSection: clone<LinksSection>(section),
            defaultSection:
                linksNavigationState.current?.defaultSection ||
                clone<LinksSection>(section),
            editingLink: section.items.find((link) => link.id === id),
        };
        navigationState.set(state);
        navigate('/editor/links/item');
    };

    const onRemoveClick = (linkId: string) => {
        setIdRemoving(linkId);
    };

    const removeLink = () => {
        const items = [...section.items];
        const index = items.findIndex(({ id }) => id === idRemoving);

        items.splice(index, 1);
        setSection({ ...section, items });
        setIdRemoving(undefined);
    };

    const hasUnsavedChangesWrapper = (): boolean => {
        return linksNavigationState?.current?.defaultSection
            ? !deepEqual(section, linksNavigationState.current.defaultSection)
            : hasUnsavedChanges();
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.links.header.title')}
                hasUnsavedChanges={hasUnsavedChangesWrapper}
            />
            {idRemoving && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.links.actions.remove.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.links.actions.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.links.actions.remove.confirmModal.confirmButton'
                    )}
                    onCancel={() => setIdRemoving(undefined)}
                    onConfirm={removeLink}
                />
            )}
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            type="text"
                            value={section.pageTitle}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                            label={t('pages.editor.links.form.pageTitle')}
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <ItemsTable
                        onSortUpdate={(items) =>
                            setSection({
                                ...section,
                                items: items as ItemSectionLinks[],
                            })
                        }
                        onAddClick={onAddClick}
                        onEditClick={onEditClick}
                        onRemoveClick={onRemoveClick}
                        items={section.items}
                        title={t('pages.editor.links.form.table.title')}
                        addButtonTitle={t(
                            'pages.editor.links.form.table.addButton'
                        )}
                    />
                </Box>
                <SaveBar
                    onSave={saveSection}
                    hasUnsavedChanges={hasUnsavedChangesWrapper}
                />
            </Form>
        </Container>
    );
};

export default LinksPage;
