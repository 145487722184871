type Props = {
    width?: number;
    height?: number;
    color: string;
};

const ArrowDownIcon = ({ width = 25, height = 24, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.495 21C12.2164 21.0014 11.9374 20.8873 11.737 20.658L4.74697 12.658C4.38358 12.2421 4.42615 11.6103 4.84204 11.247C5.25793 10.8836 5.88967 10.9261 6.25306 11.342L11.5 17.3471V4C11.5 3.44772 11.9477 3 12.5 3C13.0523 3 13.5 3.44772 13.5 4V17.33L18.7479 11.341C19.1119 10.9256 19.7437 10.8839 20.159 11.2479C20.5744 11.6119 20.6161 12.2437 20.2521 12.659L13.3276 20.5616C13.1477 20.8262 12.8441 21 12.5 21L12.495 21Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowDownIcon;
