import { css } from 'styled-components';

export const inputCss = css`
    border-radius: 0.625rem;
    padding: 1rem;
    margin: 0.5rem 0;
    width: 100%;

    border: 0.125rem solid var(--color-neutral-light);
    background-color: transparent;

    color: var(--color-neutral-white);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1rem;
    font-size: 0.875rem;

    &:focus {
        border-color: var(--color-brand-pink);
        outline: none;
        + label {
            color: var(--color-neutral-white);
        }
    }
`;
