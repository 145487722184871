import { createGlobalStyle, css } from 'styled-components';

import reset from './reset';

const global = css`
    body {
        background: var(--color-neutral-darkness);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        color: var(--color-neutral-white);
    }

    *,
    button,
    input {
        border: 0;
        background: none;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
            Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
    }

    button {
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
    }

    /* H1-Poppins-Semibold */
    h1 {
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 3rem;
    }

    /* H2-Poppins-Semibold */
    h2 {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
    }

    /* H3-Poppins-Semibold */
    h3 {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    /* H4-Poppins-Semibold */
    h4 {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    /* H5-Poppins-Semibold */
    h5 {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
    /* Tittle-Poppins-Semibold */
    h6 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
    }

    /* Text-Inter-Regular */
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    a {
        text-decoration: none;
        color: var(--color-neutral-light);

        :visited,
        :hover,
        :active {
            color: unset;
        }
    }

    .line-through {
        text-decoration: line-through;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    :root {
        --color-brand-blue: #4b10ff;
        --color-brand-pink: #ffffff;
        --color-neutral-darkness: #181a20;
        --color-neutral-dark: #262a34;
        --color-neutral-base: #3a3f50;
        --color-neutral-light: #5e6272;
        --color-neutral-lightness: #f6f6fb;
        --color-neutral-white: #ffffff;
        --color-feedback-danger: #ff4264;
        --color-feedback-success: #37bf56;
        --color-lemon: #62fa95;
        --color-neutral-150: #767b8f;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes changeTabsAnimation {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes changeTabsAnimationFade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    body {
        //faz com que o fundo nao fique movendo ao abrir modal no desktop
        overflow-y: scroll;
    }

    .hideScroll {
        touch-action: none;
        -ms-touch-action: none;
        display: block;
        position: fixed;
        overflow-y: scroll;
        width: 100%;
    }
`;

export default createGlobalStyle`
    ${reset}
    ${global}
`;
