import { Avatar } from '../avatar';
import SProof1 from './images/SProof1.png';
import SProof2 from './images/SProof2.png';
import SProof3 from './images/SProof3.png';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils/cn';

interface SocialProofProps {
    billing?: boolean;
}

export function SocialProof({ billing = false }: SocialProofProps) {
    const { t } = useTranslation();
    return (
        <div className="flex gap-4">
            <div className="z-10 flex -space-x-2 py-1 rtl:space-x-reverse">
                <Avatar src={SProof1} className="z-40 ring-2 ring-shark-900" />
                <Avatar
                    src={SProof2}
                    className="z-30 ml-6 ring-2 ring-shark-900"
                />
                <Avatar
                    src={SProof3}
                    className="z-20 ml-12 ring-2 ring-shark-900"
                />
            </div>

            <p
                className={cn('typography-p text-shark-400', {
                    'w-1/2': billing,
                    'w-1/2 break-words sm:w-1/3': !billing,
                })}
            >
                {t('pages.login.social.text')}
                <span
                    className={cn('font-bold', {
                        'text-gradient-primary': billing,
                        'text-white': !billing,
                    })}
                >
                    {' '}
                    {t('pages.login.social.amount')}{' '}
                </span>
                {t('pages.login.social.user')}
            </p>
        </div>
    );
}
