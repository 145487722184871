import styled from 'styled-components';

import { Theme } from '../../../../../../model/theme';

type ContainerProps = {
    $theme: Theme;
};

export const Container = styled.div<ContainerProps>`
    background: ${(props) => props.$theme?.primary};
    color: ${(props) => props.$theme?.secondary};
    margin: 0 auto 32px auto;
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 12px 20px 20px 20px;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid var(--color-neutral-base);

    .textPreview {
        display: inline-block;
        margin-bottom: 12px;
    }

    .buttonPreview {
        width: 100%;
        padding: 8px 0;
        border-radius: 100px;
        background: ${(props) => props.$theme?.buttons};
        color: ${(props) => props.$theme?.textButtons};
    }
`;
