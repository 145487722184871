import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ConfirmModal from '../../../../components/ConfirmModal';
import Input from '../../../../components/Form/Input/input';
import { FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import SaveBar from '../../../../components/SaveBar';
import { useAppState } from '../../../../contexts/App';
import { api } from '../../../../services/api';
import { TrackingIntegrations } from '../../../../services/api/models';
import { Container } from './styles';

type AdditionalField = {
    title: string;
    propertyName: keyof TrackingIntegrations;
};

type Props = {
    title: string;
    helpDescription: string;
    providerName: keyof TrackingIntegrations;
    image: string;
    additionalField?: AdditionalField;
};

export const TrackingPage = ({
    title,
    helpDescription,
    providerName,
    image,
    additionalField,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { appState, setAppState } = useAppState();

    const tracking = useMemo(
        () => appState.editingSiteTrackingIntegrations || {},
        [appState.editingSiteTrackingIntegrations]
    );

    const [isRemoving, setRemoving] = useState(false);
    const [value, setValue] = useState(tracking[providerName]);
    const [additionalFieldValue, setAdditionalFieldValue] = useState(
        additionalField ? tracking[additionalField?.propertyName] : undefined
    );

    const [loading, setLoading] = useState(false);

    const hasUnsavedChanges = useMemo(
        () =>
            tracking[providerName] !== value ||
            (additionalField &&
                additionalFieldValue !==
                    tracking[additionalField.propertyName]),
        [additionalField, additionalFieldValue, providerName, tracking, value]
    );

    const updateHandler = useCallback(
        async (value?: string) => {
            setLoading(true);
            const siteId = appState.editingSiteId as string;
            try {
                const updated = {
                    ...tracking,
                    [providerName]: value,
                };
                if (additionalField) {
                    updated[additionalField.propertyName] =
                        additionalFieldValue;
                }
                await api.updateTrackingIntegrations(siteId, updated);
                setAppState({
                    ...appState,
                    editingSiteTrackingIntegrations: updated,
                });
                navigate(-1);
            } finally {
                setLoading(false);
            }
        },
        [
            appState,
            tracking,
            providerName,
            additionalField,
            setAppState,
            navigate,
            additionalFieldValue,
        ]
    );

    const saveHandler = useCallback(async () => {
        if (!hasUnsavedChanges) {
            return navigate(-1);
        }
        updateHandler(value);
    }, [hasUnsavedChanges, updateHandler, value, navigate]);

    const deleteHandler = useCallback(async () => {
        setRemoving(false);
        updateHandler(undefined);
    }, [updateHandler]);

    return loading ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={title} hasUnsavedChanges={hasUnsavedChanges} />
            <Box className="intro">
                <img src={image} height={72} width={72} />
                <p>{helpDescription}</p>
            </Box>
            <Box borderBottom>
                <FormGroup>
                    <Input
                        id="id"
                        type="text"
                        value={value}
                        label={title}
                        onChange={(e) => setValue(e.target.value)}
                        required={!!additionalField}
                    />
                </FormGroup>
                {additionalField && (
                    <FormGroup>
                        <Input
                            id={additionalField.propertyName}
                            type="text"
                            value={additionalFieldValue}
                            label={additionalField.title}
                            onChange={(e) =>
                                setAdditionalFieldValue(e.target.value)
                            }
                        />
                    </FormGroup>
                )}
            </Box>
            <SaveBar
                onSave={saveHandler}
                showTrashAction={!!tracking[providerName]}
                onTrashClick={() => setRemoving(true)}
                disabled={!value}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            {isRemoving && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.settings.tracking.remove.confirmModal.title'
                    )}
                    confirmButtonTitle={t(
                        'pages.settings.tracking.remove.confirmModal.confirmButton'
                    )}
                    cancelTitle={t(
                        'pages.settings.tracking.remove.confirmModal.cancelButton'
                    )}
                    onCancel={() => setRemoving(false)}
                    onConfirm={deleteHandler}
                />
            )}
        </Container>
    );
};
