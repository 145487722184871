import styled from 'styled-components';

export const Label = styled.label`
    display: block;
    color: var(--color-neutral-light);
    font-family: 'Inter', sans-serif;
    font-size: 0.625rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const InfoSection = styled.div``;

export const InfoSectionMessage = styled.div`
    color: var(--color-feedback-danger);

    font-size: 8px;

    @media (max-width: 400px) {
        font-size: 6px;
    }
`;
