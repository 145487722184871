import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/Header';
import { LoadingModal } from '../../../../components/LoadingModal';
import { useQueryUrl } from '../../../../hooks/QueryUrl';
import { keepoInternalAnalyticsService } from '../../../../services/analytics';
import { api, ApiSiteResponse } from '../../../../services/api';
import { PaymentInfo } from '../../components/PaymentInfo';
import { CREATE_NEW_KEEPO } from '../SiteSelection';
import { Container } from './styles';
import { OfferItems, Offers } from '../../components/offers';
import { BillingTabs } from '../../components/billingTabs';
import { SocialProof } from '../../../../components/socialProof';
import { SubscriptionTitle } from '../../components/title';

const items = [
    'style',
    'sections',
    'analytics',
    'media',
    'form',
    'support',
    'brand',
];

export const FeaturesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryUrl = useQueryUrl();

    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState<ApiSiteResponse[]>([]);

    const [planType, setPlanType] = useState<'MONTHLY' | 'ANNUALLY'>(
        'ANNUALLY'
    );

    const callCheckoutHandler = useCallback(
        async (siteId?: string) => {
            const url = await api.redirectCheckout({
                plan: planType,
                siteId,
                currentPage: window.location.href,
            });

            const user = await api.getUser();
            keepoInternalAnalyticsService.notifyCheckoutEvent(user);

            window.location = url;
        },
        [planType]
    );

    const continueHandler = async () => {
        setLoading(true);
        const selectedSite = queryUrl.get('site');

        if (!sites.length) {
            return await callCheckoutHandler();
        }

        if (!!selectedSite && selectedSite !== CREATE_NEW_KEEPO) {
            return await callCheckoutHandler(selectedSite);
        }

        navigate(`/subscription/selection?plan=${planType}`);
    };

    const loadSitesHandler = useCallback(async () => {
        const sites = (await api.getSites()).filter(
            (site) => site.subscription?.type !== 'PLUS' && !!site.site
        );
        setSites(sites);
        setLoading(false);
    }, []);

    const notifyPreCheckout = async () => {
        const user = await api.getUser();
        keepoInternalAnalyticsService.notifyPreCheckoutEvent(user);
    };

    useEffect(() => {
        loadSitesHandler();
    }, [loadSitesHandler]);

    useEffect(() => {
        notifyPreCheckout();
    }, []);

    return loading ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.subscription.header.title')} />
            <div className="mx-4 mt-9 flex flex-col rounded-2xl bg-shark-950">
                <div className="auth-bg-gradient flex h-full flex-col gap-6 rounded-2xl py-4">
                    <SubscriptionTitle />
                    <Offers>
                        {items.map((item) => (
                            <OfferItems key={item}>
                                {t(
                                    `pages.subscription.features.offer.items.${item}`
                                )}
                            </OfferItems>
                        ))}
                        <p className="typography-p text-shark-900">
                            {t('pages.subscription.features.pricesInfo')}
                        </p>
                    </Offers>
                    <div className="h-[1px] w-full bg-black/10" />
                    <BillingTabs plan={planType} setPlan={setPlanType} />
                    <div className="px-6">
                        <button
                            onClick={continueHandler}
                            className="typography-h6 w-full rounded-full bg-shark-50 py-4 text-shark-950 hover:bg-white"
                        >
                            {t('pages.subscription.continueButton')}
                        </button>
                    </div>
                </div>
                <div className="px-12 py-4 md:px-16">
                    <SocialProof billing />
                </div>
            </div>
            <PaymentInfo />
        </Container>
    );
};
