import { AuthIcon } from '../../icons/auth';
import AuthBackground from './images/keepo-auth-background.png';

export function Background() {
    return (
        <div className="hidden h-full w-full items-center justify-center bg-auth-background lg:relative lg:flex">
            <div className="absolute bg-cover">
                <div className="absolute -top-8 right-0">
                    <AuthIcon.StarKeepo className="" />
                </div>
                <AuthIcon.UnionKeepo className="" />
            </div>
            <div className="absolute">
                <img className="z-40" src={AuthBackground} />
            </div>
        </div>
    );
}
