import { memo } from 'react';

import { AudioIcon } from '../../icons/Audio';
import BallsIcon from '../../icons/Balls';
import { ButtonSectionIcon } from '../../icons/ButtonSection';
import CardIcon from '../../icons/Card';
import ContentIcon from '../../icons/Content';
import { ImageIcon } from '../../icons/Image';
import LinksIcon from '../../icons/Links';
import StarIcon from '../../icons/Star';
import UnionIcon from '../../icons/Union';
import { VideoIcon } from '../../icons/Video';
import { Card } from './styles';

type Props = {
    sectionType: string;
    sectionSubtype?: string;
    iconColor: string;
    cardColor: string;
};

export const getIcon = (
    sectionType: string,
    color: string,
    sectionSubtype?: string
) => {
    switch (sectionType) {
        case 'welcome':
            return <StarIcon color={color} />;
        case 'about':
            return <ContentIcon color={color} />;
        case 'form':
            return <UnionIcon color={color} />;
        case 'catalog':
            return <CardIcon color={color} />;
        case 'links':
            return <LinksIcon color={color} />;
        case 'social':
            return <BallsIcon color={color} />;
        case 'button':
            return <ButtonSectionIcon color={color} />;
        case 'media':
            switch (sectionSubtype) {
                case 'image':
                    return <ImageIcon color={color} />;
                case 'video':
                    return <VideoIcon color={color} />;
                case 'audio':
                    return <AudioIcon color={color} />;
            }
    }
};

const IconStatusCard = ({
    sectionType,
    sectionSubtype,
    iconColor,
    cardColor,
}: Props) => {
    return (
        <Card backgroundColor={cardColor}>
            {getIcon(sectionType, iconColor, sectionSubtype)}
        </Card>
    );
};

export default memo(IconStatusCard);
