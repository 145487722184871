import { Theme } from '../../../../../../model/theme';
import { Container } from './styles';

type Props = {
    activeTheme: Theme;
};

export const ColorPreviewSection = ({ activeTheme }: Props) => {
    return (
        <Container $theme={activeTheme}>
            <span className="textPreview">Ab</span>
            <span className="buttonPreview">Ab</span>
        </Container>
    );
};
