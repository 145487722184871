interface CheckIconProps extends React.ComponentProps<'svg'> {
    width?: number;
    height?: number;
    color?: string;
}

const CheckIcon = ({ width, height, color, ...rest }: CheckIconProps) => {
    return (
        <svg
            width={width && width}
            height={height && width}
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.79992 7.60005L0.199951 3.99999L1.39995 2.8L3.79992 5.20007L8.59996 0.400024L9.79995 1.60003L3.79992 7.60005Z"
                fill={color && color}
            />
        </svg>
    );
};

export default CheckIcon;
