import { useTranslation } from 'react-i18next';

import { EmbeddedMediaPage } from '../components/EmbeddedMediaPage';

export const AudioMedia = () => {
    const { t } = useTranslation();
    return (
        <EmbeddedMediaPage
            category="AUDIO"
            headerPageTitle={t('pages.editor.media.audio.header.title')}
            helpMessage={t('pages.editor.media.audio.help.message')}
        />
    );
};
