import { useTranslation } from 'react-i18next';

import { EmptyIcon } from './icons/Empty';
import { Container } from './styles';

export const EmptyState = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <div className="image">
                <EmptyIcon />
            </div>
            <div className="text">
                <h3>{t('pages.sites.emptyState.title')}</h3>
                <p>{t('pages.sites.emptyState.description')}</p>
            </div>
        </Container>
    );
};
