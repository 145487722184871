import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useClipboard from 'react-use-clipboard';

import { Button, LinkButton } from '../../../../../../components/Button';
import { MediumCircleContainer } from '../../../../../../components/CircleContainer/styles';
import { Box } from '../../../../../../components/Layout';
import constants from '../../../../../../constants';
import { useSite } from '../../../../../../contexts/Site';
import CloseIcon from '../../../../../../icons/Close';
import { SuccessIcon } from '../../../../../../icons/Success';
import { classNames } from '../../../../../../utils/styles';
import { extractUrlParam } from '../../../../../../utils/url';
import { Container, UrlBoxSection } from './styles';

const PublishSuccessPage = () => {
    const { t } = useTranslation();
    const { site } = useSite();

    const firstPublised = extractUrlParam('first') === 'true';

    const [isUrlCopied, setUrlCopied] = useClipboard(
        `${constants.site}/${site?.url}`,
        {
            successDuration: 4000,
        }
    );

    return (
        <Container
            className={classNames({
                republish: !firstPublised,
            })}
        >
            <div className="close">
                <Link to="/sites">
                    <MediumCircleContainer
                        $color="var(--color-neutral-base)"
                        cursor="pointer"
                    >
                        <CloseIcon height={16} width={16} />
                    </MediumCircleContainer>
                </Link>
            </div>
            <div className="content">
                {firstPublised && (
                    <div className="svg-container">
                        <SuccessIcon />
                    </div>
                )}
                <h2>
                    {firstPublised
                        ? t('pages.editor.successPublish.publishMessage')
                        : t('pages.editor.successPublish.republishMessage')}
                </h2>
            </div>
            <Box className="buttons">
                <div>
                    <UrlBoxSection>
                        <h6>
                            {constants.nameSite}/{site?.url}
                        </h6>
                    </UrlBoxSection>
                </div>
                <div>
                    <Button
                        type={isUrlCopied ? 'WHITE' : 'INFO'}
                        size="LG"
                        onClick={setUrlCopied}
                    >
                        {isUrlCopied
                            ? t(
                                  'pages.editor.successPublish.actions.copiedLink'
                              )
                            : t('pages.editor.successPublish.actions.copyLink')}
                    </Button>
                </div>
                <div>
                    <LinkButton
                        onClick={() =>
                            window.open(`${constants.site}/${site?.url}`)
                        }
                    >
                        {t('pages.editor.successPublish.actions.openSite')}
                    </LinkButton>
                </div>
            </Box>
        </Container>
    );
};

export default PublishSuccessPage;
