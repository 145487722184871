import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import Label from '../Label';
import { inputCss } from './styles';

const BasicInput = styled.input`
    ${inputCss};
`;

type ContainerProps = {
    focus: boolean;
};

const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;

    border-radius: 10px;
    border: 2px solid var(--color-neutral-light);

    color: var(--color-neutral-white);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1rem;
    font-size: 0.875rem;

    padding: 0px;
    padding-left: 16px;
    margin: 8px 0px;
    width: 100%;

    border-color: ${(props) => props.focus && 'var(--color-brand-pink)'};

    + label {
        color: ${(props) => props.focus && 'var(--color-neutral-white)'};
    }

    input {
        ${inputCss};
        margin: 0px;
        border: none;
        margin-left: -14px;
        font-weight: inherit;
        font-size: inherit;
        padding-right: 10px;
        margin-right: 10px;
        flex: 1;
        color: inherit;
        &:focus {
            outline: none;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            /* color: var(--color-neutral-white); */
            color: var(--color-neutral-light);
            opacity: 0.7; /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: var(--color-neutral-light);
            opacity: 0.7; /* Firefox */
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: var(--color-neutral-light);
            opacity: 0.7; /* Firefox */
        }
    }

    span {
        color: var(--color-neutral-light);
    }

    svg {
        margin-right: 16px;
    }

    &.no-value svg {
        path:nth-child(1) {
            fill: var(--color-neutral-light);
        }
        path:nth-child(2) {
            fill: #020202;
        }
        path:nth-child(3) {
            fill: var(--color-neutral-light);
        }
    }
`;

type Props = {
    id: string;
    type: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    prefix?: string;
    label: string;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    ignoreLengthInfo?: boolean;
    loading?: boolean;
    infoMessage?: string;
    autoFocus?: boolean;
    autoCapitalize?: string;
    icon?: React.ReactNode;
    ignoreNoValueIconClass?: boolean;
    input?: React.ReactNode;
};

const Input = ({
    id,
    type,
    value = '',
    onChange,
    maxLength,
    prefix,
    label,
    required,
    placeholder,
    disabled,
    ignoreLengthInfo,
    loading,
    infoMessage,
    autoFocus,
    autoCapitalize = 'on',
    icon,
    ignoreNoValueIconClass,
    input,
}: Props) => {
    const [focus, setFocus] = useState(false);

    return (
        <>
            <Container
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                focus={focus}
                className={!value && !ignoreNoValueIconClass ? 'no-value' : ''}
            >
                {icon && icon}
                <span>{prefix}</span>
                {input ? (
                    input
                ) : (
                    <BasicInput
                        autoFocus={autoFocus}
                        id={id}
                        name={id}
                        type={type}
                        value={value}
                        onChange={onChange}
                        maxLength={maxLength}
                        autoCapitalize={autoCapitalize}
                        spellCheck="false"
                        autoComplete="off"
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                )}
            </Container>
            <Label
                htmlFor={id}
                required={required}
                text={label}
                usedLenght={value.length}
                maxLenght={maxLength}
                loading={loading}
                ignoreLengthInfo={ignoreLengthInfo}
                infoMessage={infoMessage}
            />
        </>
    );
};

export default Input;
