interface AuthIconProps extends React.ComponentProps<'svg'> {}

const UnionKeepo = ({ ...rest }: AuthIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="447"
        height="447"
        fill="none"
        viewBox="0 0 447 447"
        {...rest}
    >
        <path
            fill="#FF82DC"
            fillRule="evenodd"
            d="M149 0c27.139 0 52.584 7.256 74.5 19.933C245.416 7.256 270.861 0 298 0c82.29 0 149 66.71 149 149 0 27.295-7.339 52.875-20.152 74.876C439.661 245.802 447 271.297 447 298.5c0 82.014-66.71 148.5-149 148.5-27.139 0-52.584-7.232-74.5-19.867C201.584 439.768 176.139 447 149 447 66.71 447 0 380.514 0 298.5c0-27.203 7.34-52.698 20.152-74.624C7.339 201.875 0 176.295 0 149 0 66.71 66.71 0 149 0z"
            clipRule="evenodd"
        ></path>
    </svg>
);

const StarKeepo = ({ ...rest }: AuthIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="136"
        height="136"
        fill="none"
        viewBox="0 0 136 136"
        {...rest}
    >
        <path
            fill="#FFE01B"
            d="M68 0l5.704 39.322L94.023 5.176l-9.778 38.512 31.838-23.771-23.77 31.838 38.511-9.778-34.146 20.319L136 68l-39.322 5.704 34.146 20.319-38.512-9.778 23.771 31.838-31.838-23.77 9.777 38.511-20.318-34.146L68 136l-5.704-39.322-20.319 34.146 9.778-38.512-31.838 23.771 23.77-31.838-38.51 9.777 34.145-20.318L0 68l39.322-5.704L5.176 41.977l38.512 9.778-23.771-31.838 31.838 23.77-9.778-38.51 20.319 34.145L68 0z"
        ></path>
    </svg>
);

export const AuthIcon = {
    UnionKeepo,
    StarKeepo,
};
