export function extractUrlParam(name: string): string | undefined {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.href);
    return match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : undefined;
}

export const sanitizeUrl = (url?: string): string | undefined => {
    if (!url || url.startsWith('https') || url.startsWith('http')) {
        return url;
    }
    return `https://${url}`;
};
