import axios from 'axios';
import { Area } from 'react-easy-crop/types';

import constants from '../../constants';
import { Site } from '../../model/site';
import { TrackingIntegrations } from './models';

export interface Subscription {
    type: 'PLUS' | 'FREE';
    canceled?: boolean;
}

export interface ApiSiteResponse {
    site: Site;
    siteId: string;
    draft: Site;
    disabled: boolean;
    subscription?: Subscription;
    tracking?: TrackingIntegrations;
}

export interface MailChimpAudienceList {
    id: string;
    name: string;
}

export interface Spreadsheet {
    id: string;
    name: string;
}

export interface CropSize {
    width: number;
    height: number;
}

export interface User {
    name: string;
    givenName: string;
    email: string;
    picture: string;
    onboardingCompleted: boolean;
    new?: boolean;
    ref?: string;
    identities: {
        providerType: string;
        userId: string;
    }[];
}

export interface Checkout {
    siteId?: string;
    plan: 'MONTHLY' | 'ANNUALLY';
    currentPage: string;
}

export interface HelpRequest {
    subject: string;
    message: string;
    email: string;
}

export interface SubscriptionDetail {
    siteId: string;
    billingPortalSessionUrl: string;
    currentPeriodEnd: string;
    canceledAt?: string;
    invoices: {
        status: string;
        createdAt: string;
        card?: {
            last4: string;
        };
    }[];
}

class Api {
    saveDraftPromise = Promise.resolve();
    sequencePromise = Promise.resolve<void | ApiSiteResponse[] | undefined>(
        undefined
    );

    user: Promise<User> | undefined = undefined;
    sites: ApiSiteResponse[] | undefined = undefined;

    saveDraft(id: string, site: Site) {
        this.sites = undefined;
        this.saveDraftPromise = this.saveDraftPromise.then(() =>
            axios.put(`${constants.api}/sites/${id}/draft`, site)
        );
    }

    publish(id: string, site: Site) {
        this.sites = undefined;
        this.sequencePromise = this.sequencePromise.then(() => {
            return axios.put(`${constants.api}/sites/${id}/publish`, site);
        });
        return this.sequencePromise;
    }

    clearCacheSites() {
        this.sites = undefined;
    }

    deleteDraf(id: string) {
        this.sites = undefined;
        return axios.delete(`${constants.api}/sites/${id}/draft`);
    }

    deleteSite(id: string) {
        this.sites = undefined;
        return axios.delete(`${constants.api}/sites/${id}`);
    }

    getSites(): Promise<ApiSiteResponse[]> {
        this.sequencePromise = this.sequencePromise.then(() => {
            if (this.sites) {
                return Promise.resolve(this.sites);
            }
            return axios.get(`${constants.api}/sites`).then((response) => {
                this.sites = response.data as ApiSiteResponse[];
                return this.sites;
            });
        });
        return this.sequencePromise as Promise<ApiSiteResponse[]>;
    }

    checkUrl(siteId: string, url: string) {
        return axios.post(`${constants.api}/check-url`, { siteId, url });
    }

    getAnalytics(siteId: string) {
        return axios
            .get(`${constants.api}/sites/${siteId}/analytics`)
            .then((response) => response?.data?.summary);
    }

    disableSite(id: string) {
        this.sites = undefined;
        this.sequencePromise = this.sequencePromise.then(() =>
            axios.put(`${constants.api}/sites/${id}/disable`)
        );
    }

    enableSite(id: string) {
        this.sites = undefined;
        this.sequencePromise = this.sequencePromise.then(() =>
            axios.put(`${constants.api}/sites/${id}/enable`)
        );
        return this.sequencePromise;
    }

    authorizeUpload(siteId: string, mimeType: string) {
        return axios
            .post(`${constants.api}/sites/${siteId}/authorize-upload`, {
                mimeType,
            })
            .then((response) => response.data);
    }

    upload(url: string, file: File) {
        return axios.put(url, file, {
            headers: { 'Content-Type': file.type },
        });
    }

    cropImage(siteId: string, key: string, area: Area, size: CropSize) {
        return axios
            .post(`${constants.api}/sites/${siteId}/images/crop`, {
                area,
                key,
                size,
            })
            .then((response) => response.data);
    }

    createMailChimpFormConnection(siteId: string, authorizationCode: string) {
        return axios
            .put(
                `${constants.api}/sites/${siteId}/form/connections/mailchimp`,
                { authorizationCode }
            )
            .then((response) => response.data);
    }

    getAudienceListsMailChimpFormConnection(
        siteId: string,
        connectionId: string
    ): Promise<MailChimpAudienceList[]> {
        return axios
            .get(
                `${constants.api}/sites/${siteId}/form/connections/${connectionId}/audience-lists`
            )
            .then(({ data }) => data);
    }

    createGoogleDriveFormConnection(siteId: string, authorizationCode: string) {
        return axios
            .put(
                `${constants.api}/sites/${siteId}/form/connections/googledrive`,
                { authorizationCode }
            )
            .then((response) => response.data);
    }

    createOrUpdateSpreadsheetGoogleDriveFormConnection(
        siteId: string,
        connectionId: string,
        name: string,
        spreadsheetId?: string
    ): Promise<Spreadsheet> {
        return axios
            .put(
                `${constants.api}/sites/${siteId}/form/connections/${connectionId}/spreadsheet`,
                { name, id: spreadsheetId }
            )
            .then(({ data }) => data);
    }

    redirectCheckout(checkout: Checkout) {
        return axios
            .post(`${constants.api}/subscription/checkout`, checkout)
            .then(({ data }) => data.url);
    }

    getInitialUser({
        defaultLang,
        ref,
    }: {
        defaultLang?: string;
        ref?: string;
    }): Promise<User> {
        if (this.user) {
            return new Promise((resolve) => {
                setTimeout(async () => resolve((await this.user) as User), 200);
            });
        }
        this.user = axios
            .get(
                `${constants.api}/users/me?defaultLang=${defaultLang}&ref=${ref}`
            )
            .then((response) => response.data);
        return this.user;
    }

    getUser(): Promise<User> {
        return this.getInitialUser({});
    }

    submitFeedback(value: number, message?: string) {
        return axios.post(`${constants.api}/feedbacks`, { value, message });
    }

    submitHelp(help: HelpRequest) {
        return axios.post(`${constants.api}/users-helps`, help);
    }

    getTerms(lang: string, version: string) {
        return axios
            .get(
                `${constants.staticBucket}/keepo/privacy-and-terms/${lang}.html?${version}`
            )
            .then((response) => response.data);
    }

    completeOnboarding() {
        return Promise.all([
            axios.post(`${constants.api}/users/me/onboarding/complete`, {}),
            this.user?.then((user) => (user.onboardingCompleted = true)),
        ]);
    }

    updateLang(lang: string) {
        return axios.put(`${constants.api}/users/me/lang`, { lang });
    }

    updateTrackingIntegrations(siteId: string, tracking: TrackingIntegrations) {
        this.sites = undefined;
        return (this.sequencePromise = this.sequencePromise.then(() =>
            axios.put(`${constants.api}/sites/${siteId}/tracking`, tracking)
        ));
    }

    getSubscriptionDetail(siteId: string): Promise<SubscriptionDetail> {
        return axios
            .get(`${constants.api}/sites/${siteId}/subscription`)
            .then((response) => response.data);
    }

    cancelSubscription(siteId: string): Promise<{ downgradedDone: boolean }> {
        return axios
            .delete(`${constants.api}/sites/${siteId}/subscription`)
            .then((response) => response.data);
    }
}

export const api = new Api();
