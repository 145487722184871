const InkIcon = () => {
    return (
        <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00003 0L2.34313 5.65677C-0.781043 8.78093 -0.781043 13.8462 2.34313 16.9704C3.90524 18.5325 5.95266 19.3136 8.00003 19.3135C8.25594 19.3135 8.51185 19.3013 8.76678 19.277C10.5516 19.1061 12.29 18.3372 13.6569 16.9704C16.781 13.8462 16.781 8.78093 13.6569 5.65677L8.00003 0ZM3.6002 6.91384L7.99995 2.51409L8.00004 17.5357C6.4076 17.5358 4.81518 16.9283 3.6002 15.7133C1.17029 13.2834 1.17029 9.34377 3.6002 6.91384Z"
                fill="#181A20"
            />
        </svg>
    );
};

export default InkIcon;
