import ChevronLeftIcon from '../../../../icons/ChevronLeft';
import ChevronRightIcon from '../../../../icons/ChevronRight';
import { Period } from '../../utils';
import { Container } from './styles';

type Props = {
    periods: Period[];
    activePeriod: number;
    onSelect: (index: number) => void;
};

const iconColor = (active: boolean) =>
    active ? 'var(--color-brand-blue)' : 'var(--color-neutral-light)';

export const PeriodSelection = ({ periods, activePeriod, onSelect }: Props) => {
    return (
        <Container>
            <button
                onClick={() => onSelect(activePeriod - 1)}
                disabled={activePeriod === 0}
            >
                <ChevronLeftIcon color={iconColor(activePeriod > 0)} />
            </button>
            <span>{periods[activePeriod].title}</span>
            <button
                onClick={() => onSelect(activePeriod + 1)}
                disabled={activePeriod + 1 === periods.length}
            >
                <ChevronRightIcon
                    color={iconColor(activePeriod + 1 !== periods.length)}
                />
            </button>
        </Container>
    );
};
