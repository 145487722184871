const urls = ['images/ga.png', 'images/pixel.png', 'images/tag.png'];

export const fetch = () => {
    const promises = urls.map((url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = url;
            image.onload = resolve;
            image.onerror = reject;
        });
    });
    return Promise.all(promises);
};
