import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-input-slider';

import { Button } from '../Button';
import { ControlsContainer, CropContainer, CropModal } from './styles';

type Props = {
    file: File;
    aspect: number;
    cropShape: 'round' | 'rect';
    onSave: (area?: Area) => void;
};

const sliderStyles = {
    track: {
        width: '140px',
        backgroundColor: 'var(--color-neutral-base)',
    },
    active: {
        backgroundColor: 'var(--color-neutral-base)',
    },
    thumb: {
        width: '24px',
        height: '24px',
    },
};

const maxZoom = 6;

export const ImageCropModal = ({ file, onSave, aspect, cropShape }: Props) => {
    const { t } = useTranslation();

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

    const [objectFit, setObjectFit] = useState<
        'horizontal-cover' | 'vertical-cover' | undefined
    >();

    const [src, setSrc] = useState<string | undefined>();

    const setFile = useCallback(async () => {
        const src = await readFileAsDataURL(file);
        const image = await createImage(src);
        setObjectFit(
            image.height < image.width
                ? 'vertical-cover'
                : image.height > image.width
                  ? 'horizontal-cover'
                  : undefined
        );
        setSrc(src);
    }, [file]);

    const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onZoomChangeHandler = useCallback(({ x }: { x: number }) => {
        setZoom(x);
    }, []);

    const saveHandler = async () => {
        if (src && croppedAreaPixels) {
            onSave(croppedAreaPixels);
        }
    };

    useEffect(() => {
        setFile();
    }, [file, setFile]);

    return ReactDOM.createPortal(
        <CropModal title={t('actions.resizeImage')} onExit={onSave}>
            <CropContainer $aspect={aspect}>
                {src && (
                    <Cropper
                        image={src}
                        crop={crop}
                        cropShape={cropShape}
                        zoom={zoom}
                        maxZoom={maxZoom}
                        zoomSpeed={8}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        objectFit={objectFit}
                        style={{
                            cropAreaStyle: {
                                border: 'none',
                            },
                        }}
                    />
                )}
            </CropContainer>
            <ControlsContainer>
                <div>
                    <Slider
                        xmin={1}
                        xmax={maxZoom}
                        axis="x"
                        x={zoom}
                        onChange={onZoomChangeHandler}
                        styles={sliderStyles}
                        xstep={0.01}
                    />
                </div>
                <Button type="INFO" size="LG" onClick={saveHandler}>
                    {t('actions.save')}
                </Button>
            </ControlsContainer>
        </CropModal>,
        document.getElementById('root') as Element
    );
};

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

const readFileAsDataURL = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result as string);
        });
        reader.addEventListener('error', reject);
        reader.readAsDataURL(file);
    });
