import styled from 'styled-components';

export const Container = styled.div`
    padding: 16px 24px;
    border: 1px solid var(--color-neutral-base);
    box-sizing: border-box;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    .text {
        > p {
            /* margin-top: 6px; */
        }
    }

    .button {
        > .badge {
            padding: 8px 16px;
        }
        h6 {
            text-transform: none;
        }
    }
`;
