import styled from 'styled-components';

export const Container = styled.div`
    > .image-section {
        margin-top: 40px;
        padding: 32px 0;
        display: flex;
        justify-content: center;

        @media (max-width: 320px) {
            margin-top: 0px;
        }
    }

    p {
        span {
            font-weight: bold;
        }
    }

    > .text-section {
        padding: 0 32px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            max-width: 70%;
        }

        > p {
            margin-top: 16px;
        }

        .price-detail {
            display: flex;
            margin-top: 16px;

            p {
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
            .old-price {
                margin-right: 10px;
                font-weight: 400;
                color: var(--color-neutral-150);
            }
        }
    }

    .box-controls {
        @media (max-height: 590px) {
            padding: 0;
        }
    }

    .controls {
        display: flex;
        justify-content: center;

        > .control {
            width: 8px;
            height: 8px;
            background-color: var(--color-neutral-base);
            margin-left: 8px;
            border-radius: 100%;
            cursor: pointer;
        }

        > .active {
            background-color: var(--color-brand-blue);
        }
    }

    > .footer {
        margin-top: 80px;

        > .box {
            @media (max-height: 590px) {
                padding-bottom: 1rem;
            }
        }

        @media (max-height: 1024px) {
            position: fixed;
            bottom: 0;
            width: min(480px, 100%);
            margin: 0 auto;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .skip {
                color: var(--color-neutral-white);
            }
        }
    }
`;
