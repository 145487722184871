import { User } from '../api';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

class KeepoInternalAnalyticsService {
    notifyLoginEvent(user: User) {
        if (user.new) {
            this.publishEvent('sign_up', user);
        }
    }

    notifyPurchaseEvent(user: User) {
        this.publishEvent('purchase', user);
    }

    notifyCheckoutEvent(user: User) {
        this.publishEvent('checkout', user);
    }

    notifyPreCheckoutEvent(user: User) {
        this.publishEvent('pre_checkout', user);
    }

    private publishEvent(event: string, user: User) {
        try {
            window.dataLayer.push({
                event,
                method: user.identities[0].providerType,
                ref: user.ref,
                userId: user.identities[0].userId,
            });
        } catch (e) {
            console.log(e);
        }
    }
}

export const keepoInternalAnalyticsService =
    new KeepoInternalAnalyticsService();
