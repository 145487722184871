import styled from 'styled-components';

export const Container = styled.div``;

type ContainerProps = {
    hide: boolean;
};

export const PlayerContainer = styled.div<ContainerProps>`
    margin-top: 1rem;
    display: ${(props) => props.hide && 'none'};
`;

export const PreviewContainer = styled.div`
    width: 100%;
    height: 200px;
    border: 2px dashed var(--color-neutral-light);
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
