import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ConfirmModal from '../../../../components/ConfirmModal';
import Input from '../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { CatalogSection, ItemSectionCatalog } from '../../../../model/site';
import { clone, deepEqual } from '../../../../utils/objects';
import ItemsTable from '../../components/ItemsTable';
import { useSection } from '../../hooks/Section';
import { navigationState } from '../Sections/navigationState';
import { CatalogNavigationState } from './catalogNavigationState';
import { Container } from './styles';

const CatalogPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const catalogNavigationState = useRef(
        navigationState.get() as CatalogNavigationState
    );

    const restoreState = useCallback(
        (newly: unknown, section: CatalogSection) => {
            if (catalogNavigationState.current) {
                section.pageTitle =
                    catalogNavigationState.current.currentSection.pageTitle;
                section.items =
                    catalogNavigationState.current.currentSection.items;
            } else if (newly) {
                section.pageTitle = t('pages.editor.catalog.header.title');
                section.items = [];
            }
            return section;
        },
        [t, catalogNavigationState]
    );

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<CatalogSection>('catalog', restoreState);

    const [idRemoving, setIdRemoving] = useState<string>();

    const onAddClick = () => {
        goEditItemPage();
    };

    const onEditClick = (id: string) => {
        goEditItemPage(id);
    };

    const goEditItemPage = (id?: string) => {
        const state: CatalogNavigationState = {
            currentSection: clone<CatalogSection>(section),
            defaultSection:
                catalogNavigationState.current?.defaultSection ||
                clone<CatalogSection>(section),
            editingItem: section.items.find((link) => link.id === id),
        };
        navigationState.set(state);
        navigate('/editor/catalog/item');
    };

    const onRemoveClick = (linkId: string) => {
        setIdRemoving(linkId);
    };

    const removeItem = () => {
        const items = [...section.items];
        const index = items.findIndex(({ id }) => id === idRemoving);

        items.splice(index, 1);
        setSection({ ...section, items });
        setIdRemoving(undefined);
    };

    const hasUnsavedChangesWrapper = (): boolean => {
        return catalogNavigationState?.current?.defaultSection
            ? !deepEqual(section, catalogNavigationState.current.defaultSection)
            : hasUnsavedChanges();
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.catalog.header.title')}
                hasUnsavedChanges={hasUnsavedChangesWrapper}
            />
            {idRemoving && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.catalog.actions.remove.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.catalog.actions.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.catalog.actions.remove.confirmModal.confirmButton'
                    )}
                    onCancel={() => setIdRemoving(undefined)}
                    onConfirm={removeItem}
                />
            )}
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            type="text"
                            value={section.pageTitle}
                            label={t('pages.editor.links.form.pageTitle')}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <ItemsTable
                        onSortUpdate={(items) =>
                            setSection({
                                ...section,
                                items: items as ItemSectionCatalog[],
                            })
                        }
                        onAddClick={onAddClick}
                        onEditClick={onEditClick}
                        onRemoveClick={onRemoveClick}
                        items={section.items}
                        title={t('pages.editor.catalog.form.table.title')}
                        addButtonTitle={t(
                            'pages.editor.catalog.form.table.addButton'
                        )}
                    />
                </Box>
            </Form>
            <SaveBar
                onSave={saveSection}
                hasUnsavedChanges={hasUnsavedChangesWrapper}
            />
        </Container>
    );
};

export default CatalogPage;
