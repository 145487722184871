import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '../../../../../../components/ConfirmModal';
import Input from '../../../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import { LoadingModal } from '../../../../../../components/LoadingModal';
import SaveBar from '../../../../../../components/SaveBar';
import constants from '../../../../../../constants';
import { useAppState } from '../../../../../../contexts/App';
import { useSite } from '../../../../../../contexts/Site';
import { GoogleDriveStorage } from '../../../../../../model/site';
import { api } from '../../../../../../services/api';
import { navigationState } from '../../../Sections/navigationState';
import { IntegrationConnectionButton } from '../../components/IntegrationConnectionButton';
import StorageIntegrationServiceInfo from '../../components/StorageIntegrationServiceInfo';
import { FormNavigationState } from '../../formNavigationState';
import GoogleDriveIcon from '../../icons/GoogleDrive';
import { authenticationFlow } from '../../services/authentication-flow';
import { SpreadsheetInputContainer } from './styles';

const getTokenUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount\
?client_id=${constants.googleDriveFormIntegrationClientId}\
&redirect_uri=${constants.site}/callback.html\
&response_type=code\
&scope=https://spreadsheets.google.com/feeds https://www.googleapis.com/auth/drive https://spreadsheets.google.com/feeds/\
&access_type=offline&state=-_Me0cZWtNlczSmJXGiPw-GiwLKLv0IL9C9ZNbuF&prompt=consent&flowName=GeneralOAuthFlow`;

export const GoogleDriveStoragePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { site } = useSite();

    const siteTitle = site?.title as string;

    const defaultSpreadsheetName = t(
        'pages.editor.form.form.storage.googleDrive.form.spreadsheet.defaultValue'
    ).replace('${name}', siteTitle);

    const editingSiteId = useAppState().appState?.editingSiteId as string;

    const [isCreatingConfiguration, setCreatingConfiguration] = useState(false);
    const [isShowDisconnectConfirmModal, setShowDisconnectConfirmModal] =
        useState(false);
    const [
        isShowChangeSpreadsheetConfirmModal,
        setShowChangeSpreadsheetConfirmModal,
    ] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const formNavigationState = navigationState.get() as FormNavigationState;

    const section = formNavigationState.section;

    const [googleDrive, setGoogleDrive] = useState({
        ...section.storage.googleDrive,
    });

    const [spreadsheetName, setSpreadsheetName] = useState(
        googleDrive?.spreadsheet?.name || defaultSpreadsheetName
    );

    const hasUnsavedChanges =
        googleDrive.active &&
        (!section.storage.googleDrive.active ||
            (!!spreadsheetName &&
                spreadsheetName !==
                    section.storage?.googleDrive?.spreadsheet?.name));

    const spreadsheetNameHasChanged =
        !!section.storage?.googleDrive?.spreadsheet?.name &&
        section.storage?.googleDrive?.spreadsheet?.name !== spreadsheetName;

    const updateState = (googleDrive: GoogleDriveStorage) => {
        const state = {
            ...formNavigationState,
            section: {
                ...section,
                storage: {
                    ...section.storage,
                    googleDrive,
                },
            },
        };
        navigationState.set(state);
    };

    const startConnectFlow = async () => {
        if (isCreatingConfiguration) {
            return;
        }
        const code = await authenticationFlow.getCodeFromModal({
            url: getTokenUrl,
            target: 'googleDrive',
        });
        setCreatingConfiguration(true);
        try {
            const { id } = await api.createGoogleDriveFormConnection(
                editingSiteId,
                code
            );
            setGoogleDrive({ active: true, connectionId: id });
        } finally {
            setCreatingConfiguration(false);
        }
    };

    const disconnect = () => setShowDisconnectConfirmModal(true);

    const confirmDisconnect = () => {
        const googleDriveCopy = { active: false };

        setGoogleDrive(googleDriveCopy);
        updateState(googleDriveCopy);
        setShowDisconnectConfirmModal(false);
    };

    const saveHandler = async () => {
        if (spreadsheetNameHasChanged) {
            setShowChangeSpreadsheetConfirmModal(true);
            return;
        }
        confirmSaveHandler();
    };

    const confirmSaveHandler = async () => {
        const spreadsheet = await createOrUpdateSpreadsheet();
        const googleDriveCopy = { ...googleDrive, spreadsheet: spreadsheet };

        setGoogleDrive(googleDriveCopy);
        updateState(googleDriveCopy);
        navigate(-1);
    };

    const createOrUpdateSpreadsheet = async () => {
        try {
            setSaving(true);
            return await api.createOrUpdateSpreadsheetGoogleDriveFormConnection(
                editingSiteId,
                googleDrive.connectionId as string,
                spreadsheetName,
                googleDrive.spreadsheet?.id
            );
        } finally {
            setSaving(false);
        }
    };

    const confirmSpreadsheetChange = () => {
        setShowChangeSpreadsheetConfirmModal(false);
        confirmSaveHandler();
    };

    useEffect(() => {
        return () => authenticationFlow.reset();
         
    }, []);

    return (
        <div>
            {(isCreatingConfiguration || isSaving) && <LoadingModal />}
            <Header
                title="Google Drive"
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <StorageIntegrationServiceInfo
                LogoIcon={GoogleDriveIcon}
                logoContainerColor="var(--color-neutral-white)"
                info={t('pages.editor.form.form.storage.googleDrive.info')}
            />
            <Box borderBottom>
                {googleDrive.active && (
                    <SpreadsheetInputContainer>
                        <FormGroup>
                            <Input
                                id="spreadsheetName"
                                onChange={(e) =>
                                    setSpreadsheetName(e.target.value)
                                }
                                value={spreadsheetName}
                                label={t(
                                    'pages.editor.form.form.storage.googleDrive.form.spreadsheet.label'
                                )}
                                type="string"
                            />
                        </FormGroup>
                    </SpreadsheetInputContainer>
                )}
                <IntegrationConnectionButton
                    status={googleDrive.active ? 'CONNECTED' : 'DISCONNECTED'}
                    connectTitle={t(
                        'pages.editor.form.form.storage.googleDrive.connectButton'
                    )}
                    onConnect={startConnectFlow}
                    onDisconnect={disconnect}
                />
            </Box>
            <Form>
                <SaveBar
                    onSave={saveHandler}
                    disabled={!googleDrive.active || !spreadsheetName?.trim()}
                    cancelTitle={t('actions.goBack')}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
            {isShowDisconnectConfirmModal && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.form.form.storage.googleDrive.actions.disconnect.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.form.form.storage.googleDrive.actions.disconnect.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.form.form.storage.googleDrive.actions.disconnect.confirmModal.confirmButton'
                    )}
                    onConfirm={confirmDisconnect}
                    onCancel={() => setShowDisconnectConfirmModal(false)}
                />
            )}
            {isShowChangeSpreadsheetConfirmModal && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.form.form.storage.googleDrive.actions.save.updateSpreadsheetModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.form.form.storage.googleDrive.actions.save.updateSpreadsheetModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.form.form.storage.googleDrive.actions.save.updateSpreadsheetModal.confirmButton'
                    )}
                    onConfirm={confirmSpreadsheetChange}
                    onCancel={() => setShowChangeSpreadsheetConfirmModal(false)}
                />
            )}
        </div>
    );
};
