import styled from 'styled-components';

export const Container = styled.div`
    .keepos {
        .keepo {
            display: flex;
            justify-content: space-between;
            background-color: var(--color-neutral-dark);
            border-radius: 8px;
            padding: 1.5rem;

            margin-bottom: 1rem;
            cursor: pointer;

            h6 {
                margin-bottom: 0.5rem;
            }

            p {
                color: var(--color-neutral-150);
            }

            .status {
                width: 8px;
                height: 8px;
                background-color: var(--color-lemon);
                border-radius: 50%;

                &.canceled {
                    background-color: var(--color-feedback-danger);
                }
            }
        }
    }
`;
