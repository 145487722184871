export const ShapeIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.66634 2.99967H20.333C21.0694 2.99967 21.6663 2.40272 21.6663 1.66634C21.6663 0.929961 21.0694 0.333008 20.333 0.333008H1.66634C0.929961 0.333008 0.333008 0.929961 0.333008 1.66634C0.333008 2.40272 0.929961 2.99967 1.66634 2.99967Z"
                fill="white"
            />
            <path
                d="M1.66634 21.6663H20.333C21.0694 21.6663 21.6663 21.0694 21.6663 20.333C21.6663 19.5966 21.0694 18.9997 20.333 18.9997H1.66634C0.929961 18.9997 0.333008 19.5966 0.333008 20.333C0.333008 21.0694 0.929961 21.6663 1.66634 21.6663Z"
                fill="white"
            />
            <path
                d="M20.333 12.333H1.66634C0.929961 12.333 0.333008 11.7361 0.333008 10.9997C0.333008 10.2633 0.929961 9.66634 1.66634 9.66634H20.333C21.0694 9.66634 21.6663 10.2633 21.6663 10.9997C21.6663 11.7361 21.0694 12.333 20.333 12.333Z"
                fill="white"
            />
        </svg>
    );
};
