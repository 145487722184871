import React from 'react';

interface IconProps extends React.ComponentProps<'svg'> {}

const ArrowRightIcon = ({ ...rest }: IconProps) => {
    return (
        <svg
            {...rest}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 12H19M19 12L12 5M19 12L12 19"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowRightIcon;
