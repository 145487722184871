import styled from 'styled-components';

export const Container = styled.div`
    .top {
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
            margin-bottom: 1rem;
        }
    }
    .group-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 37px 0 0;
    }
    .offer-title {
        text-align: center;
    }

    .offer-items-wrapper {
        margin: 0rem 2rem;
    }

    .offer-items {
        margin: 0rem 1.5rem;

        .item {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            > :first-child {
                min-width: 24px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            > h6 {
                margin-left: 1rem;
                line-height: 20px;
            }
        }
    }

    .group-prices {
        padding: 2rem 0rem;
        margin: 0rem 2rem;

        .price-detail {
            p {
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
            .old-price {
                margin-right: 10px;
                font-weight: 400;
                color: var(--color-neutral-150);
            }
        }
    }

    .prices-info {
        text-align: center;
        margin: 16px 0 0;

        p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: var(--color-neutral-150);
        }
    }

    .off-badge {
        @media (min-width: 320px) {
            min-width: 63px;
            padding: 4px 4px;
        }
    }

    .group-button {
        position: relative;
        height: 96px;

        .fixed-button {
            padding: 1.5rem 2rem;
            position: fixed;
            background: var(--color-neutral-darkness);
            bottom: 0;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            max-width: 480px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
`;
