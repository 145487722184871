type Props = {
    color: string;
};

const UnionIcon = ({ color }: Props) => {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H3ZM17 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H17C17.5523 14 18 13.5523 18 13V3C18 2.44772 17.5523 2 17 2ZM5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6V10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H7C7.55228 12 8 11.5523 8 11C8 10.4477 7.55228 10 7 10V6C7.55228 6 8 5.55228 8 5C8 4.44772 7.55228 4 7 4H5Z"
                fill={color}
            />
        </svg>
    );
};

export default UnionIcon;
