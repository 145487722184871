import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../components/Button';
import { MediumCircleContainer } from '../../../../components/CircleContainer/styles';
import LaunchIcon from '../../../../components/Launch';
import { PlusLabel } from '../../../../components/PlusLabel';
import constants from '../../../../constants';
import { AppState, useAppState } from '../../../../contexts/App';
import ChartIcon from '../../../../icons/Chart';
import EngineIcon from '../../../../icons/Engine';
import { Site } from '../../../../model/site';
import { Subscription } from '../../../../services/api';
import { TrackingIntegrations } from '../../../../services/api/models';
import {
    Container,
    DescriptionSection,
    EditButton,
    FirstLineSection,
    IconStatus,
    RightButtonsSection,
    SecondLineSection,
    ThirdLineSection,
} from './styles';

type Props = {
    site: Site;
    siteId: string;
    subscription?: Subscription;
    disabled: boolean;
    onEditClick: () => void;
    onEnableClick: () => void;
    setSite: (site: Site) => void;
    trackingIntegrations?: TrackingIntegrations;
};

const CardSite = ({
    site,
    siteId,
    onEditClick,
    onEnableClick,
    disabled,
    setSite,
    subscription = { type: 'FREE' },
    trackingIntegrations,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();

    const configureSite = useCallback(() => {
        setAppState({
            ...appState,
            editingSiteId: siteId,
            editingSiteDisabled: disabled,
            editingSiteSubscription: subscription,
            editingSiteTrackingIntegrations: trackingIntegrations,
        } as AppState);
        setSite(site);
    }, [
        appState,
        site,
        siteId,
        disabled,
        setAppState,
        setSite,
        subscription,
        trackingIntegrations,
    ]);

    const statisticsOnClickHandler = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();

            if (disabled) return;

            configureSite();

            navigate('/analytics');
        },
        [disabled, navigate, configureSite]
    );

    const settingsHandler = (e: React.MouseEvent) => {
        if (disabled) {
            return;
        }

        configureSite();

        navigate('/settings');
        e.stopPropagation();
    };

    const launchHandler = (e: React.MouseEvent) => {
        if (disabled) {
            return;
        }
        window.open(`${constants.site}/${site.url}`);
        e.stopPropagation();
    };

    const iconCircleContainerColor = disabled
        ? 'var(--color-neutral-dark)'
        : 'var(--color-neutral-base)';

    const iconColor = disabled
        ? 'var(--color-neutral-light)'
        : 'var(--color-neutral-white)';

    return (
        <Container
            onClick={() => !disabled && onEditClick()}
            active={!disabled}
        >
            <FirstLineSection>
                <DescriptionSection>
                    <h4>{site.title}</h4>
                </DescriptionSection>
                {subscription?.type === 'PLUS' ? (
                    <PlusLabel disabled={disabled} />
                ) : (
                    <IconStatus active={!disabled} />
                )}
            </FirstLineSection>
            <SecondLineSection>
                <p>
                    {constants.nameSite}/{site.url}
                </p>
            </SecondLineSection>
            <ThirdLineSection>
                {disabled ? (
                    <Button
                        type="NEUTRAL"
                        size="SM"
                        uppercase
                        onClick={() => onEnableClick()}
                    >
                        {t('pages.sites.card.enable.buttonTitle')}
                    </Button>
                ) : (
                    <EditButton type="OUTLINE" size="SM" uppercase>
                        {t('pages.sites.card.edit.buttonTitle')}
                    </EditButton>
                )}
                <RightButtonsSection>
                    {subscription?.type === 'PLUS' && (
                        <MediumCircleContainer
                            as="button"
                            onClick={statisticsOnClickHandler}
                            $color={iconCircleContainerColor}
                            title={t('pages.sites.card.statistics.title')}
                        >
                            <ChartIcon color={iconColor} />
                        </MediumCircleContainer>
                    )}
                    <MediumCircleContainer
                        onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => settingsHandler(e)}
                        $color={iconCircleContainerColor}
                        title={t('pages.sites.card.settings.title')}
                    >
                        <EngineIcon color={iconColor} />
                    </MediumCircleContainer>
                    <MediumCircleContainer
                        onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => launchHandler(e)}
                        $color={iconCircleContainerColor}
                        title={t('pages.sites.card.launch.title')}
                    >
                        <LaunchIcon color={iconColor} />
                    </MediumCircleContainer>
                </RightButtonsSection>
            </ThirdLineSection>
        </Container>
    );
};

export default memo(CardSite);
