import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 32px;
    border-bottom: 1px solid var(--color-neutral-base);

    .account {
        cursor: pointer;
    }
`;

export const LogoSection = styled.div`
    display: flex;
    align-items: center;
`;

export const SitesSection = styled.div`
    margin-top: 1rem;
    margin-bottom: 7.5rem;
`;
