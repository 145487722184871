import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../components/Button';
import { MicroCircleContainer } from '../../../../../../components/CircleContainer/styles';
import PlusIcon from '../../../../../../icons/Plus';
import { EmptyIcon } from './icons/Empty';
import { Container } from './styles';

type Props = {
    onClick: () => void;
};

export const EmptyState = ({ onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <div className="image">
                <EmptyIcon />
            </div>
            <div className="text">
                <h3>{t('pages.editor.sections.empty.title')}</h3>
                <p>{t('pages.editor.sections.empty.description')}</p>
            </div>
            <div className="button">
                <Button type="WHITE" size="LG" onClick={onClick}>
                    {t('pages.editor.sections.actions.add.button.title')}
                    <MicroCircleContainer
                        $color="var(--color-brand-blue)"
                        className="icon"
                    >
                        <PlusIcon
                            width={10}
                            height={10}
                            color="var(--color-neutral-white)"
                        />
                    </MicroCircleContainer>
                </Button>
            </div>
        </Container>
    );
};
