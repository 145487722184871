import { Box } from '../../../../../../components/Layout';
import { Container, LogoContainer } from './styles';
type Props = {
    info: string;
    logoContainerColor: string;
    LogoIcon: React.ElementType;
};

const StorageIntegrationServiceInfo = ({
    info,
    logoContainerColor,
    LogoIcon,
}: Props) => {
    return (
        <Box borderBottom>
            <Container>
                <div>
                    <LogoContainer color={logoContainerColor}>
                        <LogoIcon />
                    </LogoContainer>
                </div>
                <div>
                    <p>{info}</p>
                </div>
            </Container>
        </Box>
    );
};

export default StorageIntegrationServiceInfo;
