const SortIcon = () => {
    return (
        <svg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.4">
                <path
                    d="M15 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4Z"
                    fill="#5E6272"
                />
                <path
                    d="M15 8H1C0.447715 8 0 7.55229 0 7C0 6.44771 0.447715 6 1 6H15C15.5523 6 16 6.44771 16 7C16 7.55229 15.5523 8 15 8Z"
                    fill="#5E6272"
                />
            </g>
        </svg>
    );
};

export default SortIcon;
