import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > .icon-section {
        cursor: pointer;
    }

    .icon-container {
        width: 64px;
        height: 64px;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 46px;
        padding-top: 2px;
        border-radius: 7px;

        &.value-1 {
            background-color: #f23c42;
        }
        &.value-2 {
            background-color: #f86a47;
        }
        &.value-3 {
            background-color: #ffa322;
        }
        &.value-4 {
            background-color: #becf36;
        }
        &.value-5 {
            background-color: #6fc031;
        }
        &.not-active {
            background-color: transparent;
        }

        @media (max-width: 320px) {
            width: 50px;
            height: 50px;
            font-size: 35px;
        }
    }
`;
