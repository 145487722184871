import { useCallback } from 'react';

import { TabsNavItem as TabsNavItemComponent } from './styles';

interface TabsNavItemProps {
    active: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

const TabsNavItem: React.FC<TabsNavItemProps> = ({
    active,
    onClick,
    children,
}) => {
    const onClickHandler = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();

            if (onClick) onClick();
        },
        [onClick]
    );

    return (
        <TabsNavItemComponent className={active ? 'active' : ''}>
            <a onClick={onClickHandler} href="#">
                {children}
            </a>
        </TabsNavItemComponent>
    );
};

export default TabsNavItem;
