import styled, { css } from 'styled-components';

import { Box } from '../../components/Layout';

export const Container = styled.div`
    .first-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .premium-banner {
        background-color: var(--color-lemon);
        border-radius: 100px;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 4px 16px;
        text-transform: uppercase;

        color: var(--color-neutral-darkness);
        font-size: 10px;
        font-family: Inter, sans-serif;
        font-weight: bold;
        line-height: 16px;

        cursor: pointer;
    }

    .tracking-cards {
        margin-top: 1rem;
        > div {
            margin-bottom: 0.5rem;
        }
        > div:last-child {
            margin-bottom: 0;
        }
    }
`;

type ToggleSwitchSectionProps = {
    isPlus?: boolean;
};

export const ToggleSwitchSection = styled.div<ToggleSwitchSectionProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${({ isPlus }) =>
            isPlus &&
            css`
                flex: 1;
            `}
    }

    label {
        margin-bottom: 1.5rem;
    }

    h6 {
        margin-right: 1rem;
    }
`;

export const FooterBox = styled(Box)`
    padding: 1.5rem 0rem;
`;

export const FooterButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
