import { useCallback } from 'react';
import { HexColorInput } from 'react-colorful';

import { Color, Container, Label } from './styles';

interface Props {
    label?: string;
    onClick?: () => void;
    onChange?: (color: string) => void;
    color: string;
    active?: boolean;
    onlyPreview?: boolean;
}

const InputColor = ({
    label,
    onClick,
    onChange,
    color,
    active,
    onlyPreview,
}: Props) => {
    const onClickHandler = useCallback(() => {
        if (onClick && active) {
            onClick();
        }
    }, [onClick, active]);
    const onChangeHandler = useCallback(
        (color: string) => {
            if (onChange) {
                onChange(color);
            }
        },
        [onChange]
    );

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Color
                role="button"
                color={color}
                onClick={onClickHandler}
                hasClick={active! && onlyPreview! && !!onClick}
            >
                {onlyPreview && color}
                {!onlyPreview && (
                    <HexColorInput
                        prefixed
                        color={color}
                        onChange={onChangeHandler}
                    />
                )}
            </Color>
        </Container>
    );
};

export default InputColor;
