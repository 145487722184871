type Props = {
    width?: number;
    height?: number;
    color: string;
};

const ChevronLeftIcon = ({ width = 17, height = 16, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9416 16L13.5 14.5455L6.95455 8L13.5 1.45455L11.9416 0L3.94156 8L11.9416 16Z"
                fill={color}
            />
        </svg>
    );
};

export default ChevronLeftIcon;
