const key = 'ref';

export const useSiteRef = () => {
    return {
        getRefAndClear: (): string | undefined => {
            try {
                return localStorage.getItem(key) || undefined;
            } catch (e) {
                return undefined;
            } finally {
                localStorage.removeItem(key);
            }
        },
        setRef: (ref: string | undefined | null) => {
            if (ref) {
                localStorage.setItem(key, ref);
            }
        },
    };
};
