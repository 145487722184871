import millify from 'millify';
import { MillifyOptions } from 'millify/dist/options';

import { externalTooltipHandler } from './tooltip';

export const getBarOptions = (
    locale: string,
    millifyOptions: Partial<MillifyOptions>
) => {
    const fontFamily = "'Inter', sans-serif";
    return {
        locale,
        aspectRatio: 1.59,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
                callbacks: {
                    title: (item: { label: string }[]) => {
                        return `${locale === 'en-US' ? 'Day' : 'Dia'} ${
                            item[0].label
                        }`;
                    },
                    beforeBody: () => 'Total: ',
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                    scaleBeginAtZero: true,
                    autoSkip: true,
                    maxTicksLimit: 10,
                    color: '#fff',
                    font: {
                        size: 12,
                        family: fontFamily,
                        weight: '400',
                    },
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 16,
                    crossAlign: 'center',
                },
            },
            y: {
                position: 'right',
                grid: {
                    display: false,
                },
                ticks: {
                    precision: 0,
                    color: '#fff',
                    font: {
                        size: 14,
                        family: fontFamily,
                        weight: '400',
                    },
                    callback: (
                        value: number,
                        index: number,
                        values: number[]
                    ) =>
                        values.length < 4 || index % 2 === 0
                            ? millify(value, millifyOptions)
                            : undefined,
                },
            },
        },
    };
};
