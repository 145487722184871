import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.span`
    color: var(--color-neutral-white);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
`;

type StyleType = {
    '--color-preview': string;
};

interface ColorProps {
    color: string;
    hasClick: boolean;
    style?: StyleType;
}

export const Color = styled.div.attrs<ColorProps>((props) => ({
    hasClick: props.hasClick,
    color: props.color,
    style: {
        '--color-preview': props.color,
    },
}))`
    width: 138px;
    border-radius: 8px;
    border: 1px solid var(--color-neutral-base);
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: var(--color-neutral-white);
    box-sizing: border-box;
    cursor: ${(props) => props.hasClick && 'pointer'};

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 4px;
        background: var(--color-preview);
        border: 1.2px solid var(--color-neutral-base);
        margin-right: 0.5rem;
    }

    input {
        width: 70px;
        height: 28px;
        justify-content: space-between;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
        text-transform: uppercase;
        color: var(--color-neutral-white);
        text-align: right;
        outline: none !important;
        margin-left: -8px;
    }
`;
