const Google = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#4285F4"
            d="M22.501 12.233c0-.863-.071-1.493-.226-2.147h-10.06v3.897h5.905c-.119.968-.762 2.427-2.19 3.407l-.02.13 3.18 2.415.22.021c2.024-1.831 3.191-4.526 3.191-7.723z"
        ></path>
        <path
            fill="#34A853"
            d="M12.215 22.5c2.893 0 5.321-.933 7.095-2.543l-3.381-2.567c-.905.618-2.12 1.05-3.714 1.05a6.438 6.438 0 01-6.096-4.363l-.125.01-3.307 2.508-.044.118c1.762 3.43 5.381 5.787 9.572 5.787z"
        ></path>
        <path
            fill="#FBBC05"
            d="M6.12 14.077A6.347 6.347 0 015.763 12c0-.723.13-1.423.345-2.077l-.006-.139-3.349-2.548-.11.05A10.339 10.339 0 001.502 12c0 1.692.417 3.29 1.143 4.713l3.476-2.636z"
        ></path>
        <path
            fill="#EB4335"
            d="M12.215 5.56c2.012 0 3.369.852 4.143 1.563L19.38 4.23c-1.857-1.692-4.273-2.73-7.166-2.73-4.19 0-7.81 2.357-9.572 5.787l3.465 2.636a6.465 6.465 0 016.107-4.363z"
        ></path>
    </svg>
);

const Facebook = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            cx="12"
            cy="12"
            r="10.5"
            fill="url(#paint0_linear_86_1588)"
        ></circle>
        <path
            fill="#fff"
            d="M15.91 15.211l.467-2.963h-2.918v-1.923c0-.81.407-1.602 1.714-1.602H16.5V6.2S15.296 6 14.145 6c-2.404 0-3.974 1.42-3.974 3.989v2.259H7.5v2.963h2.671v7.165a10.856 10.856 0 003.288 0V15.21h2.451z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_86_1588"
                x1="12"
                x2="12"
                y1="1.5"
                y2="22.438"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18ACFE"></stop>
                <stop offset="1" stopColor="#0163E0"></stop>
            </linearGradient>
        </defs>
    </svg>
);

export const CustomIcons = {
    Google,
    Facebook,
};
