import styled from 'styled-components';

import ArrowDownIconDisabled from './icons/ArrowDown/icon-disabled.svg';
import ArrowDownIcon from './icons/ArrowDown/icon.svg';
import { inputCss } from './styles';

export const Select = styled.select`
    ${inputCss}
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background: url(${ArrowDownIcon}) no-repeat right transparent;
    background-position-x: calc(100% - 1rem);

    &:disabled {
        background-color: var(--color-neutral-dark);
        background: url(${ArrowDownIconDisabled}) no-repeat right transparent;
        background-position-x: calc(100% - 1rem);
    }

    > option {
        background-color: var(--color-neutral-darkness);
        color: var(--color-neutral-white);
        padding: 0.5rem 1rem;
        outline: none;

        &:checked {
            background-color: var(--color-neutral-light);
        }
        &:not(:checked) {
            background-color: var(--color-neutral-darkness);
        }
    }
`;
