import { useTranslation } from 'react-i18next';

import * as Fonts from '../../../../../../components/Fonts';
import { useAppState } from '../../../../../../contexts/App';
import PlusIcon from '../../../../../../icons/Plus';
import {
    extraPredefinedTheme,
    preDefinedThemes,
    Theme,
} from '../../../../../../model/theme';
import { classNames } from '../../../../../../utils/styles';
import { ThemeCard, ThemeCardsSection } from './styles';

type Props = {
    activeTheme: Theme;
    customTheme: Theme;
    onSelectTheme: (theme: Theme) => void;
    hideBorderBottom: boolean;
};

export const PreDefinedColorSection = ({
    activeTheme,
    customTheme,
    onSelectTheme,
    hideBorderBottom,
}: Props) => {
    const { t } = useTranslation();
    const isCustomThemeActive = activeTheme.id === 'custom';

    const { appState } = useAppState();
    const isPROKeepo = appState.editingSiteSubscription.type === 'PLUS';

    return (
        <>
            <Fonts.Label center>
                {t('pages.editor.settings.form.predefinedColors.title')}
            </Fonts.Label>

            <ThemeCardsSection
                className={classNames({
                    'no-border': hideBorderBottom,
                })}
            >
                {preDefinedThemes.map((theme) => (
                    <ThemeCard
                        onClick={() => onSelectTheme(theme)}
                        $active={theme.id === activeTheme?.id}
                        $primary={theme.editorPrimary || theme.primary}
                        $secondary={theme.secondary}
                        key={theme.id}
                    >
                        Ab
                    </ThemeCard>
                ))}
                {isPROKeepo ? (
                    <ThemeCard
                        onClick={() => onSelectTheme(customTheme)}
                        $active={isCustomThemeActive}
                        $primary={customTheme.primary}
                        $secondary={customTheme.secondary}
                    >
                        <PlusIcon
                            width={20}
                            height={20}
                            color={customTheme.secondary}
                        />
                        {/* <PlusIcon color={customTheme.secondary} /> */}
                    </ThemeCard>
                ) : (
                    <ThemeCard
                        onClick={() => onSelectTheme(extraPredefinedTheme)}
                        $active={extraPredefinedTheme.id === activeTheme?.id}
                        $primary={extraPredefinedTheme.primary}
                        $secondary={extraPredefinedTheme.secondary}
                        key={extraPredefinedTheme.id}
                    >
                        Ab
                    </ThemeCard>
                )}
            </ThemeCardsSection>
        </>
    );
};
