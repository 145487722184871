type Props = {
    width: number;
    height: number;
    color: string;
};

const EyeIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.0659919 4.66249C1.17254 1.94491 3.45554 0.199997 6 0.199997C8.54447 0.199997 10.8275 1.94491 11.934 4.66249C12.022 4.87859 12.022 5.12141 11.934 5.3375C10.8275 8.05509 8.54447 9.8 6 9.8C3.45554 9.8 1.17254 8.05509 0.0659919 5.3375C-0.0219973 5.12141 -0.0219973 4.87859 0.0659919 4.66249ZM6 8.68979C8.05143 8.68979 9.93225 7.27205 10.8899 5C9.93225 2.72795 8.05143 1.3102 6 1.3102C3.94857 1.3102 2.06775 2.72795 1.11005 5C2.06775 7.27205 3.94857 8.68979 6 8.68979ZM6 7.18775C4.81274 7.18775 3.85027 6.20826 3.85027 5C3.85027 3.79173 4.81274 2.81224 6 2.81224C7.18726 2.81224 8.14973 3.79173 8.14973 5C8.14973 6.20826 7.18726 7.18775 6 7.18775ZM6 6.07755C6.58477 6.07755 7.05882 5.59511 7.05882 5C7.05882 4.40488 6.58477 3.92245 6 3.92245C5.41523 3.92245 4.94118 4.40488 4.94118 5C4.94118 5.59511 5.41523 6.07755 6 6.07755Z"
                fill={color}
            />
        </svg>
    );
};

export default EyeIcon;
