import styled from 'styled-components';

export const Container = styled.div`
    p {
        text-align: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

type LogoContainerProps = {
    color: string;
};

export const LogoContainer = styled.div<LogoContainerProps>`
    width: 72px;
    height: 72px;
    border-radius: 16px;
    background-color: ${(props) => props.color};
    margin-bottom: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
