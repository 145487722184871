export const SuccessIcon = () => {
    return (
        <svg
            width="166"
            height="160"
            viewBox="0 0 166 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4102 150.118C122.301 150.118 153.83 118.588 153.83 79.5588C153.83 40.5292 122.301 9 83.4102 9C44.5195 9 12.9902 40.5292 12.9902 79.5588C12.9902 118.588 44.5195 150.118 83.4102 150.118Z"
                fill="#3A3F50"
            />
            <path
                d="M124.99 43C129.409 43 132.99 46.5817 132.99 51V129.817C120.337 142.397 102.817 150 83.4902 150C64.302 150 46.7824 142.258 33.9902 129.817V51C33.9902 46.5817 37.572 43 41.9902 43H124.99Z"
                fill="white"
            />
            <path
                d="M66.5443 85.6862C69.7714 85.6862 72.3874 83.0702 72.3874 79.8431C72.3874 76.6161 69.7714 74 66.5443 74C63.3172 74 60.7012 76.6161 60.7012 79.8431C60.7012 83.0702 63.3172 85.6862 66.5443 85.6862Z"
                fill="#CED6E2"
            />
            <path
                d="M100.435 85.6862C103.662 85.6862 106.278 83.0702 106.278 79.8431C106.278 76.6161 103.662 74 100.435 74C97.2079 74 94.5918 76.6161 94.5918 79.8431C94.5918 83.0702 97.2079 85.6862 100.435 85.6862Z"
                fill="#CED6E2"
            />
            <path
                d="M92.0993 92.7978C91.4624 92.7978 90.8255 93.0102 90.4008 93.6477C88.7024 95.9849 86.1548 97.2597 83.1826 97.2597C80.4227 97.2597 77.6628 95.9849 76.1767 93.6477C75.5398 92.5853 74.266 92.3728 73.2045 93.2227C72.143 93.8601 71.9307 95.135 72.7799 96.1974C75.1152 99.5969 79.1489 101.722 83.3949 101.722C87.6409 101.722 91.4624 99.5969 94.01 96.1974C94.6469 95.135 94.4346 93.8601 93.5854 93.2227C92.9485 92.7978 92.5239 92.7978 92.0993 92.7978Z"
                fill="#CED6E2"
            />
            <path
                d="M100.024 145.785C99.6723 147.279 99.1448 148.862 98.5295 150.18C96.8591 153.433 94.2218 155.983 90.969 157.653C87.6284 159.323 83.6723 160.026 79.7163 159.147C70.3976 157.213 64.4196 148.07 66.3536 138.752C68.2877 129.433 77.3426 123.367 86.6613 125.389C90.002 126.092 92.9031 127.763 95.3646 130.048C99.4965 134.18 101.255 140.158 100.024 145.785Z"
                fill="#4B10FF"
            />
            <path
                d="M146.49 146C149.528 146 151.99 143.538 151.99 140.5C151.99 137.462 149.528 135 146.49 135C143.453 135 140.99 137.462 140.99 140.5C140.99 143.538 143.453 146 146.49 146Z"
                fill="#3A3F50"
            />
            <path
                d="M156.79 127.6C158.337 127.6 159.59 126.346 159.59 124.8C159.59 123.254 158.337 122 156.79 122C155.244 122 153.99 123.254 153.99 124.8C153.99 126.346 155.244 127.6 156.79 127.6Z"
                fill="#3A3F50"
            />
            <path
                d="M5.49023 128C7.97551 128 9.99023 125.985 9.99023 123.5C9.99023 121.015 7.97551 119 5.49023 119C3.00495 119 0.990234 121.015 0.990234 123.5C0.990234 125.985 3.00495 128 5.49023 128Z"
                fill="#323643"
            />
            <path
                d="M155.19 24.4C158.062 24.4 160.39 22.0719 160.39 19.2C160.39 16.3281 158.062 14 155.19 14C152.318 14 149.99 16.3281 149.99 19.2C149.99 22.0719 152.318 24.4 155.19 24.4Z"
                fill="#323643"
            />
            <path
                d="M80.8439 148.579C80.3561 148.579 79.8683 148.384 79.478 147.994L75.5756 144.094C74.7951 143.313 74.7951 142.046 75.5756 141.266C76.3561 140.486 77.6244 140.486 78.4049 141.266L80.8439 143.703L87.9658 136.585C88.7463 135.805 90.0146 135.805 90.7951 136.585C91.5756 137.365 91.5756 138.633 90.7951 139.413L82.3073 147.994C81.9171 148.384 81.3317 148.579 80.8439 148.579Z"
                fill="white"
            />
        </svg>
    );
};
