import { TabsBody, TabsContent, TabsHeader, TabsPane } from './styles';
import TabsNavItem from './TabsNavItem';

export interface TabData {
    title: string;
    content?: React.ReactNode;
}

interface TabsProps {
    children: React.ReactNode;
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
    return <TabsBody>{children}</TabsBody>;
};

export default Tabs;

export { TabsContent, TabsHeader, TabsNavItem, TabsPane };
