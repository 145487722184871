import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../components/Button';
import * as Fonts from '../../../../components/Fonts';
import Input from '../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import constants from '../../../../constants';
import { useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import useCheckUrl from '../../../../hooks/CheckUrl';
import { api } from '../../../../services/api';
import { Container, InputMessageSection, PublishBox } from './styles';

const PublishPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();
    const { site, setSite } = useSite();

    const { url, onChangeUrl, urlReady, loadingUrl, urlNotAvailable } =
        useCheckUrl(appState?.editingSiteId as string, site?.url || '');

    const [isPublishing, setPublishing] = useState(false);

    const publishHandler = async () => {
        setPublishing(true);

        const copy = { ...site, url };
        const firstPublish = !appState?.published;

        try {
            await api.publish(appState.editingSiteId as string, copy);
            setAppState({ ...appState, published: true });
            setSite(copy);
            navigate(`/editor/publish/success?first=${firstPublish}`);
        } catch (e) {
            setPublishing(false);
        }
    };

    return (
        <>
            {isPublishing && <LoadingModal />}
            <Container>
                <Header title={t('pages.editor.publish.header.title')} />
                <Box>
                    <Form>
                        <FormGroup>
                            <Input
                                type="text"
                                id="url"
                                ignoreLengthInfo
                                disabled={appState?.published}
                                autoCapitalize="none"
                                value={url}
                                maxLength={30}
                                autoFocus={!url}
                                loading={loadingUrl}
                                prefix={`${constants.nameSite}/`}
                                onChange={(e) =>
                                    onChangeUrl(
                                        e.target.value.replace(/\s/g, '')
                                    )
                                }
                                label={t('pages.editor.publish.form.url')}
                            />
                        </FormGroup>
                        <InputMessageSection
                            type={urlReady ? 'SUCCESS' : 'DANGER'}
                        >
                            {url && (
                                <Fonts.Label>
                                    {urlNotAvailable &&
                                        t(
                                            'pages.editor.publish.form.urlNotAvailable.message'
                                        )}
                                    {urlReady &&
                                        !appState?.published &&
                                        t(
                                            'pages.editor.publish.form.urlAvailable.message'
                                        )}
                                </Fonts.Label>
                            )}
                        </InputMessageSection>
                    </Form>
                </Box>
                <PublishBox>
                    <Button
                        disabled={!urlReady}
                        type="INFO"
                        size="LG"
                        onClick={() => urlReady && publishHandler()}
                    >
                        {isPublishing
                            ? t(
                                  'pages.editor.publish.form.publishing.button.title'
                              )
                            : t(
                                  'pages.editor.publish.form.publish.button.title'
                              )}
                    </Button>
                </PublishBox>
            </Container>
        </>
    );
};

export default PublishPage;
