type Props = {
    color: string;
};

const BallsIcon = ({ color }: Props) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.55556 0C5.51923 0 7.11111 1.59188 7.11111 3.55556C7.11111 5.51923 5.51923 7.11111 3.55556 7.11111C1.59188 7.11111 0 5.51923 0 3.55556C0 1.59188 1.59188 0 3.55556 0ZM3.55556 1.77778C2.57372 1.77778 1.77778 2.57372 1.77778 3.55556C1.77778 4.5374 2.57372 5.33333 3.55556 5.33333C4.5374 5.33333 5.33333 4.5374 5.33333 3.55556C5.33333 2.57372 4.5374 1.77778 3.55556 1.77778ZM12.4444 0C14.4081 0 16 1.59188 16 3.55556C16 5.51923 14.4081 7.11111 12.4444 7.11111C10.4808 7.11111 8.88889 5.51923 8.88889 3.55556C8.88889 1.59188 10.4808 0 12.4444 0ZM12.4444 1.77778C11.4626 1.77778 10.6666 2.57372 10.6666 3.55556C10.6666 4.5374 11.4626 5.33333 12.4444 5.33333C13.4263 5.33333 14.2222 4.5374 14.2222 3.55556C14.2222 2.57372 13.4263 1.77778 12.4444 1.77778ZM7.11111 12.4444C7.11111 10.4808 5.51923 8.88889 3.55556 8.88889C1.59188 8.88889 0 10.4808 0 12.4444C0 14.4081 1.59188 16 3.55556 16C5.51923 16 7.11111 14.4081 7.11111 12.4444ZM1.77778 12.4444C1.77778 11.4626 2.57372 10.6667 3.55556 10.6667C4.5374 10.6667 5.33333 11.4626 5.33333 12.4444C5.33333 13.4263 4.5374 14.2222 3.55556 14.2222C2.57372 14.2222 1.77778 13.4263 1.77778 12.4444ZM12.4444 8.88889C14.4081 8.88889 16 10.4808 16 12.4444C16 14.4081 14.4081 16 12.4444 16C10.4808 16 8.88889 14.4081 8.88889 12.4444C8.88889 10.4808 10.4808 8.88889 12.4444 8.88889ZM12.4444 10.6667C11.4626 10.6667 10.6666 11.4626 10.6666 12.4444C10.6666 13.4263 11.4626 14.2222 12.4444 14.2222C13.4263 14.2222 14.2222 13.4263 14.2222 12.4444C14.2222 11.4626 13.4263 10.6667 12.4444 10.6667Z"
                fill={color}
            />
        </svg>
    );
};

export default BallsIcon;
