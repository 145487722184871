import styled from 'styled-components';

export const Container = styled.div``;

export const CircleAddImage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 0.1rem dashed var(--color-neutral-base);
    cursor: pointer;
`;

export const UploadImageSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const UploadImagemButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h5 {
        display: inline-block;
        margin-bottom: 1rem;
    }

    & > div {
        display: flex;

        & > div:last-child {
            margin-left: 1rem;
        }
    }
`;

type LogoPreviewProps = {
    url: string;
};

export const LogoPreviewSection = styled.div<LogoPreviewProps>`
    border-radius: 50%;
    width: 96px;
    height: 96px;
    background-image: url(${(props) => props.url});
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border: 0.1rem var(--color-neutral-darkness);
`;
