type Props = {
    color: string;
    width?: number;
    height?: number;
};

export const ImageIcon = ({ color, height = 24, width = 24 }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C10.0478 4 8.24816 4.161 6.80371 4.35177C5.50372 4.52346 4.52346 5.50372 4.35177 6.80371C4.16101 8.24816 4 10.0478 4 12C4 13.9522 4.161 15.7518 4.35177 17.1963C4.52346 18.4963 5.50372 19.4765 6.80371 19.6482C8.24816 19.839 10.0478 20 12 20C13.9522 20 15.7518 19.839 17.1963 19.6482C18.4963 19.4765 19.4765 18.4963 19.6482 17.1963C19.839 15.7518 20 13.9522 20 12C20 10.0478 19.839 8.24816 19.6482 6.80371C19.4765 5.50372 18.4963 4.52346 17.1963 4.35177C15.7518 4.16101 13.9522 4 12 4ZM6.54184 2.36899C4.34504 2.65912 2.65912 4.34504 2.36899 6.54184C2.16953 8.05208 2 9.94127 2 12C2 14.0587 2.16953 15.9479 2.36899 17.4582C2.65912 19.655 4.34504 21.3409 6.54184 21.631C8.05208 21.8305 9.94127 22 12 22C14.0587 22 15.9479 21.8305 17.4582 21.631C19.655 21.3409 21.3409 19.655 21.631 17.4582C21.8305 15.9479 22 14.0587 22 12C22 9.94127 21.8305 8.05208 21.631 6.54184C21.3409 4.34504 19.655 2.65912 17.4582 2.36899C15.9479 2.16953 14.0587 2 12 2C9.94127 2 8.05208 2.16953 6.54184 2.36899Z"
                fill={color}
            />
            <path
                d="M8.99866 11C10.1032 11 10.9987 10.1046 10.9987 9C10.9987 7.89543 10.1032 7 8.99866 7C7.89409 7 6.99866 7.89543 6.99866 9C6.99866 10.1046 7.89409 11 8.99866 11Z"
                fill={color}
            />
            <path
                d="M18.12 12.7073L19.9143 14.5016C19.8524 15.4378 19.762 16.3065 19.6623 17.0781L16.7058 14.1215C16.3152 13.731 15.6821 13.731 15.2915 14.1215L13.12 16.2931C11.9484 17.4647 10.0489 17.4647 8.87733 16.2931L8.70576 16.1215C8.31523 15.731 7.68207 15.731 7.29155 16.1215L4.88248 18.5306C4.60341 18.1522 4.41668 17.6981 4.35041 17.1963C4.31427 16.9227 4.2792 16.6363 4.24609 16.3385L5.87733 14.7073C7.04891 13.5357 8.9484 13.5357 10.12 14.7073L10.2915 14.8789C10.6821 15.2694 11.3152 15.2694 11.7058 14.8789L13.8773 12.7073C15.0489 11.5357 16.9484 11.5357 18.12 12.7073Z"
                fill={color}
            />
        </svg>
    );
};
