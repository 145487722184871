import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../../components/Form/styles';
import Header from '../../../../../components/Header';
import { Box } from '../../../../../components/Layout';
import SaveBar from '../../../../../components/SaveBar';
import { SectionHelp } from '../../../../../components/SectionHelp';
import { UploadImageBox } from '../../../../../components/UploadImageBox';
import { MediaSection } from '../../../../../model/site';
import { useSection } from '../../../hooks/Section';
import { Container } from './styles';

export const ImageMediaPage = () => {
    const { t } = useTranslation();
    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<MediaSection>('media');

    const saveHandler = useCallback(() => {
        saveSection({ ...section, subType: 'image' });
    }, [section, saveSection]);

    return (
        <Container>
            <Header
                title={t('pages.editor.media.image.header.title')}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <SectionHelp message={t('pages.editor.media.image.help.message')} />
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            type="text"
                            id="title"
                            value={section.pageTitle}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                            label={t('pages.editor.media.image.form.title')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <UploadImageBox
                            required
                            image={section.image}
                            onUpload={(image) =>
                                setSection({
                                    ...section,
                                    image,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    disabled={!section.image}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
        </Container>
    );
};
