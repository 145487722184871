import { useTranslation } from 'react-i18next';

import ConfirmModal from '../ConfirmModal';

type Props = {
    onConfirmExit: () => void;
    onCloseModal: () => void;
    title?: string;
    confirmButtonTitle?: string;
    cancelTitle?: string;
};

export const UnsavedChangesConfirmModal = ({
    onConfirmExit,
    onCloseModal,
    title,
    confirmButtonTitle,
    cancelTitle,
}: Props) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            hideExitButton
            type="DANGER"
            title={title || t('actions.unsavedChanges.modal.title')}
            confirmButtonTitle={
                confirmButtonTitle ||
                t('actions.unsavedChanges.modal.confirmButton')
            }
            cancelTitle={
                cancelTitle || t('actions.unsavedChanges.modal.cancelButton')
            }
            onExit={onCloseModal}
            onConfirm={onConfirmExit}
            onCancel={onCloseModal}
        />
    );
};
