export const ReloadIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.80078 3.53528C2.80078 3.12909 3.12771 2.7998 3.53099 2.7998C3.93427 2.7998 4.2612 3.12909 4.2612 3.53528V5.0844C6.50009 2.7764 10.0044 2.11734 12.9622 3.57036C16.7069 5.40997 18.262 9.95887 16.4356 13.7306C14.6092 17.5024 10.0928 19.0687 6.34809 17.2291C4.85251 16.4944 3.65522 15.2994 2.91428 13.812C2.73342 13.4489 2.87901 13.0069 3.23947 12.8248C3.59992 12.6426 4.03874 12.7893 4.2196 13.1523C4.81721 14.352 5.78116 15.314 6.98829 15.907C10.0081 17.3905 13.6501 16.1274 15.123 13.0858C16.5958 10.0442 15.3418 6.37592 12.322 4.89244C9.67407 3.59165 6.51327 4.39786 4.78746 6.72234H7.42543C7.82872 6.72234 8.15564 7.05163 8.15564 7.45782C8.15564 7.86401 7.82872 8.19329 7.42543 8.19329H3.53099C3.12771 8.19329 2.80078 7.86401 2.80078 7.45782V7.40258C2.80062 7.3908 2.80078 3.53528 2.80078 3.53528Z"
                fill="white"
            />
        </svg>
    );
};
