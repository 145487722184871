import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;

    h2 {
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 3rem;
        color: var(--color-neutral-white);
        margin: 0;
    }

    h3 {
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 2rem;
        color: var(--color-neutral-white);
        display: inline-flex;
        align-items: center;

        svg {
            margin-left: 8px;
        }
    }
`;
