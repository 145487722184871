import React, { MouseEvent } from 'react';
import { Label } from './styles';
import { MouseEventHandler } from 'react';

type Props = {
    value: boolean;
    setValue: (value: boolean) => void;
    disabled?: boolean;
    onClick?: () => void;
};

const ToggleSwitch = ({
    value,
    setValue,
    disabled = false,
    onClick,
}: Props) => {
    const handleChange = () => {
        if (!disabled) {
            setValue(!value);
        }
    };

    const handleClick = (e: MouseEvent<HTMLInputElement>) => {
        // e.preventDefault();
        if (disabled && onClick) {
            onClick();
        } else {
            handleChange();
        }
    };
    return (
        <Label onClick={onClick}>
            <input
                type="checkbox"
                checked={value}
                onChange={handleChange}
                disabled={disabled}
            />
            <span></span>
        </Label>
    );
};

export default ToggleSwitch;
