import { Container } from './styles';

type Props = {
    active: boolean;
};

export const RadioInput = ({ active }: Props) => {
    return (
        <Container $active={active} className="radio-input-container">
            <div className="radio-input-content"></div>
        </Container>
    );
};
