import { Box } from '../Layout';
import { Container } from './styles';

type Props = {
    message: string;
    bordeBottom?: boolean;
};

export const SectionHelp = ({ message, bordeBottom = true }: Props) => {
    return (
        <Box borderBottom={bordeBottom}>
            <Container>
                <p>{message}</p>
            </Container>
        </Box>
    );
};
