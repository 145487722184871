import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 1rem;

    > :first-child {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }

    :last-child {
        margin-bottom: 0rem;
    }

    .selection {
        border: 2px solid var(--color-neutral-light);
        border-radius: 8px;
        padding: 26px 28px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;
        }

        > div:first-child {
            > div:first-child {
                min-width: 24px;
            }
        }

        h6 {
            margin-left: 1rem;
        }

        &.active {
            border-color: var(--color-lemon);

            .radio-input-container {
                border-color: var(--color-lemon);
            }
            .radio-input-content {
                background-color: var(--color-lemon);
            }

            .off-badge {
                background-color: var(--color-lemon);
                color: var(--color-neutral-darkness);
            }
        }
    }
`;
