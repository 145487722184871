import { preDefinedThemes, Theme } from './theme';

export interface Site {
    title: string;
    category: string;
    url?: string;
    sections: Section[];
    theme: Theme;
    layout: {
        align: 'LEFT' | 'CENTER';
    };
    hideSignature?: boolean;
}

export interface Section {
    id: string;
    type: string;
    active: boolean;
    subType?: string;
}

export interface WelcomeSection extends Section {
    featuredTitle?: string;
    supportingText?: string;
    buttonTitle?: string;
    buttonLink?: string;
    logo?: string;
    exibitionName?: string;
}

export interface AboutSection extends Section {
    pageTitle?: string;
    image?: string;
    featuredText?: string;
    completeDescription?: string;
}

export interface ItemSectionCatalog {
    id: string;
    title: string;
    image: string;
    description: string;
    linkTitle: string;
    link: string;
}

export interface CatalogSection extends Section {
    pageTitle: string;
    items: ItemSectionCatalog[];
}

export interface ItemSectionLinks {
    id: string;
    title: string;
    link: string;
    thumbnailType: 'IMAGE' | 'EMOJI';
    image?: string;
    emoji?: string;
}

export interface LinksSection extends Section {
    pageTitle: string;
    items: ItemSectionLinks[];
}

export interface SocialSection extends Section {
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    linkedin?: string;
    whatsapp?: string;
    behance?: string;
    dribbble?: string;
    medium?: string;
    twitch?: string;
    tiktok?: string;
    vimeo?: string;
}

export interface MediaSection extends Section {
    subType: string;
    link?: string;
    pageTitle?: string;
    image?: string;
}

export interface FormSection extends Section {
    pageTitle: string;
    fields: {
        name: FormField;
        email: FormField;
        phone: FormField;
        message: FormField;
    };
    buttonText: string;
    successRedirectLink?: string;
    successMessage?: string;
    storage: Storage;
}

export interface Storage {
    email: {
        active: boolean;
        value?: string;
    };
    mailChimp: MailChimpStorage;
    googleDrive: GoogleDriveStorage;
}

export interface MailChimpStorage {
    active: boolean;
    connectionId?: string;
    audienceList?: {
        id: string;
        name: string;
    };
}

export interface GoogleDriveStorage {
    active: boolean;
    connectionId?: string;
    spreadsheet?: {
        id: string;
        name: string;
    };
}

export interface FormField {
    active: boolean;
}

export const createNewSite = (): Site => {
    return {
        title: '',
        category: 'author',
        sections: [],
        theme: preDefinedThemes[0],
        layout: {
            align: 'LEFT',
        },
    };
};
