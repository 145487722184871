import styled from 'styled-components';

import Modal from '../Modal';
import {
    Body as ModalBody,
    Container as ModalContainer,
} from '../Modal/styles';

export const CropModal = styled(Modal)`
    ${ModalBody} {
        padding-top: 16px;
        width: 100%;
    }
    ${ModalContainer} {
        display: unset;
    }
`;

type CropContainerProps = {
    $aspect: number;
};

export const CropContainer = styled.div<CropContainerProps>`
    position: relative;
    padding-bottom: ${(props) => (props.$aspect === 4 / 3 ? '75%' : '100%')};
`;

export const ControlsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;

    > div:first-child {
        margin-left: 10px;
    }
`;
