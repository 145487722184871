import { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import { SectionHelp } from '../../../../components/SectionHelp';
import { useQueryUrl } from '../../../../hooks/QueryUrl';
import { keepoInternalAnalyticsService } from '../../../../services/analytics';
import { api } from '../../../../services/api';
import { ItemSelection } from '../../components/ItemSelection';
import { PaymentInfo } from '../../components/PaymentInfo';
import { Container } from './styles';

type ItemSelection = {
    id: string;
    title: string;
    active: boolean;
};

export const CREATE_NEW_KEEPO = 'CREATE_NEW_KEEPO';

export const SiteSelection = () => {
    const { t } = useTranslation();
    const query = useQueryUrl();

    const selectedSite = query.get('site');

    const [isLoading, setLoading] = useState(true);
    const [items, setItems] = useState<ItemSelection[]>([]);

    const canContinue = useMemo(
        () => items.some((item) => item.active),
        [items]
    );

    const plan = useMemo(
        () => (query.get('plan') as 'ANNUALLY' | 'MONTHLY') || 'ANNUALLY',
        [query]
    );

    const selectHandler = (item: ItemSelection) => {
        const index = items.findIndex(
            (currentItem) => currentItem.id === item.id
        );
        const copyItems = [...items];
        copyItems.forEach((item) => (item.active = false));
        copyItems[index] = { ...copyItems[index], active: true };
        setItems(copyItems);
    };

    const callCheckoutHandler = useCallback(
        async (siteId?: string) => {
            const normalizedSiteId =
                siteId === CREATE_NEW_KEEPO ? undefined : siteId;

            const [url, user] = await Promise.all([
                api.redirectCheckout({
                    plan,
                    siteId: normalizedSiteId,
                    currentPage: window.location.href.replace(
                        'selection',
                        'features'
                    ),
                }),
                api.getUser(),
            ]);

            keepoInternalAnalyticsService.notifyCheckoutEvent(user);
            window.location = url;
        },
        [plan]
    );

    const continueHandler = useCallback(async () => {
        if (!canContinue) {
            return;
        }
        setLoading(true);

        const item = items.find(({ active }) => active);
        const siteId = item?.id;

        await callCheckoutHandler(siteId);
    }, [canContinue, items, callCheckoutHandler]);

    const loadSites = useCallback(async () => {
        const sites = await api.getSites();

        const items = sites
            .filter(
                (site) =>
                    (site.subscription?.type !== 'PLUS' ||
                        site.subscription?.canceled) &&
                    !!site.site
            )
            .map((site) => ({
                id: site.siteId,
                title: site.site.title,
                active: site.siteId === selectedSite,
            }));

        if (!items.length) {
            await callCheckoutHandler();
            return;
        }

        items.unshift({
            id: CREATE_NEW_KEEPO,
            active: false,
            title: t('pages.subscription.siteSelection.createNewKeepoItem'),
        });

        setItems(items);
        setLoading(false);
    }, [t, selectedSite, callCheckoutHandler]);

    useEffect(() => {
        loadSites();
    }, [loadSites]);

    return isLoading ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.subscription.header.title')} />
            <SectionHelp
                message={t('pages.subscription.siteSelection.help')}
                bordeBottom={false}
            />
            <div className="selection-box">
                {items.map((item, index) => (
                    <ItemSelection
                        tabIndex={index + 1}
                        key={item.id}
                        active={item.active}
                        title={item.title}
                        onClick={() => selectHandler(item)}
                    />
                ))}
            </div>
            <Box>
                <Button
                    type="WHITE"
                    size="LG"
                    onClick={continueHandler}
                    tabIndex={items.length + 1}
                    disabled={!canContinue}
                >
                    {t('pages.subscription.continueButton')}
                </Button>
            </Box>
            <PaymentInfo />
        </Container>
    );
};
