import { useState } from 'react';
import { Area } from 'react-easy-crop/types';

import constants from '../../constants';
import { api } from '../../services/api';
import { ImageCropModal } from '../ImageCropModal';
import { LoadingModal } from '../LoadingModal';

export type CropProps = {
    size: {
        width: number;
        height: number;
    };
    slice: {
        aspect: number;
    };
    cropShape: 'round' | 'rect';
};

type Props = {
    accept: string;
    siteId: string;
    children: React.ReactNode;
    crop?: CropProps;
    onUpload: (value: string) => void;
    disabled?: boolean;
};

type CropState = {
    file: File;
    aspect: number;
    cropShape: 'round' | 'rect';
    resolve: (area?: Area) => void;
};

export const UploadImageInput = ({
    accept,
    siteId,
    children,
    crop,
    onUpload,
    disabled,
}: Props) => {
    const [isUploading, setUploading] = useState(false);
    const [cropState, setCropState] = useState<CropState | undefined>();

    const onChangeHandler = async (files: FileList) => {
        if (files && files.length) {
            const file = files[0];
            const shouldCrop = file.type !== 'image/gif' && !!crop;

            try {
                const area = await (shouldCrop
                    ? new Promise((resolve) => {
                          setCropState({
                              file,
                              resolve,
                              aspect: crop!.slice.aspect,
                              cropShape: crop!.cropShape,
                          });
                      })
                    : Promise.resolve());

                if (shouldCrop && !area) {
                    return;
                }

                setUploading(true);

                const { url, id } = await api.authorizeUpload(
                    siteId,
                    file.type
                );

                let key = id;

                await api.upload(url, file);

                if (area && shouldCrop) {
                    key = (
                        await api.cropImage(
                            siteId,
                            id,
                            area as Area,
                            crop!.size
                        )
                    ).key;
                }

                onUpload(`${constants.staticBucket}/${key}`);
            } finally {
                setUploading(false);
            }
        }
    };

    const resetInputValue = (el: HTMLInputElement) => (el.value = '');

    return disabled ? (
        <>{children}</>
    ) : (
        <>
            <label htmlFor="upload">
                {isUploading ? (
                    <LoadingModal />
                ) : (
                    <input
                        type="file"
                        id="upload"
                        name="upload"
                        style={{ display: 'none' }}
                        accept={accept}
                        onChange={(e) =>
                            onChangeHandler(e.target.files as FileList)
                        }
                        onClick={(e) =>
                            resetInputValue(e.target as HTMLInputElement)
                        }
                    />
                )}
                {children}
            </label>
            {cropState && (
                // @ts-ignore
                <ImageCropModal
                    file={cropState.file}
                    onSave={(area?: Area) => {
                        cropState.resolve(area);
                        setCropState(undefined);
                    }}
                    aspect={cropState.aspect}
                    cropShape={cropState.cropShape}
                />
            )}
        </>
    );
};
