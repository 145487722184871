import { Container, ContentSection, OutsiteComponent } from './styles';

type Props = {
    children?: React.ReactNode;
    outsiteComponent?: React.ReactNode;
    hideLine?: boolean;
    hideBox?: boolean;
};

const FixedFooterBar = ({
    children,
    hideLine,
    hideBox,
    outsiteComponent,
}: Props) => {
    return (
        <Container hideLine={hideLine} hideBox={hideBox}>
            {!!outsiteComponent && (
                <OutsiteComponent>{outsiteComponent}</OutsiteComponent>
            )}
            <ContentSection hideLine={hideLine}>{children}</ContentSection>
        </Container>
    );
};

export default FixedFooterBar;
