import Spinner from '../../Spinner';
import * as Styles from './styles';

type Props = {
    text: string;
    htmlFor: string;
    maxLenght?: number;
    usedLenght?: number;
    required?: boolean;
    ignoreLengthInfo?: boolean;
    loading?: boolean;
    infoMessage?: string;
};

const Label = ({
    text,
    htmlFor,
    maxLenght,
    usedLenght,
    required,
    ignoreLengthInfo,
    loading,
    infoMessage,
}: Props) => {
    return (
        <Styles.Label htmlFor={htmlFor}>
            <Styles.Container>
                <Styles.InfoSection>
                    {text}
                    {required && '*'}
                </Styles.InfoSection>
                <Styles.InfoSection>
                    {!loading && (
                        <Styles.InfoSectionMessage>
                            {infoMessage}
                        </Styles.InfoSectionMessage>
                    )}
                    {loading ? (
                        <Spinner />
                    ) : (
                        maxLenght &&
                        !ignoreLengthInfo &&
                        `${usedLenght || 0}/${maxLenght}`
                    )}
                </Styles.InfoSection>
            </Styles.Container>
        </Styles.Label>
    );
};

export default Label;
