import { CircleSpinner } from '../CircleSpinner';
import { Background } from '../Modal/styles';
import { Container } from './styles';

export const LoadingModal = () => {
    return (
        <Background>
            <Container>
                <CircleSpinner size="57px" />
            </Container>
        </Background>
    );
};
