type Props = {
    color: string;
};
export const ButtonSectionIcon = ({ color }: Props) => {
    return (
        <svg
            width="24"
            height="17"
            viewBox="0 0 24 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H16C20.4183 0.5 24 4.08172 24 8.5C24 12.9183 20.4183 16.5 16 16.5H8C3.58172 16.5 0 12.9183 0 8.5ZM2 8.5C2 5.18629 4.68629 2.5 8 2.5H16C19.3137 2.5 22 5.18629 22 8.5C22 11.8137 19.3137 14.5 16 14.5H8C4.68629 14.5 2 11.8137 2 8.5ZM9 7.5C8.44771 7.5 8 7.94772 8 8.5C8 9.05229 8.44771 9.5 9 9.5H15C15.5523 9.5 16 9.05229 16 8.5C16 7.94772 15.5523 7.5 15 7.5H9Z"
                fill={color}
            />
        </svg>
    );
};
