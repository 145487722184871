import { useTranslation } from 'react-i18next';

import { Select } from '../../../../components/Form/Input/select';

const categories = [
    'author',
    'artist',
    'designer',
    'entrepreneur',
    'photographer',
    'influencer',
    'infoproductor',
    'education',
    'health',
    'food',
    'social_projects',
    'ecommerce',
    'marketing',
    'other',
];

type Props = {
    id: string;
    value?: string;
    onChange: (value: string) => void;
};

const CategoriesInputSelect = ({ id, value, onChange }: Props) => {
    const { t } = useTranslation();

    return (
        <Select
            id={id}
            name={id}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onChange(e.target.value)
            }
        >
            {categories.map((item) => (
                <option key={item} value={item}>
                    {t(`site.categories.${item}`)}
                </option>
            ))}
        </Select>
    );
};

export default CategoriesInputSelect;
