import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/Button';
import ConfirmModal from '../../components/ConfirmModal';
import { TextArea } from '../../components/Form/Input/textArea';
import Label from '../../components/Form/Label';
import { FormGroup } from '../../components/Form/styles';
import Header from '../../components/Header';
import { Box } from '../../components/Layout';
import { SectionHelp } from '../../components/SectionHelp';
import { api } from '../../services/api';
import { Rating } from './components/Rating';
import { Container } from './styles';

export const FeedbackPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [value, setValue] = useState(5);

    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const submitHandler = async () => {
        setSubmitting(true);
        try {
            await api.submitFeedback(value, message);
        } finally {
            setSubmitting(false);
            setSubmitted(true);
        }
    };

    return (
        <Container>
            <Header title={t('pages.feedback.header.title')} />
            <SectionHelp message={t('pages.feedback.help')} />
            <Box className="form">
                <Rating value={value} onSelect={setValue} />
                <FormGroup>
                    <TextArea
                        id="message"
                        name="message"
                        autoComplete="off"
                        rows={5}
                        maxLength={1000}
                        value={message}
                        //@ts-ignore
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Label
                        htmlFor="message"
                        text={t('pages.feedback.form.message')}
                        maxLenght={1000}
                        usedLenght={message.length}
                    />
                </FormGroup>
            </Box>
            <Box>
                <Button
                    size="LG"
                    type="INFO"
                    onClick={submitHandler}
                    disabled={isSubmitting}
                >
                    {t('pages.feedback.form.save')}
                </Button>
            </Box>
            {isSubmitted && (
                <ConfirmModal
                    type="INFO"
                    title={t('pages.feedback.successModal.message')}
                    confirmButtonTitle={t(
                        'pages.feedback.successModal.buttonTitle'
                    )}
                    onConfirm={() => navigate(-1)}
                    hideExitButton
                />
            )}
        </Container>
    );
};
