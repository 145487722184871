import styled from 'styled-components';

export const Container = styled.div`
    .user-info {
        display: flex;
        justify-content: space-between;

        > div:first-child {
            p {
                margin-top: 0.5rem;
                color: var(--color-neutral-150);
            }
        }

        .lang {
            cursor: pointer;

            > div {
                display: flex;
                align-items: center;
                > svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: column;

        > .action {
            cursor: pointer;
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            h6 {
                margin-left: 1rem;
            }

            :last-child {
                margin-bottom: 0;
            }
        }
    }

    .logout-button {
        border-color: var(--color-neutral-white);
    }

    .legal {
        color: var(--color-neutral-150);
        max-width: 180px;
        a {
            color: var(--color-neutral-150);
            cursor: pointer;
        }
    }

    .subscriptions {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: var(--color-neutral-dark);
        border-radius: 8px;
        align-items: center;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
        }

        > div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
                border-radius: 24px;
                padding: 0 0.5rem;
                background-color: var(--color-lemon);

                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 16px;

                text-align: center;
                justify-content: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                color: var(--color-neutral-darkness);

                margin-left: 1rem;
            }
        }
    }
`;
