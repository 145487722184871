import styled from 'styled-components';

export const Form = styled.form``;

export const FormGroup = styled.div`
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
`;
