import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    width: min(480px, 100%);
    margin: 0 auto;

    .new-site-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: var(--color-neutral-dark);
        height: 56px;

        > .button {
            border: 4px solid #181a20;
            margin-top: -24px;
        }
    }
`;
