import { useTranslation } from 'react-i18next';

import { StripeIcon } from '../../../../icons/Stripe';
import { Container } from './styles';

export const PaymentInfo = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <p>{t('pages.subscription.paymentInfo')}</p>
            <StripeIcon />
        </Container>
    );
};
