import { useTranslation } from 'react-i18next';

export function SubscriptionTitle() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-2 px-6">
            <h3 className="text-shark-950">Keepo Plus</h3>
            <p className="text-shark-900">
                {t('pages.subscription.header.description')}
            </p>
        </div>
    )
}