import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import * as Fonts from '../../../../components/Fonts';
import { SelectableCardItem } from '../../../../components/SelectableCardItem';
import { useAppState } from '../../../../contexts/App';

export const AdvancedConfigs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { appState } = useAppState();

    const tracking = useMemo(
        () => appState.editingSiteTrackingIntegrations || {},
        [appState.editingSiteTrackingIntegrations]
    );
    const goTrackingPage = useCallback(
        (path: string) => {
            navigate(`/settings/${path}`);
        },
        [navigate]
    );

    return (
        <>
            <Fonts.Label>
                {t('pages.settings.components.advancedConfigs.title')}
            </Fonts.Label>
            <div className="tracking-cards">
                <SelectableCardItem
                    title={t(
                        'pages.settings.components.advancedConfigs.tracking.facebookPixel.title'
                    )}
                    description={t(
                        'pages.settings.components.advancedConfigs.tracking.facebookPixel.description'
                    )}
                    status={tracking.facebookPixel ? 'ACTIVE' : 'DISABLED'}
                    onClick={() => goTrackingPage('facebook-pixel')}
                />
                <SelectableCardItem
                    title={t(
                        'pages.settings.components.advancedConfigs.tracking.googleAnalytics.title'
                    )}
                    description={t(
                        'pages.settings.components.advancedConfigs.tracking.googleAnalytics.description'
                    )}
                    status={tracking.googleAnalytics ? 'ACTIVE' : 'DISABLED'}
                    onClick={() => goTrackingPage('google-analytics')}
                />
                <SelectableCardItem
                    title={t(
                        'pages.settings.components.advancedConfigs.tracking.tagManager.title'
                    )}
                    description={t(
                        'pages.settings.components.advancedConfigs.tracking.tagManager.description'
                    )}
                    status={tracking.tagManager ? 'ACTIVE' : 'DISABLED'}
                    onClick={() => goTrackingPage('tag-manager')}
                />
            </div>
        </>
    );
};
