class NavigationState {
    state?: any;

    set(state: any) {
        this.state = state;
    }

    get(): any {
        return this.state;
    }

    reset() {
        this.state = undefined;
    }
}

export const navigationState = new NavigationState();
