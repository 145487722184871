import { SitePage } from '@keepoinc/site-page-react-component';
import { ContextProps } from '@keepoinc/site-page-react-component/dist/cjs/site';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import constants from '../../../../constants';
import { useSite } from '../../../../contexts/Site';
import { Site } from '../../../../model/site';
import { getThemeFromSite } from '../../../../model/theme';
import FixedBar from './components/FixedBar';
import { Container } from './styles';

const updateBackgroundColor = (color: string) => {
    const originalColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = color;
    return () => {
        document.body.style.backgroundColor = originalColor;
    };
};

const PreviewPage = () => {
    const { t } = useTranslation();

    const site = useSite().site as Site;
    const theme = getThemeFromSite(site);

    const contextProps: ContextProps = {
        footer: {
            credits: t('pages.editor.preview.sections.keepoFooter.credits'),
        },
        form: {
            defaultSuccessMessage: t(
                'pages.editor.form.form.successMessage.defaultValue'
            ),
            fields: {
                name: t('pages.editor.form.form.fields.name'),
                email: t('pages.editor.form.form.fields.email'),
                phone: t('pages.editor.form.form.fields.phone'),
                message: t('pages.editor.form.form.fields.message'),
            },
            invalid: t('pages.editor.form.form.invalidSubmitForm'),
        },
        hostSite: constants.site as string,
        hostApi: constants.api as string,
    };

    useEffect(() => {
        return updateBackgroundColor(theme.primary);
    }, []);

    return (
        <Container>
            <FixedBar />
            <div className="ksp preview">
                <SitePage
                    site={site}
                    theme={theme}
                    contextProps={contextProps}
                    layout={site.layout || { align: 'LEFT' }}
                />
            </div>
        </Container>
    );
};

export default PreviewPage;
