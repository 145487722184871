import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../components/Button';
import { Box } from '../../../../components/Layout';
import { Background } from '../../../../components/Modal/styles';
import constants from '../../../../constants';
import { stopScroll } from '../../../../utils/modal';
import { classNames } from '../../../../utils/styles';
import { CardContainer, Container } from './styles';

type CardProps = {
    title: string;
    description: string;
    buttonTitle: string;
    onClick: () => void;
    plus?: boolean;
};

const Card = ({
    title,
    description,
    buttonTitle,
    onClick,
    plus = false,
}: CardProps) => {
    return (
        <CardContainer onClick={onClick}>
            <h4>{title}</h4>
            <p>{description}</p>
            <Button
                type="INFO"
                size="MD"
                className={classNames({
                    'cta-btn-plus': plus,
                })}
            >
                {buttonTitle}
            </Button>
        </CardContainer>
    );
};

type ModalProps = {
    onClose: () => void;
    createNewSite: () => void;
};

export const NewSiteModal = ({ onClose, createNewSite }: ModalProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goPremium = useCallback(() => {
        navigate(`/subscription/features`);
    }, [navigate]);

    useEffect(() => {
        return stopScroll();
    }, []);
    return (
        <Background onClick={onClose}>
            <Container>
                <Box>
                    <Card
                        title={t('pages.sites.addNewModal.free.title')}
                        description={t(
                            'pages.sites.addNewModal.free.description'
                        )}
                        buttonTitle={t('pages.sites.addNewModal.free.button')}
                        onClick={createNewSite}
                    />
                    <Card
                        title={t('pages.sites.addNewModal.plus.title')}
                        description={t(
                            'pages.sites.addNewModal.plus.description'
                        ).replace('${price}', constants.price.month.value)}
                        buttonTitle={t('pages.sites.addNewModal.plus.button')}
                        plus
                        onClick={goPremium}
                    />
                </Box>
            </Container>
        </Background>
    );
};
