import { useTranslation } from 'react-i18next';

import { TrackingPage } from '../../components/TrackingPage';

export const FacebookPixelPage = () => {
    const { t } = useTranslation();
    return (
        <TrackingPage
            image="/images/pixel.png"
            providerName="facebookPixel"
            title="Meta Pixel"
            helpDescription={t(
                'pages.settings.components.advancedConfigs.tracking.facebookPixel.help'
            )}
            additionalField={{
                title: t(
                    'pages.settings.components.advancedConfigs.tracking.facebookPixel.domainVerification'
                ),
                propertyName: 'facebookPixelCodeDomainVerification',
            }}
        />
    );
};
