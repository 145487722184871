import styled from 'styled-components';

export const Content = styled.div`
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.125rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    padding-left: 2rem;
    padding-right: 2rem;

    &.pb {
        padding-bottom: 2.5rem;
    }

    span {
        cursor: pointer;
        font-size: 0.875rem;
        color: var(--color-neutral-light);
    }

    .descriptions {
        p {
            margin-top: 1rem;
        }
    }
`;

export const ButtonSection = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
`;
