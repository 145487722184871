import { useTranslation } from 'react-i18next';

import { TrackingPage } from '../../components/TrackingPage';

export const TagManager = () => {
    const { t } = useTranslation();
    return (
        <TrackingPage
            image="/images/tag.png"
            providerName="tagManager"
            title="TAG Manager"
            helpDescription={t(
                'pages.settings.components.advancedConfigs.tracking.tagManager.help'
            )}
        />
    );
};
