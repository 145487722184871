import CheckIcon from '../../../../icons/Check';

interface OffersProps extends React.ComponentProps<'ul'> {}

export function Offers({ ...rest }: OffersProps) {
    return <ul className="flex flex-col gap-2 px-6" {...rest} />;
}

interface OfferItemsProps extends React.ComponentProps<'li'> {}

export function OfferItems({ children, ...rest }: OfferItemsProps) {
    return (
        <li
            className="typography-h6 flex items-center gap-3 text-shark-950"
            {...rest}
        >
            <>
                <CheckIcon className="h-4 w-4 fill-shark-950" />
                {children}
            </>
        </li>
    );
}
