import { classNames } from '../../../../utils/styles';
import { Container } from './styles';

type Props = {
    value: number;
    onSelect: (value: number) => void;
};

export const Rating = ({ value, onSelect }: Props) => {
    const options = ['😫', '😕', '😑', '😃', '🤩'].map((icon, index) => ({
        icon,
        value: index + 1,
        active: index + 1 === value,
    }));
    console.log('rendering');

    return (
        <Container>
            {options.map((option) => (
                <div
                    key={option.value}
                    className="icon-section"
                    onClick={() => onSelect(option.value)}
                >
                    <div
                        className={classNames(
                            `icon-container value-${option.value}`,
                            {
                                'not-active': !option.active,
                            }
                        )}
                    >
                        {option.icon}
                    </div>
                </div>
            ))}
        </Container>
    );
};
