export const Step1Icon = () => {
    return (
        <svg
            width="166"
            height="160"
            viewBox="0 0 166 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4099 150.118C122.301 150.118 153.83 118.588 153.83 79.5588C153.83 40.5292 122.301 9 83.4099 9C44.5192 9 12.99 40.5292 12.99 79.5588C12.99 118.588 44.5192 150.118 83.4099 150.118Z"
                fill="#3A3F50"
            />
            <path
                d="M124.99 43C129.408 43 132.99 46.5817 132.99 51V129.817C120.337 142.397 102.817 150 83.49 150C64.3018 150 46.7821 142.258 33.99 129.817V51C33.99 46.5817 37.5717 43 41.99 43H124.99Z"
                fill="white"
            />
            <path
                d="M68.99 86C71.7514 86 73.99 83.7614 73.99 81C73.99 78.2386 71.7514 76 68.99 76C66.2286 76 63.99 78.2386 63.99 81C63.99 83.7614 66.2286 86 68.99 86Z"
                fill="#CED6E2"
            />
            <path
                d="M97.99 86C100.751 86 102.99 83.7614 102.99 81C102.99 78.2386 100.751 76 97.99 76C95.2286 76 92.99 78.2386 92.99 81C92.99 83.7614 95.2286 86 97.99 86Z"
                fill="#CED6E2"
            />
            <path
                d="M90.8568 92.0854C90.3118 92.0854 89.7668 92.2672 89.4035 92.8127C87.9502 94.8127 85.7702 95.9036 83.2268 95.9036C80.8651 95.9036 78.5035 94.8127 77.2318 92.8127C76.6868 91.9036 75.5968 91.7218 74.6884 92.4491C73.7801 92.9945 73.5984 94.0854 74.3251 94.9945C76.3234 97.9035 79.7751 99.7217 83.4085 99.7217C87.0418 99.7217 90.3118 97.9035 92.4919 94.9945C93.0369 94.0854 92.8552 92.9945 92.1285 92.4491C91.5835 92.0854 91.2202 92.0854 90.8568 92.0854Z"
                fill="#CED6E2"
            />
            <path
                d="M100.024 145.785C99.6721 147.279 99.1446 148.862 98.5292 150.18C96.8589 153.433 94.2215 155.983 90.9688 157.653C87.6281 159.323 83.6721 160.026 79.716 159.147C70.3973 157.213 64.4193 148.07 66.3534 138.752C68.2875 129.433 77.3424 123.367 86.6611 125.389C90.0017 126.092 92.9028 127.763 95.3644 130.048C99.4962 134.18 101.254 140.158 100.024 145.785Z"
                fill="#4B10FF"
            />
            <path
                d="M88.6832 140.774H84.7272V136.818C84.7272 136.027 84.1118 135.323 83.2327 135.323C82.4414 135.323 81.7382 135.939 81.7382 136.818V140.774H77.7821C76.9909 140.774 76.2876 141.389 76.2876 142.268C76.2876 143.147 76.903 143.763 77.7821 143.763H81.7382V147.719C81.7382 148.51 82.3535 149.213 83.2327 149.213C84.0239 149.213 84.7272 148.598 84.7272 147.719V143.763H88.6832C89.4744 143.763 90.1777 143.147 90.1777 142.268C90.1777 141.389 89.4744 140.774 88.6832 140.774Z"
                fill="white"
            />
            <path
                d="M145.5 149C148.538 149 151 146.538 151 143.5C151 140.462 148.538 138 145.5 138C142.462 138 140 140.462 140 143.5C140 146.538 142.462 149 145.5 149Z"
                fill="#3A3F50"
            />
            <path
                d="M156.8 133.6C158.346 133.6 159.6 132.346 159.6 130.8C159.6 129.254 158.346 128 156.8 128C155.254 128 154 129.254 154 130.8C154 132.346 155.254 133.6 156.8 133.6Z"
                fill="#3A3F50"
            />
            <path
                d="M16.8 23.6C18.3464 23.6 19.6 22.3464 19.6 20.8C19.6 19.2536 18.3464 18 16.8 18C15.2536 18 14 19.2536 14 20.8C14 22.3464 15.2536 23.6 16.8 23.6Z"
                fill="#3A3F50"
            />
            <path
                d="M19.5 150C21.9853 150 24 147.985 24 145.5C24 143.015 21.9853 141 19.5 141C17.0147 141 15 143.015 15 145.5C15 147.985 17.0147 150 19.5 150Z"
                fill="#5E6272"
            />
            <path
                d="M160 41C161.657 41 163 39.6569 163 38C163 36.3431 161.657 35 160 35C158.343 35 157 36.3431 157 38C157 39.6569 158.343 41 160 41Z"
                fill="#5E6272"
            />
        </svg>
    );
};
