import { classNames } from '../../utils/styles';
import { Container } from './styles';

type Props = {
    disabled?: boolean;
};

export const PlusLabel = ({ disabled }: Props) => {
    return (
        <Container className={classNames({ disabled: !!disabled })}>
            Plus
        </Container>
    );
};
