import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components/Button';
import { SmallCircleContainer } from '../../../../components/CircleContainer/styles';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import { useAppState } from '../../../../contexts/App';
import { useQueryUrl } from '../../../../hooks/QueryUrl';
import CloseIcon from '../../../../icons/Close';
import { SuccessIcon } from '../../../../icons/Success';
import { Site } from '../../../../model/site';
import { keepoInternalAnalyticsService } from '../../../../services/analytics';
import { api, ApiSiteResponse, Subscription } from '../../../../services/api';
import { Container } from './styles';

type Props = {
    setSite: (site: Site) => void;
};

export const SuccessPage = ({ setSite }: Props) => {
    const { t } = useTranslation();
    const query = useQueryUrl();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();

    const [loading, setLoading] = useState(true);
    const [subscribedSite, setSubscribedSite] = useState<ApiSiteResponse>();

    const editHandler = useCallback(() => {
        setSite((subscribedSite?.draft || subscribedSite?.site) as Site);

        setAppState({
            ...appState,
            editingSiteId: subscribedSite?.siteId as string,
            editingSiteModified: false,
            published: !!subscribedSite?.site,
            editingSiteDisabled: false,
            editingSiteSubscription:
                subscribedSite?.subscription as Subscription,
        });
        navigate(subscribedSite?.site ? '/editor/sections' : '/editor/title');
    }, [
        appState,
        navigate,
        setAppState,
        setSite,
        subscribedSite?.draft,
        subscribedSite?.site,
        subscribedSite?.siteId,
        subscribedSite?.subscription,
    ]);

    const nextPageHandler = useCallback(() => {
        if (subscribedSite?.disabled) {
            navigate('/sites');
            return;
        } else {
            editHandler();
        }
    }, [editHandler, navigate, subscribedSite?.disabled]);

    const load = useCallback(async () => {
        const sites = await api.getSites();
        const subscribedSiteId = query.get('siteId');

        const predicate: (site: ApiSiteResponse) => boolean = subscribedSiteId
            ? (site) =>
                  site.siteId === subscribedSiteId &&
                  site.subscription?.type === 'PLUS'
            : (site) => !site.site && site.subscription?.type === 'PLUS';

        const subscribedSite = sites.find(predicate);

        if (!subscribedSite) {
            navigate(`/`, { replace: true });
            navigate(`/sites`);
            return;
        }

        setSubscribedSite(subscribedSite);
        setLoading(false);
    }, [navigate, query]);

    const notifyPurchase = async () => {
        const user = await api.getUser();
        keepoInternalAnalyticsService.notifyPurchaseEvent(user);
    };

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        notifyPurchase();
    }, []);

    return loading ? (
        <LoadingModal />
    ) : (
        <Container>
            <div className="header">
                <SmallCircleContainer
                    $color="var(--color-neutral-base)"
                    cursor="pointer"
                    onClick={() => navigate('/sites')}
                >
                    <CloseIcon width={14} height={14} />
                </SmallCircleContainer>
            </div>
            <div className="banner">
                <SuccessIcon />
            </div>
            <div className="message">
                <h2>{t('pages.subscription.success.message.title')}</h2>
                <p>{t('pages.subscription.success.message.description')}</p>
            </div>
            <Box>
                <Button type="WHITE" size="LG" onClick={nextPageHandler}>
                    {!subscribedSite?.site
                        ? t(
                              'pages.subscription.success.createNewKeepoContinueButton'
                          )
                        : t(
                              'pages.subscription.success.editKeepoContinueButton'
                          )}
                </Button>
            </Box>
        </Container>
    );
};
