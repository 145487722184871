const MailIcon = () => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.6673 9.16667H7.33398C6.32146 9.16667 5.50065 9.98748 5.50065 11V33C5.50065 34.0125 6.32146 34.8333 7.33398 34.8333H36.6673C37.6798 34.8333 38.5006 34.0125 38.5006 33V11C38.5006 9.98748 37.6798 9.16667 36.6673 9.16667ZM7.33398 5.5C4.29642 5.5 1.83398 7.96243 1.83398 11V33C1.83398 36.0376 4.29642 38.5 7.33398 38.5H36.6673C39.7049 38.5 42.1673 36.0376 42.1673 33V11C42.1673 7.96243 39.7049 5.5 36.6673 5.5H7.33398Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.54607 8.89616C5.19427 8.11832 6.3503 8.01322 7.12814 8.66142L20.8255 20.4688C21.5053 21.0354 22.4929 21.0354 23.1728 20.4688L36.3902 9.13968C37.1681 8.49148 38.3241 8.59658 38.9723 9.37442C39.6205 10.1523 39.5154 11.3083 38.7376 11.9565L25.5201 23.2856C23.4805 24.9853 20.5178 24.9853 18.4781 23.2856L4.7808 11.4782C4.00296 10.83 3.89787 9.674 4.54607 8.89616Z"
                fill="white"
            />
        </svg>
    );
};

export default MailIcon;
