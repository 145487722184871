import { classNames } from '../../utils/styles';
import { Button } from '../Button';
import { LoadingModal } from '../LoadingModal';
import Modal from '../Modal';
import { ButtonSection, Content } from './styles';

type Props = {
    type?: 'DANGER' | 'INFO' | 'WHITE';
    title: string;
    descriptions?: string[];
    confirmButtonTitle?: string;
    cancelTitle?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onExit?: () => void;
    outsideExit?: boolean;
    hideExitButton?: boolean;
    confirming?: boolean;
};

const ConfirmModal = ({
    type,
    title,
    descriptions = [],
    confirmButtonTitle,
    cancelTitle,
    onConfirm,
    onCancel,
    onExit,
    outsideExit = true,
    hideExitButton,
    confirming,
}: Props) => {
    return confirming ? (
        <LoadingModal />
    ) : (
        <Modal
            outsideExit={outsideExit}
            onExit={onExit || onCancel}
            hideExitButton={hideExitButton}
        >
            <Content
                className={classNames({
                    pb: !confirmButtonTitle && !cancelTitle,
                })}
            >
                {title}
                <div className="descriptions">
                    {descriptions.map((description, index) => {
                        return <p key={index}>{description}</p>;
                    })}
                </div>
                {confirmButtonTitle && type ? (
                    <ButtonSection>
                        <Button onClick={onConfirm} size="LG" type={type}>
                            {confirmButtonTitle}
                        </Button>
                    </ButtonSection>
                ) : null}
                {cancelTitle && onCancel && (
                    <span onClick={onCancel}>{cancelTitle}</span>
                )}
            </Content>
        </Modal>
    );
};

export default ConfirmModal;
