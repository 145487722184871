import styled from 'styled-components';

export const TextedCircleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
        margin-top: 0.25rem;
        color: var(--color-neutral-light);
    }
`;

export const FooterButtonsSection = styled.div`
    display: flex;
    width: 100%;

    > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 2.25rem;

        > div:first-child {
            margin-right: 1.5rem;
        }
    }

    > div:last-child {
        width: 100%;
        display: flex;
        align-items: center;
    }
`;
