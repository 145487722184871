import rfdc from 'rfdc';

export function deepEqual(obj1: any, obj2: Record<string, any>): boolean {
    function isObject(obj: unknown) {
        if (typeof obj === 'object' && obj != null) {
            return true;
        } else {
            return false;
        }
    }

    if (obj1 === obj2) {
        return true;
    } else if (isObject(obj1) && isObject(obj2)) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
            return false;
        }
        for (const prop in obj1) {
            if (!deepEqual(obj1[prop], obj2[prop])) {
                return false;
            }
        }
        return true;
    }
    return false;
}

export function clone<T>(obj: unknown): T {
    return rfdc()(obj) as T;
}
