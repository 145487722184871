import { fetchAuthSession } from 'aws-amplify/auth';

export const isAuthenticated = async () => {
    const { tokens } = await fetchAuthSession();
    try {
        return !!tokens?.accessToken;
    } catch (e) {
        return false;
    }
};

// export const federatedSignIn = (provider: CognitoHostedUIIdentityProvider) =>
//     Auth.federatedSignIn({ provider, customState: 'random' });
