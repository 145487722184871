type Props = {
    color: string;
    width?: number;
    height?: number;
};
export const AudioIcon = ({ color, height = 24, width = 24 }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.456 3.81824C16.9539 3.81824 16.5469 4.22525 16.5469 4.72733C16.5469 5.22941 16.9539 5.63642 17.456 5.63642H21.0923C21.5944 5.63642 22.0014 5.22941 22.0014 4.72733C22.0014 4.22525 21.5944 3.81824 21.0923 3.81824H17.456Z"
                fill={color}
            />
            <path
                d="M16.5469 8.36369C16.5469 7.86161 16.9539 7.4546 17.456 7.4546H21.0923C21.5944 7.4546 22.0014 7.86161 22.0014 8.36369C22.0014 8.86577 21.5944 9.27278 21.0923 9.27278H17.456C16.9539 9.27278 16.5469 8.86577 16.5469 8.36369Z"
                fill={color}
            />
            <path
                d="M17.456 11.091C16.9539 11.091 16.5469 11.498 16.5469 12.0001C16.5469 12.5021 16.9539 12.9091 17.456 12.9091H21.0923C21.5944 12.9091 22.0014 12.5021 22.0014 12.0001C22.0014 11.498 21.5944 11.091 21.0923 11.091H17.456Z"
                fill={color}
            />
            <path
                d="M16.5469 15.6364C16.5469 15.1343 16.9539 14.7273 17.456 14.7273H21.0923C21.5944 14.7273 22.0014 15.1343 22.0014 15.6364C22.0014 16.1385 21.5944 16.5455 21.0923 16.5455H17.456C16.9539 16.5455 16.5469 16.1385 16.5469 15.6364Z"
                fill={color}
            />
            <path
                d="M16.5469 19.2728C16.5469 18.7707 16.9539 18.3637 17.456 18.3637H21.0923C21.5944 18.3637 22.0014 18.7707 22.0014 19.2728C22.0014 19.7749 21.5944 20.1819 21.0923 20.1819H17.456C16.9539 20.1819 16.5469 19.7749 16.5469 19.2728Z"
                fill={color}
            />
            <path
                d="M10.1825 11.0909C9.68045 11.0909 9.27344 11.498 9.27344 12C9.27344 12.5021 9.68045 12.9091 10.1825 12.9091H13.8189C14.321 12.9091 14.728 12.5021 14.728 12C14.728 11.498 14.321 11.0909 13.8189 11.0909H10.1825Z"
                fill={color}
            />
            <path
                d="M9.27344 15.6364C9.27344 15.1343 9.68045 14.7273 10.1825 14.7273H13.8189C14.321 14.7273 14.728 15.1343 14.728 15.6364C14.728 16.1385 14.321 16.5455 13.8189 16.5455H10.1825C9.68045 16.5455 9.27344 16.1385 9.27344 15.6364Z"
                fill={color}
            />
            <path
                d="M9.27344 19.2728C9.27344 18.7707 9.68045 18.3637 10.1825 18.3637H13.8189C14.321 18.3637 14.728 18.7707 14.728 19.2728C14.728 19.7748 14.321 20.1819 13.8189 20.1819H10.1825C9.68045 20.1819 9.27344 19.7748 9.27344 19.2728Z"
                fill={color}
            />
            <path
                d="M2.90909 7.45459C2.40701 7.45459 2 7.8616 2 8.36368C2 8.86576 2.40701 9.27277 2.90909 9.27277H6.54545C7.04753 9.27277 7.45455 8.86576 7.45455 8.36368C7.45455 7.8616 7.04753 7.45459 6.54545 7.45459H2.90909Z"
                fill={color}
            />
            <path
                d="M2 12C2 11.498 2.40701 11.091 2.90909 11.091H6.54545C7.04753 11.091 7.45455 11.498 7.45455 12C7.45455 12.5021 7.04753 12.9091 6.54545 12.9091H2.90909C2.40701 12.9091 2 12.5021 2 12Z"
                fill={color}
            />
            <path
                d="M2.90909 14.7273C2.40701 14.7273 2 15.1343 2 15.6364C2 16.1385 2.40701 16.5455 2.90909 16.5455H6.54545C7.04753 16.5455 7.45455 16.1385 7.45455 15.6364C7.45455 15.1343 7.04753 14.7273 6.54545 14.7273H2.90909Z"
                fill={color}
            />
            <path
                d="M2.90909 18.3637C2.40701 18.3637 2 18.7707 2 19.2728C2 19.7748 2.40701 20.1819 2.90909 20.1819H6.54545C7.04753 20.1819 7.45455 19.7748 7.45455 19.2728C7.45455 18.7707 7.04753 18.3637 6.54545 18.3637H2.90909Z"
                fill={color}
            />
        </svg>
    );
};
