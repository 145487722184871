type Props = {
    size: number;
    color: string;
};

const TrashIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.39799 3.62L5.80123 2.41027C5.8829 2.16526 6.11218 2 6.37044 2H9.61044C9.8687 2 10.098 2.16526 10.1797 2.41027L10.5829 3.62H13.3904C13.7218 3.62 13.9904 3.88863 13.9904 4.22C13.9904 4.55137 13.7218 4.82 13.3904 4.82H12.8762L12.4359 12.3057C12.3799 13.2571 11.592 14 10.639 14H5.34193C4.38887 14 3.601 13.2571 3.54503 12.3057L3.1047 4.82H2.6C2.26863 4.82 2 4.55137 2 4.22C2 3.88863 2.26863 3.62 2.6 3.62H5.39799ZM6.6629 3.62H9.31799L9.17799 3.2H6.8029L6.6629 3.62ZM11.6741 4.82H4.30677L4.74296 12.2352C4.76162 12.5524 5.02424 12.8 5.34193 12.8H10.639C10.9566 12.8 11.2193 12.5524 11.2379 12.2352L11.6741 4.82ZM9.01161 6.34258C9.03228 6.01185 9.31714 5.7605 9.64787 5.78117C9.97859 5.80184 10.2299 6.08671 10.2093 6.41743L9.93927 10.7374C9.9186 11.0682 9.63374 11.3195 9.30301 11.2988C8.97229 11.2782 8.72094 10.9933 8.74161 10.6626L9.01161 6.34258ZM7.23927 10.6626C7.25994 10.9933 7.00859 11.2782 6.67787 11.2988C6.34714 11.3195 6.06228 11.0682 6.04161 10.7374L5.77161 6.41743C5.75094 6.08671 6.00229 5.80184 6.33301 5.78117C6.66374 5.7605 6.9486 6.01185 6.96927 6.34258L7.23927 10.6626Z"
                fill={color}
            />
        </svg>
    );
};

export default TrashIcon;
