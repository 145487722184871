type Props = {
    width: number;
    height: number;
    color?: string;
};

const CloseIcon = ({ width, height, color = 'white' }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.48453 9.48453C9.90511 9.06394 9.90511 8.38205 9.48453 7.96146L6.52309 5.00003L9.48104 2.04208L9.57743 1.93179C9.8987 1.50966 9.86658 0.904552 9.48104 0.519019C9.06046 0.098437 8.37856 0.0984371 7.95798 0.519019L5.00003 3.47697L2.03845 0.515387L1.92816 0.419004C1.50603 0.0977265 0.900921 0.129854 0.515387 0.515387C0.0948057 0.935969 0.0948057 1.61787 0.515387 2.03845L3.47697 5.00003L0.518989 7.95801L0.422606 8.0683C0.101328 8.49043 0.133456 9.09554 0.518989 9.48107C0.939571 9.90166 1.62147 9.90166 2.04205 9.48107L5.00003 6.52309L7.96146 9.48453L8.07175 9.58091C8.49388 9.90219 9.09899 9.87006 9.48453 9.48453Z"
                fill={color}
            />
        </svg>
    );
};

export default CloseIcon;
