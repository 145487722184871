import styled from 'styled-components';

export const TabsBody = styled.div``;

export const TabsHeader = styled.ul`
    display: flex;
    width: 100%;
    background: var(--color-neutral-dark);
    border-radius: 100vw;
    margin-bottom: 16px;
`;

export const TabsNavItem = styled.li`
    flex: 1;
    transition: 0.15s ease-in-out all;

    > a {
        font-family: 'Inter', sans-serif;
        font-size: 0.625rem;
        line-height: 1rem;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 8px 0;
        transition: all 0.2s ease-in;
        text-align: center;
        display: block;
    }

    &.active {
        background: var(--color-brand-blue);
        border-radius: 100vw;

        a {
            color: var(--color-neutral-white);
        }
    }
`;

export const TabsContent = styled.div`
    position: relative;
`;

export const TabsPane = styled.div`
    display: none;

    &.show {
        display: block;
        animation: changeTabsAnimationFade 500ms ease-in-out;
    }
`;
