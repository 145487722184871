import styled from 'styled-components';

interface LabelProps {
    center?: boolean;
    color?: string;
}

export const Label = styled.h5.attrs<LabelProps>((props) => ({
    center: props.center,
    color: props.color,
}))`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props) =>
        props.color ? props.color : 'var(--color-neutral-light)'};
    text-align: ${(props) => props.center && 'center'};
`;
