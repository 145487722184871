import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SmallCircleContainer } from '../../../../../../../../components/CircleContainer/styles';
import { CircleSpinner } from '../../../../../../../../components/CircleSpinner';
import ConfirmModal from '../../../../../../../../components/ConfirmModal';
import { Label } from '../../../../../../../../components/Form/Label/styles';
import { RadioInput } from '../../../../../../../../components/RadioInput';
import {
    CropProps,
    UploadImageInput,
} from '../../../../../../../../components/UploadImageInput';
import { useAppState } from '../../../../../../../../contexts/App';
import { EmojiIcon } from '../../../../../../../../icons/Emoji';
import PlusIcon from '../../../../../../../../icons/Plus';
import { ReloadIcon } from '../../../../../../../../icons/Reload';
import TrashIcon from '../../../../../../../../icons/Trash';
import { ImageIcon } from '../../../../../Sections/icons/Image';
import { EmojiModal } from './EmojiModal';
import { Image } from './Image';
import {
    Container,
    ContentContainer,
    EmojiContainer,
    InputButtons,
    InputSection,
    PreviewBox,
    SelectSection,
    SelectionSectionInput,
} from './styles';

type ThumbnailType = 'IMAGE' | 'EMOJI';

type Props = {
    thumbnailType: ThumbnailType;
    onThumbnailChange: (type: ThumbnailType) => void;
    image?: string;
    emoji?: string;
    onChangeImage: (image?: string) => void;
    onChangeEmoji: (emoji?: string) => void;
};

const cropOptions: CropProps = {
    slice: {
        aspect: 1,
    },
    size: {
        width: 400,
        height: 400,
    },
    cropShape: 'rect',
};

export const LinkThumbnail = ({
    thumbnailType,
    onThumbnailChange,
    image,
    emoji,
    onChangeImage,
    onChangeEmoji,
}: Props) => {
    const { t } = useTranslation();

    const siteId = useAppState().appState?.editingSiteId as string;

    const [isUploadingImage, setUploadingImage] = useState(false);
    const [isShowEmojiModal, setShowEmojiModal] = useState(false);
    const [isRemovingImage, setRemovingImage] = useState(false);

    const isCurrentTypeValuePresent =
        (thumbnailType === 'IMAGE' && !!image) ||
        (thumbnailType === 'EMOJI' && !!emoji);

    const onRemove = useCallback(() => {
        if (thumbnailType === 'IMAGE') {
            setRemovingImage(true);
        } else {
            onChangeImage(undefined);
            onChangeEmoji(undefined);
        }
    }, [onChangeImage, onChangeEmoji, thumbnailType]);

    const onUploadImageHandler = useCallback(
        (image: string) => {
            onChangeImage(image);
            setUploadingImage(false);
        },
        [onChangeImage]
    );

    const confirmRemoveImage = useCallback(() => {
        onChangeImage(undefined);
        onChangeEmoji(undefined);
        setRemovingImage(false);
    }, [onChangeImage, onChangeEmoji]);

    return (
        <Container>
            {isRemovingImage && (
                <ConfirmModal
                    type="DANGER"
                    title={t('actions.loadImage.remove.confirmModal.title')}
                    cancelTitle={t(
                        'actions.loadImage.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'actions.loadImage.remove.confirmModal.confirmButton'
                    )}
                    onCancel={() => setRemovingImage(false)}
                    onConfirm={confirmRemoveImage}
                />
            )}
            {isShowEmojiModal && (
                <EmojiModal
                    onSelect={(emojiData) => {
                        onChangeEmoji(emojiData.emoji);
                        setShowEmojiModal(false);
                    }}
                    onCancel={() => setShowEmojiModal(false)}
                />
            )}
            <Label>{t('pages.editor.links.item.form.thumbnail.label')}</Label>
            <ContentContainer>
                <SelectSection>
                    <SelectionSectionInput
                        onClick={() => onThumbnailChange('IMAGE')}
                    >
                        <RadioInput active={thumbnailType === 'IMAGE'} />
                        <h6>
                            {t('pages.editor.links.item.form.thumbnail.image')}
                        </h6>
                    </SelectionSectionInput>
                    <SelectionSectionInput
                        onClick={() => onThumbnailChange('EMOJI')}
                    >
                        <RadioInput active={thumbnailType === 'EMOJI'} />
                        <h6>
                            {t('pages.editor.links.item.form.thumbnail.emoji')}
                        </h6>
                    </SelectionSectionInput>
                </SelectSection>
                <InputSection>
                    {isUploadingImage && (
                        <PreviewBox $hideBorder>
                            <CircleSpinner size="40px" />
                        </PreviewBox>
                    )}
                    {thumbnailType === 'IMAGE' && !isUploadingImage && (
                        <UploadImageInput
                            siteId={siteId}
                            accept="image/*"
                            onUpload={onUploadImageHandler}
                            crop={cropOptions}
                        >
                            {image ? (
                                <Image image={image} />
                            ) : (
                                <PreviewBox>
                                    <ImageIcon
                                        width={34}
                                        height={34}
                                        color="var(--color-neutral-base)"
                                    />
                                </PreviewBox>
                            )}
                        </UploadImageInput>
                    )}
                    {!emoji &&
                        thumbnailType === 'EMOJI' &&
                        !isUploadingImage && (
                            <PreviewBox onClick={() => setShowEmojiModal(true)}>
                                <EmojiIcon />
                            </PreviewBox>
                        )}

                    {emoji &&
                        thumbnailType === 'EMOJI' &&
                        !isUploadingImage && (
                            <EmojiContainer
                                onClick={() => setShowEmojiModal(true)}
                            >
                                <h1>{emoji}</h1>
                            </EmojiContainer>
                        )}
                    <InputButtons>
                        <UploadImageInput
                            siteId={siteId}
                            crop={cropOptions}
                            accept="image/*"
                            disabled={thumbnailType === 'EMOJI'}
                            onUpload={onUploadImageHandler}
                        >
                            <SmallCircleContainer
                                cursor="pointer"
                                $color="var(--color-neutral-dark)"
                                onClick={() =>
                                    thumbnailType === 'EMOJI' &&
                                    setShowEmojiModal(true)
                                }
                            >
                                {!isCurrentTypeValuePresent ? (
                                    <PlusIcon
                                        color="var(--color-neutral-white)"
                                        width={14}
                                        height={14}
                                    />
                                ) : (
                                    <ReloadIcon />
                                )}
                            </SmallCircleContainer>
                        </UploadImageInput>
                        <SmallCircleContainer
                            cursor={
                                isCurrentTypeValuePresent
                                    ? 'pointer'
                                    : undefined
                            }
                            $color="var(--color-neutral-dark)"
                            onClick={onRemove}
                        >
                            <TrashIcon
                                color={
                                    isCurrentTypeValuePresent
                                        ? 'var(--color-neutral-white)'
                                        : 'var(--color-neutral-base)'
                                }
                                size={16}
                            />
                        </SmallCircleContainer>
                    </InputButtons>
                </InputSection>
            </ContentContainer>
        </Container>
    );
};
