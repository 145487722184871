import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/Button';
import ConfirmModal from '../../components/ConfirmModal';
import Input from '../../components/Form/Input/input';
import { Select } from '../../components/Form/Input/select';
import { TextArea } from '../../components/Form/Input/textArea';
import Label from '../../components/Form/Label';
import { Form, FormGroup } from '../../components/Form/styles';
import Header from '../../components/Header';
import { Box } from '../../components/Layout';
import { LoadingModal } from '../../components/LoadingModal';
import { SectionHelp } from '../../components/SectionHelp';
import { User, api } from '../../services/api';
import { isEmailValid } from '../../utils/email';
import { Container } from './styles';

const SUBJECT_OPTIONS = [
    'SELECT',
    'ABOUT_USE',
    'HELP_SUBSCRIPTION',
    'FEEDBACK',
    'BUG_REPORT',
    'OTHERS',
];

export const HelpPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const subjectOptions = useMemo(() => {
        return SUBJECT_OPTIONS.map((option) => ({
            id: option,
            description: t(`pages.help.form.subjectOptions.${option}`),
        }));
    }, [t]);

    const [user, setUser] = useState<User>();

    const [subject, setSubject] = useState<string>(subjectOptions[0].id);
    const [message, setMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const isSubmitDisabled =
        subject === 'SELECT' || !message || !isEmailValid(email);

    const loadUser = useCallback(async () => {
        const user = await api.getUser();
        setUser(user);
        setEmail(user.email);
    }, []);

    const saveHandler = async () => {
        if (isSubmitDisabled) {
            return;
        }
        setSubmitting(true);
        try {
            await api.submitHelp({
                subject,
                message,
                email,
            });
            setSubmitted(true);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    return !user ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.help.header.title')} />
            <SectionHelp
                message={t('pages.help.help').replace(
                    '${name}',
                    user.givenName
                )}
            />
            <Box>
                <Form>
                    <FormGroup>
                        <Select
                            id="subject"
                            name="subject"
                            value={subject}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => setSubject(e.target.value)}
                        >
                            {subjectOptions.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.description}
                                </option>
                            ))}
                        </Select>
                        <Label
                            htmlFor="subject"
                            text={t('pages.help.form.subject')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextArea
                            id="message"
                            name="message"
                            autoComplete="off"
                            rows={7}
                            value={message}
                            //@ts-ignore
                            onChange={(e) => setMessage(e.target.value)}
                            maxLength={1000}
                        />
                        <Label
                            maxLenght={1000}
                            usedLenght={message?.length || 0}
                            htmlFor="message"
                            text={t('pages.help.form.message')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="email"
                            id="email"
                            maxLength={100}
                            ignoreLengthInfo
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label={t('pages.help.form.email')}
                        />
                    </FormGroup>
                </Form>
            </Box>
            <Box>
                <Button
                    type="INFO"
                    size="LG"
                    disabled={isSubmitDisabled || isSubmitting}
                    onClick={saveHandler}
                >
                    {t('pages.help.form.submitButton')}
                </Button>
            </Box>
            {isSubmitted && (
                <ConfirmModal
                    title={t('pages.help.successModal.message')}
                    type="INFO"
                    hideExitButton
                    confirmButtonTitle={t(
                        'pages.help.successModal.buttonTitle'
                    )}
                    onConfirm={() => navigate(-1)}
                />
            )}
        </Container>
    );
};
