import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, LinkButton } from '../../../../components/Button';
import { SmallCircleContainer } from '../../../../components/CircleContainer/styles';
import * as Fonts from '../../../../components/Fonts';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import { useAppState } from '../../../../contexts/App';
import CloseIcon from '../../../../icons/Close';
import { SuccessIcon } from '../../../../icons/Success';
import { Site } from '../../../../model/site';
import {
    ApiSiteResponse,
    Subscription,
    SubscriptionDetail,
    api,
} from '../../../../services/api';
import { capitalizeFirstLetter } from '../../../../utils/string';

const isPtLang = (language: string) => language.toUpperCase().includes('PT');

import { Container } from './styles';

type Props = {
    setSite: (site: Site) => void;
};

export const SubscriptionDetailPage = ({ setSite }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { appState, setAppState } = useAppState();

    const [loading, setLoading] = useState(true);
    const [detail, setDetail] = useState<SubscriptionDetail>();
    const [editingSite, setEditingSite] = useState<ApiSiteResponse>();

    const ptLang = useMemo(() => isPtLang(i18n.language), [i18n]);

    const dateFormat = ptLang ? 'DD/MM/YYYY' : 'YYYY-DD-MM';

    const isCanceled = !!detail?.canceledAt;

    const loadInitialData = useCallback(
        async (siteId: string) => {
            const [detail, sites] = await Promise.all([
                api.getSubscriptionDetail(siteId),
                api.getSites(),
            ]);

            if (!Object.keys(detail).length) {
                navigate('/sites');
                return;
            }
            setEditingSite(sites.find((site) => site.siteId === siteId));
            setDetail(detail);
            setLoading(false);
        },
        [navigate]
    );

    const editSite = useCallback(() => {
        setSite((editingSite?.draft || editingSite?.site) as Site);
        setAppState({
            ...appState,
            editingSiteId: editingSite?.siteId as string,
            editingSiteModified: false,
            published: !!editingSite?.site,
            editingSiteDisabled: false,
            editingSiteSubscription: editingSite?.subscription as Subscription,
        });
        navigate(editingSite?.site ? '/editor/sections' : '/editor/title');
    }, [
        appState,
        editingSite?.draft,
        editingSite?.site,
        editingSite?.siteId,
        editingSite?.subscription,
        navigate,
        setAppState,
        setSite,
    ]);

    useEffect(() => {
        const site = new URLSearchParams(location.search).get('site');
        if (!site) {
            navigate('/sites');
        } else {
            loadInitialData(site);
        }
    }, [location, navigate, loadInitialData]);

    if (loading && !detail) {
        return <LoadingModal />;
    }

    const updatePayment = () => {
        window.location.href = detail?.billingPortalSessionUrl as string;
    };

    return (
        <Container>
            <div className="header">
                <SmallCircleContainer
                    $color="var(--color-neutral-base)"
                    cursor="pointer"
                    onClick={() => navigate(-1)}
                >
                    <CloseIcon width={14} height={14} />
                </SmallCircleContainer>
            </div>
            <div className="banner">
                <SuccessIcon />
            </div>
            <div className="message">
                {isCanceled ? (
                    <h2>
                        {t('pages.subscriptionDetail.header.canceledTitlePre')}{' '}
                        <span>{editingSite?.site.title as string} </span>
                        {t('pages.subscriptionDetail.header.canceledTitlePos')}
                    </h2>
                ) : (
                    <h2>{t('pages.subscriptionDetail.header.title')}</h2>
                )}
                <p>
                    {t(
                        isCanceled
                            ? 'pages.subscriptionDetail.header.canceledSubscriptionInfo'
                            : 'pages.subscriptionDetail.header.subscriptionInfo'
                    )}{' '}
                    <strong>
                        {dayjs(detail?.currentPeriodEnd).format(dateFormat)}
                    </strong>
                </p>
            </div>
            <Box className="npt">
                <Button type="OUTLINE" size="LG" onClick={editSite}>
                    {t('pages.subscriptionDetail.header.actions.edit.title')}
                </Button>
                {!isCanceled && detail?.billingPortalSessionUrl && (
                    <Button
                        type="OUTLINE"
                        size="LG"
                        onClick={updatePayment}
                        style={{ marginTop: 16 }}
                    >
                        {t(
                            'pages.subscriptionDetail.actions.updateSubscription.title'
                        )}
                    </Button>
                )}
            </Box>
            <Box className="transactions">
                <Fonts.Label className="title">
                    {t('pages.subscriptionDetail.transactions.title')}
                </Fonts.Label>
                {detail?.invoices.map(({ createdAt, card }, index) => {
                    return (
                        <div key={index} className="transaction">
                            <p>
                                {capitalizeFirstLetter(
                                    dayjs(createdAt)
                                        .locale(ptLang ? 'pt-br' : 'en-us')
                                        .format('MMMM YYYY')
                                )}
                            </p>
                            {card && <p>****{card.last4}</p>}
                            <p className="success">
                                {t(
                                    'pages.subscriptionDetail.transactions.paid'
                                )}
                            </p>
                        </div>
                    );
                })}
            </Box>
            <Box className="footer">
                <LinkButton onClick={() => navigate(-1)}>
                    {t('actions.goBack')}
                </LinkButton>
            </Box>
        </Container>
    );
};
