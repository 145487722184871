import ArrowDownIcon from '../../../../icons/ArrowDown';
import ArrowUpIcon from '../../../../icons/ArrowUp';
import { AnalyticsBigNumbers } from '../../../../services/api/models';
import { Container } from './styles';

type Props = {
    formatterNumber: (value: number) => string;
    numbers: AnalyticsBigNumbers;
};
export const BigsNumbers = ({ formatterNumber, numbers }: Props) => {
    return (
        <Container>
            <h2>{formatterNumber(numbers.count)}</h2>
            <h3>
                {formatterNumber(numbers.variation.value)}
                {' % '}
                {numbers.variation.positive ? (
                    <ArrowUpIcon color="var(--color-brand-blue)" />
                ) : (
                    <ArrowDownIcon color="var(--color-brand-blue)" />
                )}
            </h3>
        </Container>
    );
};
