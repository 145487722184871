import styled from 'styled-components';

export const Container = styled.div`
    h6 {
        color: var(--color-neutral-light);
    }
`;

export const CardItems = styled.div`
    margin-top: 0.5rem;
`;

export const CardItem = styled.div`
    will-change: transform;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-neutral-dark);
    border-radius: 0.4rem;
    height: 96px;
    margin-bottom: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 0 1rem;
    justify-content: space-between;

    .left-section {
        display: flex;
        align-items: center;

        &.has-thumbnail {
            @media (max-width: 320px) {
                max-width: 40%;
                > .title {
                    white-space: nowrap;
                }
            }
        }

        svg {
            min-width: 16px;
            margin-right: 16px;
        }

        > .title {
            max-height: 64px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        span {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

    .right-section {
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    .thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-neutral-white);
        width: 64px;
        height: 64px;
        margin-right: 1rem;
        border-radius: 4px;

        &.emoji {
            font-size: 32px;
        }
    }
`;

type ThumbnailImageProps = {
    $image: string;
};

export const ThumbnailImage = styled.div<ThumbnailImageProps>`
    background-image: url(${(props) => props.$image});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
`;

export const AddItemBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 96px;
    border: 2px dashed var(--color-neutral-base);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
`;
