import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../../components/Button';
import useWindowSize from '../../../../../../../hooks/WindowSize';
import InputColor from '../../PreDefinedColorSection/components/InputColor';
import { ModalColor, ModalColorBody, ModalColorFooter } from './styles';

type Props = {
    onExit: () => void;
    onSave: (color: string) => void;
    color: string;
};

export const SelectColorModal = ({ onExit, onSave, color }: Props) => {
    const { t } = useTranslation();

    const modalRef = useRef<HTMLDivElement>(null);
    const windowSize = useWindowSize();

    const [colorPickerWidth, setColorPickerWidth] = useState(380);
    const [internalColor, setInternalColor] = useState(color);

    useEffect(() => {
        const modalWidth = modalRef.current?.offsetWidth;
        if (!windowSize.width || !modalWidth) {
            return;
        }
        const newColorPickerWidthCandidate = modalWidth - 32; //32 is modal padding;
        if (colorPickerWidth !== newColorPickerWidthCandidate) {
            setColorPickerWidth(newColorPickerWidthCandidate);
        }
    }, [modalRef, windowSize, colorPickerWidth]);

    return (
        <ModalColor
            title={t('pages.editor.settings.modal.selectColor.title')}
            onExit={onExit}
            ref={modalRef}
        >
            <ModalColorBody>
                <HexColorPicker
                    color={internalColor}
                    onChange={setInternalColor}
                />
            </ModalColorBody>
            <ModalColorFooter>
                <div>
                    <InputColor
                        color={internalColor}
                        onChange={setInternalColor}
                    />
                </div>
                <Button
                    type="INFO"
                    size="LG"
                    onClick={() => onSave(internalColor)}
                >
                    {t('actions.save')}
                </Button>
            </ModalColorFooter>
        </ModalColor>
    );
};
