import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';
import { SmallCircleContainer } from '../../../../components/CircleContainer/styles';
import ConfirmModal from '../../../../components/ConfirmModal';
import * as Fonts from '../../../../components/Fonts';
import Input from '../../../../components/Form/Input/input';
import { TextArea } from '../../../../components/Form/Input/textArea';
import Label from '../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import {
    CropProps,
    UploadImageInput,
} from '../../../../components/UploadImageInput';
import { useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import PlusIcon from '../../../../icons/Plus';
import TrashIcon from '../../../../icons/Trash';
import { WelcomeSection } from '../../../../model/site';
import { sanitizeUrl } from '../../../../utils/url'; //TODO
import { useSection } from '../../hooks/Section';
import {
    CircleAddImage,
    Container,
    LogoPreviewSection,
    UploadImageSection,
    UploadImagemButtonSection,
} from './styles';

const buttonTitleMaxLenght = 24;

const avatarCropOptions: CropProps = {
    slice: {
        aspect: 1,
    },
    size: {
        width: 400,
        height: 400,
    },
    cropShape: 'round',
};

const WelcomePage = () => {
    const { t } = useTranslation();
    const { site } = useSite();
    const editingSiteId = useAppState().appState.editingSiteId;

    const setDefaultValues = useCallback(
        (newly: boolean, section: WelcomeSection) => {
            if (newly) {
                section.exibitionName = site.title;
            }
            return section;
        },
        [site]
    );

    const { section, setSection, saveSection, hasUnsavedChanges } = useSection(
        'welcome',
        setDefaultValues
    );

    const [isUploadingLogo, setUploadingLogo] = useState(false);

    const [isShowModalConfirmRemoveLogo, setIsShowModalConfirmRemoveLogo] =
        useState(false);

    const isSaveDisabled = !!section.buttonTitle && !section.buttonLink;

    const saveHandler = () => {
        saveSection({
            ...section,
            buttonLink: sanitizeUrl(section.buttonLink),
        });
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.welcome.header.title')}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Form>
                <Box>
                    <UploadImageSection>
                        <UploadImagemButtonSection>
                            <Fonts.Label>
                                {t(
                                    'pages.editor.welcome.form.addImage.buttonLabel'
                                )}
                            </Fonts.Label>
                            <div>
                                <UploadImageInput
                                    accept="image/*"
                                    siteId={editingSiteId as string}
                                    crop={avatarCropOptions}
                                    onUpload={(logo) => {
                                        setSection({ ...section, logo });
                                        setUploadingLogo(false);
                                    }}
                                >
                                    <Button
                                        type="NEUTRAL"
                                        size="SM"
                                        uppercase
                                        disabled={isUploadingLogo}
                                    >
                                        {isUploadingLogo
                                            ? t(
                                                  'pages.editor.welcome.form.addImage.buttonTitleUploading'
                                              )
                                            : section.logo
                                              ? t(
                                                    'pages.editor.welcome.form.addImage.buttonTitleUpdate'
                                                )
                                              : t(
                                                    'pages.editor.welcome.form.addImage.buttonTitle'
                                                )}
                                    </Button>
                                </UploadImageInput>
                                {section.logo && !isUploadingLogo && (
                                    <SmallCircleContainer
                                        $color="var(--color-neutral-base)"
                                        cursor="pointer"
                                        onClick={() =>
                                            setIsShowModalConfirmRemoveLogo(
                                                true
                                            )
                                        }
                                    >
                                        <TrashIcon
                                            color="var(--color-neutral-white)"
                                            size={16}
                                        />
                                    </SmallCircleContainer>
                                )}
                            </div>
                        </UploadImagemButtonSection>
                        <UploadImageInput
                            accept="image/*"
                            siteId={editingSiteId as string}
                            crop={avatarCropOptions}
                            onUpload={(logo) => {
                                setSection({ ...section, logo });
                                setUploadingLogo(false);
                            }}
                        >
                            {!section.logo ? (
                                <CircleAddImage
                                    title={t(
                                        'pages.editor.welcome.form.addImage.alt'
                                    )}
                                >
                                    <PlusIcon
                                        width={24}
                                        height={24}
                                        color="var(--color-neutral-light)"
                                    />
                                </CircleAddImage>
                            ) : (
                                <LogoPreviewSection url={section.logo} />
                            )}
                        </UploadImageInput>
                    </UploadImageSection>
                </Box>
                <Box borderBottom className="npt">
                    <FormGroup>
                        <Input
                            id="buttonTitle"
                            type="text"
                            value={section.exibitionName}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    exibitionName: e.target.value,
                                })
                            }
                            maxLength={buttonTitleMaxLenght}
                            label={t('pages.editor.welcome.form.exibitionName')}
                        />
                    </FormGroup>
                </Box>
                <Box borderBottom>
                    <FormGroup>
                        <TextArea
                            id="featuredTitle"
                            name="featuredTitle"
                            autoComplete="off"
                            rows={5}
                            value={section.featuredTitle}
                            // @ts-ignore
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    featuredTitle: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="featuredTitle"
                            text={t('pages.editor.welcome.form.featuredTitle')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextArea
                            id="supportingText"
                            name="supportingText"
                            autoComplete="off"
                            rows={5}
                            value={section.supportingText}
                            //@ts-ignore
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    supportingText: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="supportingText"
                            text={t('pages.editor.welcome.form.supportingText')}
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <FormGroup>
                        <Input
                            id="buttonTitle"
                            type="text"
                            value={section.buttonTitle}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    buttonTitle: e.target.value,
                                })
                            }
                            maxLength={buttonTitleMaxLenght}
                            label={t('pages.editor.welcome.form.buttonTitle')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            autoCapitalize="none"
                            id="buttonLink"
                            type="text"
                            value={section.buttonLink}
                            required={!!section.buttonTitle?.length}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    buttonLink: e.target.value,
                                })
                            }
                            label={t('pages.editor.welcome.form.buttonLink')}
                        />
                    </FormGroup>
                </Box>
                {isShowModalConfirmRemoveLogo && (
                    <ConfirmModal
                        hideExitButton
                        type="DANGER"
                        title={t(
                            'pages.editor.welcome.actions.removeLogo.confirmModal.title'
                        )}
                        confirmButtonTitle={t(
                            'pages.editor.welcome.actions.removeLogo.confirmModal.confirmButton'
                        )}
                        cancelTitle={t(
                            'pages.editor.welcome.actions.removeLogo.confirmModal.cancelButton'
                        )}
                        onExit={() => setIsShowModalConfirmRemoveLogo(false)}
                        onConfirm={() => {
                            setSection({ ...section, logo: undefined });
                            setIsShowModalConfirmRemoveLogo(false);
                        }}
                        onCancel={() => setIsShowModalConfirmRemoveLogo(false)}
                    />
                )}
                <SaveBar
                    onSave={saveHandler}
                    disabled={isSaveDisabled}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
        </Container>
    );
};

export default WelcomePage;
