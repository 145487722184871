import styled from 'styled-components';

import Modal from '../../../../../../../components/Modal';
import {
    Container as ModalContainer,
    Body as ModalBody,
} from '../../../../../../../components/Modal/styles';

export const ModalColor = styled(Modal)`
    ${ModalContainer} {
        max-width: min(80vw, 375px);
    }
    ${ModalBody} {
        padding-top: 16px;
        width: 100%;
    }
`;

export const ModalColorBody = styled.div`
    margin-bottom: 16px;
    .react-colorful {
        width: auto;
        height: auto;
    }
    .react-colorful__saturation {
        height: 330px;
        border-bottom: 0;
        border-radius: 0;
        border: none;
    }
    .react-colorful__last-control {
        border-radius: 10px;
        height: 8px;
        margin: 16px;
    }
    .react-colorful__saturation-pointer {
        width: 18px;
        height: 18px;
        border-width: 4px;
    }
    .react-colorful__hue-pointer {
        width: 16px;
        height: 16px;
        border-width: 4px;
    }
`;

export const ModalColorFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
