import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Fonts from '../../../../../../components/Fonts';
import { Theme } from '../../../../../../model/theme';
import InputColor from '../PreDefinedColorSection/components/InputColor';
import { SelectColorModal } from './SelectColorModal';
import { Container, InputColorsSection } from './styles';

type Props = {
    activeTheme: Theme;
    onUpdateTheme: (theme: Theme) => void;
    customTheme: Theme;
};

export const CustomColorSection = ({
    activeTheme,
    onUpdateTheme,
    customTheme,
}: Props) => {
    const { t } = useTranslation();
    const [openModalColor, setOpenModalColor] =
        useState<keyof Omit<Theme, 'editorPrimary'>>();

    const isCustomColorActive = activeTheme.id === 'custom';

    const selectModalColorHandler = (color: string) => {
        onUpdateTheme({
            ...activeTheme,
            [openModalColor as keyof Theme]: color,
        });
        setOpenModalColor(undefined);
    };

    return (
        <>
            <Container $active={isCustomColorActive}>
                <Fonts.Label center>
                    {t(
                        'pages.editor.settings.form.advancedCustomization.title'
                    )}
                </Fonts.Label>
                <InputColorsSection>
                    <div className="input-group">
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.background.label'
                            )}
                            onClick={() => setOpenModalColor('primary')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.primary
                                    : customTheme.primary
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.foreground.label'
                            )}
                            onClick={() => setOpenModalColor('secondary')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.secondary
                                    : customTheme.secondary
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                    </div>
                    <div className="input-group">
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.buttonsColor.label'
                            )}
                            onClick={() => setOpenModalColor('buttons')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.buttons
                                    : customTheme.buttons
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.textButtons.label'
                            )}
                            onClick={() => setOpenModalColor('textButtons')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.textButtons
                                    : customTheme.textButtons
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                    </div>
                    <div className="input-group">
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.cardColor.label'
                            )}
                            onClick={() => setOpenModalColor('cardColor')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.cardColor
                                    : customTheme.cardColor
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.cardText.label'
                            )}
                            onClick={() => setOpenModalColor('cardText')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.cardText
                                    : customTheme.cardText
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                    </div>
                    <div className="input-group">
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.iconsBackground.label'
                            )}
                            onClick={() => setOpenModalColor('iconsBackground')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.iconsBackground
                                    : customTheme.iconsBackground
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                        <InputColor
                            label={t(
                                'pages.editor.settings.form.advancedCustomization.inputs.iconsColor.label'
                            )}
                            onClick={() => setOpenModalColor('iconsColor')}
                            color={
                                isCustomColorActive
                                    ? activeTheme.iconsColor
                                    : customTheme.iconsColor
                            }
                            active={isCustomColorActive}
                            onlyPreview
                        />
                    </div>
                </InputColorsSection>
            </Container>
            {openModalColor && (
                <SelectColorModal
                    onExit={() => setOpenModalColor(undefined)}
                    onSave={selectModalColorHandler}
                    color={activeTheme[openModalColor]}
                />
            )}
        </>
    );
};
