import styled, { css } from 'styled-components';

type ContainerProps = {
    $hasImage?: boolean;
    $isLoadingImage?: boolean;
};

export const ContainerWrapper = styled.div`
    position: relative;
    padding-bottom: 75%; // 4:3
`;

export const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 2px dashed var(--color-neutral-base);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .image {
        object-fit: cover;
    }

    > div > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: ${(props) => props.$isLoadingImage && 'none'};
    }

    ${(props) =>
        props.$hasImage &&
        css`
            border: 0;
        `};
`;

export const AddImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > svg {
        margin-bottom: 1.5rem;
    }
`;

export const EditImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 999;
    top: 0;
    right: 0;
    position: absolute;
    margin: 16px;

    > div:first-child {
        margin-bottom: 1.5rem;
    }
`;
