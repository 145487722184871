export const FeedbackIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7831 22.9762C22.4976 23.135 23.135 22.4976 22.9762 21.7831L21.8786 16.8439C22.6123 15.3503 23 13.7022 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C13.7022 23 15.3503 22.6123 16.8439 21.8786L21.7831 22.9762ZM19.9424 16.2373C19.8296 16.4481 19.7961 16.6924 19.848 16.9258L20.6829 20.6829L16.9258 19.848C16.6924 19.7961 16.4481 19.8296 16.2373 19.9424C14.9449 20.6335 13.5 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 13.5 20.6335 14.9449 19.9424 16.2373Z"
                fill="#767B8F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 14C10.1046 14 11 13.1046 11 12C11 10.8954 10.1046 10 9 10C7.89543 10 7 10.8954 7 12C7 13.1046 7.89543 14 9 14Z"
                fill="#767B8F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14Z"
                fill="#767B8F"
            />
        </svg>
    );
};
