import styled from 'styled-components';

import { Box } from '../../../../components/Layout';

export const Container = styled.div``;

type InputMessageProps = {
    type: 'DANGER' | 'SUCCESS';
};

export const InputMessageSection = styled.div<InputMessageProps>`
    height: 16px;
    margin-top: -0.5rem;

    h5 {
        color: ${(props) =>
            props.type === 'DANGER'
                ? 'var(--color-feedback-danger)'
                : 'var(--color-feedback-success)'};
        font-size: 9px;
    }
`;

export const PublishBox = styled(Box)`
    padding-top: 1.5rem;
`;
