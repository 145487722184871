import styled from 'styled-components';

import { Box } from '../../components/Layout';

export const Container = styled.div``;

export const ActiveSection = styled.div`
    margin-bottom: 2rem;
    display: flex;

    > div:last-child {
        margin-top: -12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h6 {
        margin-right: 1rem;
    }
`;

export const FooterBox = styled(Box)`
    padding: 1.5rem 0;
    display: flex;
    > .go-back-button {
        color: var(--color-neutral-white);
    }
`;

export const FooterButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StatisticsContentSubTabs = styled.div`
    display: flex;
`;

export const StatisticsContent = styled.div`
    transform: translateX(100%);
    display: none;
    width: 100%;

    &.show {
        display: block;
        transform: translateX(0);
        animation: changeTabsAnimationFade 1s ease-in-out;
    }
`;

export const StatisticsContentHead = styled.div`
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StatisticsContentBody = styled.div``;

export const StatisticsContentBars = styled.div`
    margin-bottom: 2rem;
`;

export const EmptyStateBox = styled.div`
    margin-top: 62px;
    display: flex;
    align-items: center;
    flex-direction: column;

    > svg {
        padding: 32px;
    }
`;
