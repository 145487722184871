type Props = {
    color: string;
};

const CardIcon = ({ color }: Props) => {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 2H7C6.44772 2 6 2.44772 6 3V13C6 13.5523 6.44772 14 7 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2ZM7 0C5.34315 0 4 1.34315 4 3V13C4 14.6569 5.34315 16 7 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H7ZM0 2C0 1.44772 0.44772 1 1 1C1.55228 1 2 1.44772 2 2V14C2 14.5523 1.55228 15 1 15C0.44772 15 0 14.5523 0 14V2ZM19 1C18.4477 1 18 1.44772 18 2V14C18 14.5523 18.4477 15 19 15C19.5523 15 20 14.5523 20 14V2C20 1.44772 19.5523 1 19 1Z"
                fill={color}
            />
        </svg>
    );
};

export default CardIcon;
