import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppState } from '../../contexts/App';
import { EditPencilIcon } from '../../icons/EditPencil';
import TrashIcon from '../../icons/Trash';
import { ImageIcon } from '../../pages/Editor/pages/Sections/icons/Image';
import { Button } from '../Button';
import { SmallCircleContainer } from '../CircleContainer/styles';
import { CircleSpinner } from '../CircleSpinner';
import ConfirmModal from '../ConfirmModal';
import { UploadImageInput } from '../UploadImageInput';
import {
    AddImageContainer,
    Container,
    ContainerWrapper,
    EditImageContainer,
} from './styles';

type Props = {
    image?: string;
    required?: boolean;
    onUpload: (image?: string) => void;
};

export const UploadImageBox = ({ required, image, onUpload }: Props) => {
    const { t } = useTranslation();
    const siteId = useAppState().appState?.editingSiteId as string;

    const [isLoading, setLoading] = useState(!!image);
    const [isRemoving, setRemoving] = useState(false);

    const onUploadHandler = useCallback(
        (image: string) => {
            onUpload(image);
        },
        [onUpload]
    );

    const onRemoveHandler = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setRemoving(true);
    }, []);

    const confirmRemoveHandler = useCallback(() => {
        onUpload(undefined);
        setRemoving(false);
    }, [onUpload]);

    const onCancelRemoveHandler = useCallback(() => {
        setRemoving(false);
    }, []);

    return (
        <>
            <ContainerWrapper>
                <UploadImageInput
                    onUpload={onUploadHandler}
                    siteId={siteId}
                    accept="image/*, image/jpeg, image/png"
                    crop={{
                        slice: {
                            aspect: 4 / 3,
                        },
                        size: {
                            width: 1200,
                            height: 900,
                        },
                        cropShape: 'rect',
                    }}
                >
                    <Container $hasImage={!!image} $isLoadingImage={isLoading}>
                        {image && (
                            <div>
                                <img
                                    className="image"
                                    src={image}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        )}
                        {isLoading && <CircleSpinner size="40px" />}
                        {image && !isLoading && (
                            <EditImageContainer>
                                <SmallCircleContainer
                                    className="trashContainer"
                                    onClick={onRemoveHandler}
                                    $color="var(--color-neutral-base)"
                                    cursor="pointer"
                                >
                                    <TrashIcon
                                        size={16}
                                        color="var(--color-neutral-white)"
                                    />
                                </SmallCircleContainer>
                                <SmallCircleContainer
                                    className="trashContainer"
                                    $color="var(--color-neutral-base)"
                                    cursor="pointer"
                                >
                                    <EditPencilIcon />
                                </SmallCircleContainer>
                            </EditImageContainer>
                        )}
                        {!image && !isLoading && (
                            <AddImageContainer>
                                <ImageIcon
                                    color="var(--color-neutral-base)"
                                    height={68}
                                    width={68}
                                />
                                <Button type="NEUTRAL" size="SM" uppercase>
                                    {t('actions.loadImage.button.title')}
                                    {required && ' *'}
                                </Button>
                            </AddImageContainer>
                        )}
                    </Container>
                </UploadImageInput>
            </ContainerWrapper>

            {isRemoving && (
                <ConfirmModal
                    type="DANGER"
                    title={t('actions.loadImage.remove.confirmModal.title')}
                    cancelTitle={t(
                        'actions.loadImage.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'actions.loadImage.remove.confirmModal.confirmButton'
                    )}
                    onCancel={onCancelRemoveHandler}
                    onConfirm={confirmRemoveHandler}
                />
            )}
        </>
    );
};
