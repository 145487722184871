import { memo } from 'react';

import { RadioInput } from '../../../../components/RadioInput';
import { classNames } from '../../../../utils/styles';
import { Container } from './styles';

type SelectionProps = {
    title: string;
    active: boolean;
    onClick: () => void;
    rightChildren?: React.ReactNode;
    tabIndex: number;
};

export const ItemSelection = memo(function ItemSelection({
    title,
    active,
    onClick,
    rightChildren,
    tabIndex,
}: SelectionProps) {
    return (
        <Container>
            <div
                tabIndex={tabIndex}
                className={classNames('selection', { active })}
                onClick={onClick}
            >
                <div>
                    <RadioInput active={active} />
                    <h6>{title}</h6>
                </div>
                {rightChildren && rightChildren}
            </div>
        </Container>
    );
});
