import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../../../../../components/Button';
import { MediumCircleContainer } from '../../../../../../components/CircleContainer/styles';
import ConfirmModal from '../../../../../../components/ConfirmModal';
import FixedFooterBar from '../../../../../../components/FixedFooterBar';
import { SectionHelp } from '../../../../../../components/SectionHelp';
import { AppState, useAppState } from '../../../../../../contexts/App';
import { useSite } from '../../../../../../contexts/Site';
import PlusIcon from '../../../../../../icons/Plus';
import { Section, Site } from '../../../../../../model/site';
import { api } from '../../../../../../services/api';
import { AddSectionModal } from '../AddSectionModal';
import CardItem from './components/Card';
import {
    CardItemWrapper,
    Container,
    ContinueButtonSection,
    SectionsBox,
} from './styles';

const SectionsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();
    const { site, setSite } = useSite();

    const copySite = { ...site } as Site;
    const sections = copySite.sections as Section[];

    const [showAddSectionModal, setShowAddSectionModal] = useState(false);
    const [sectionIdRemoving, setSectionIdRemoving] = useState<string>();

    const isContinueButtonAvailable =
        sections.filter(({ active }) => active).length > 0;

    const onActiveClick = (sectionId: string, active: boolean) => {
        sections.find(({ id }) => id === sectionId)!.active = active;
        updateSiteState();
    };

    const onRemoveClick = (sectionId: string) => {
        setSectionIdRemoving(sectionId);
    };

    const removeSection = () => {
        const index = sections.findIndex(({ id }) => id === sectionIdRemoving);
        sections.splice(index, 1);

        updateSiteState();
        setSectionIdRemoving(undefined);
    };

    const updateSiteState = () => {
        setSite(copySite);

        const copyAppState = {
            ...appState,
            editingSiteModified: true,
        } as AppState;

        api.saveDraft(copyAppState.editingSiteId as string, copySite);
        setAppState(copyAppState);
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const itemsClone = Array.from(sections);
        const [reorderedItem] = itemsClone.splice(result.source.index, 1);
        itemsClone.splice(result.destination.index, 0, reorderedItem);

        copySite.sections = itemsClone;

        updateSiteState();
    };

    const goToPreview = () => {
        if (isContinueButtonAvailable) {
            navigate('/editor/preview');
        }
    };

    const getSectionPath = (type: string, id: string, subType?: string) => {
        const path = subType ? `/editor/${type}/${subType}` : `/editor/${type}`;
        return `${path}?id=${id}`;
    };

    return (
        <Container>
            <SectionHelp message={t('pages.editor.sections.help.message')} />
            <SectionsBox>
                {/* @ts-ignore */}
                <DragDropContext onDragEnd={handleDragEnd}>
                    {/* @ts-ignore */}
                    <Droppable droppableId="sections" direction="vertical">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {sections.map(
                                    ({ id, active, type, subType }, index) => (
                                        // @ts-ignore
                                        <Draggable
                                            key={id}
                                            index={index}
                                            draggableId={id}
                                        >
                                            {(provided) => (
                                                <CardItemWrapper
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <CardItem
                                                        key={id}
                                                        sectionType={type}
                                                        sectionSubtype={subType}
                                                        active={active}
                                                        onClick={() =>
                                                            navigate(
                                                                getSectionPath(
                                                                    type,
                                                                    id,
                                                                    subType
                                                                )
                                                            )
                                                        }
                                                        onActiveClick={(
                                                            active
                                                        ) =>
                                                            onActiveClick(
                                                                id,
                                                                active
                                                            )
                                                        }
                                                        onRemoveClick={() =>
                                                            onRemoveClick(id)
                                                        }
                                                    />
                                                </CardItemWrapper>
                                            )}
                                        </Draggable>
                                    )
                                )}
                                {provided.placeholder as React.ReactNode}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </SectionsBox>
            {sectionIdRemoving && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.sections.actions.remove.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.sections.actions.remove.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.sections.actions.remove.confirmModal.confirmButton'
                    )}
                    onCancel={() => setSectionIdRemoving(undefined)}
                    onConfirm={removeSection}
                />
            )}
            {showAddSectionModal && (
                <AddSectionModal
                    onClose={() => setShowAddSectionModal(false)}
                />
            )}
            <FixedFooterBar hideLine>
                <div
                    className="add-new-section"
                    onClick={() => setShowAddSectionModal(true)}
                >
                    <MediumCircleContainer
                        title={t(
                            'pages.editor.sections.actions.add.button.title'
                        )}
                        $color="var(--color-brand-blue)"
                    >
                        <PlusIcon
                            width={16}
                            height={16}
                            color="var(--color-neutral-white)"
                        />
                    </MediumCircleContainer>
                    <h6>{t('pages.editor.sections.actions.section.text')}</h6>
                </div>
                <ContinueButtonSection>
                    <Button
                        type="INFO"
                        size="LG"
                        disabled={!isContinueButtonAvailable}
                        onClick={() => goToPreview()}
                    >
                        {t(
                            'pages.editor.sections.actions.continue.button.title'
                        )}
                    </Button>
                </ContinueButtonSection>
            </FixedFooterBar>
        </Container>
    );
};

export default SectionsList;
