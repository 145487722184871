import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import { LoadingModal } from '../../../../components/LoadingModal';
import { SectionHelp } from '../../../../components/SectionHelp';
import {
    api,
    ApiSiteResponse,
    SubscriptionDetail,
} from '../../../../services/api';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { classNames } from '../../../../utils/styles';
import { Container } from './styles';

type keepoProps = {
    title: string;
    siteId: string;
    subscriptionDetail: SubscriptionDetail;
};

const isPtLang = (language: string) => language.toUpperCase().includes('PT');

const Keepo = ({ title, siteId, subscriptionDetail }: keepoProps) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const isCanceled = !!subscriptionDetail.canceledAt;

    const lastEventDescription = isCanceled
        ? t('pages.subscriptionList.table.canceled.description')
        : subscriptionDetail.invoices.length === 1
          ? t('pages.subscriptionList.table.created.description')
          : t('pages.subscriptionList.table.renewed.description');

    const lastEventDate =
        subscriptionDetail.canceledAt ||
        subscriptionDetail.invoices[subscriptionDetail.invoices.length - 1]
            .createdAt;

    return (
        <div
            className="keepo"
            onClick={() => navigate(`/subscription/detail?site=${siteId}`)}
        >
            <div>
                <div>
                    <h6>{title}</h6>
                </div>
                <div>
                    <p>
                        {lastEventDescription}{' '}
                        {capitalizeFirstLetter(
                            dayjs(lastEventDate)
                                .locale(
                                    isPtLang(i18n.language) ? 'pt-br' : 'en-us'
                                )
                                .format('MMMM YYYY')
                        )}
                    </p>
                </div>
            </div>
            <div>
                <div
                    className={classNames('status', {
                        canceled: isCanceled,
                    })}
                />
            </div>
        </div>
    );
};

export const SubscriptionListPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loadingSites, setLoadingSites] = useState(true);
    const [sites, setSites] = useState<ApiSiteResponse[]>([]);

    const [subscriptionDetails, setSubscriptionDetails] =
        useState<SubscriptionDetail[]>();

    const loadSites = useCallback(async () => {
        const sites = await api.getSites();
        const premiumSites = sites.filter((site) => !!site.subscription);
        if (!premiumSites.length) {
            navigate('/sites');
            return;
        }
        setSubscriptionDetails(
            await Promise.all(
                premiumSites.map((site) =>
                    api.getSubscriptionDetail(site.siteId)
                )
            )
        );

        setSites(premiumSites);
        setLoadingSites(false);
    }, [navigate]);

    useEffect(() => {
        loadSites();
    }, [loadSites]);

    return loadingSites ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.subscriptionList.header.title')} />
            <SectionHelp
                bordeBottom={false}
                message={t('pages.subscriptionList.header.description')}
            />
            <Box className="npt">
                <div className="keepos">
                    {sites.map((site) => (
                        <Keepo
                            siteId={site.siteId}
                            key={site.siteId}
                            title={
                                site.site
                                    ? site.site.title
                                    : site.draft.title || '-'
                            }
                            subscriptionDetail={
                                subscriptionDetails?.find(
                                    ({ siteId }) => siteId === site.siteId
                                ) as SubscriptionDetail
                            }
                        />
                    ))}
                </div>
            </Box>
        </Container>
    );
};
