import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Header from '../../../../components/Header';
import { LoadingModal } from '../../../../components/LoadingModal';
import { useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import { Site } from '../../../../model/site';
import { api } from '../../../../services/api';
import { AddSectionModal } from './components/AddSectionModal';
import { EmptyState } from './components/EmptyState';
import SectionsList from './components/SectionsList';
import { navigationState } from './navigationState';
import { Container } from './styles';

const SectionsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState } = useAppState();
    const { site } = useSite();
    const copySite = { ...site } as Site;
    const sections = copySite.sections;

    const [removingDraft, setRemovingDraft] = useState(false);

    const [showAddSectionModal, setShowAddSectionModal] = useState(false);

    const exitMessages = useMemo(() => {
        const isNonPublishedPlus =
            !appState.published &&
            appState.editingSiteSubscription?.type === 'PLUS';
        return {
            title: isNonPublishedPlus
                ? t(
                      'pages.editor.sections.actions.exit.unsavedChanges.notRemove.modal.title'
                  )
                : t(
                      'pages.editor.sections.actions.exit.unsavedChanges.canRemove.modal.title'
                  ),
            confirmButton: isNonPublishedPlus
                ? t(
                      'pages.editor.sections.actions.exit.unsavedChanges.notRemove.modal.confirmButton'
                  )
                : undefined,
            cancelButton: isNonPublishedPlus
                ? t(
                      'pages.editor.sections.actions.exit.unsavedChanges.notRemove.modal.cancelButton'
                  )
                : undefined,
        };
    }, [appState, t]);

    useEffect(() => {
        navigationState.reset();
    });

    const deleteDraftAndBack = async () => {
        setRemovingDraft(true);

        try {
            if (appState.editingSiteSubscription?.type !== 'PLUS') {
                await api.deleteDraf(appState?.editingSiteId as string);
            }
            navigate('/sites', { replace: true });
        } catch (e) {
            setRemovingDraft(false);
        }
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.sections.header.title')}
                hasUnsavedChanges={appState?.editingSiteModified}
                onConfirmExitUnsavedChanges={deleteDraftAndBack}
                isConfirmExitUnsavedChanges={removingDraft}
                titleConfirmExitModal={exitMessages.title}
                buttonTitleConfirmExitModal={exitMessages.confirmButton}
                cancelTitleConfirmExitModal={exitMessages.cancelButton}
            />
            {sections.length === 0 ? (
                <EmptyState onClick={() => setShowAddSectionModal(true)} />
            ) : (
                <SectionsList />
            )}
            {showAddSectionModal && (
                <AddSectionModal
                    onClose={() => setShowAddSectionModal(false)}
                />
            )}

            {removingDraft && <LoadingModal />}
        </Container>
    );
};

export default SectionsPage;
