export default {
    aws_project_region: 'sa-east-1',
    aws_cognito_region: 'sa-east-1',
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_USER_POOL_DOMAIN,
        scope: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
        responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
};
