import { useTranslation } from 'react-i18next';

import { TrackingPage } from '../../components/TrackingPage';

export const GoogleAnalytics = () => {
    const { t } = useTranslation();
    return (
        <TrackingPage
            image="/images/ga.png"
            providerName="googleAnalytics"
            title="Google Analytics"
            helpDescription={t(
                'pages.settings.components.advancedConfigs.tracking.googleAnalytics.help'
            )}
        />
    );
};
