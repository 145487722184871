import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

import { api } from '../../services/api';
import debounce from '../../utils/debounce';

const isUrlSyntaxValid = (url: string) => {
    return !!url && url.match(/^\s*[a-z\d-_]{3,255}\s*$/i) !== null;
};

type ReturnType = {
    url: string;
    onChangeUrl: (url: string) => void;
    loadingUrl: boolean;
    urlNotAvailable: boolean;
    urlReady: boolean;
};

let checkPromise = Promise.resolve();

const useCheckUrl = (siteId: string, initialUrl: string): ReturnType => {
    const [url, setUrl] = useState<string>(initialUrl);
    const [loadingUrl, setLoadingUrl] = useState(false);
    const [urlNotAvailable, setUrlNotAvailable] = useState(false);

     
    const debouncedCheckUrlOnServer = useCallback(debounce(checkUrl, 500), []);

    const onChangeUrl = (newUrl: string) => {
        setUrl(newUrl);
        setUrlNotAvailable(false);
        setLoadingUrl(true);
        debouncedCheckUrlOnServer(newUrl);
    };

    function checkUrl(url: string) {
        checkPromise = checkPromise
            .then(() => {
                if (!url) {
                    setUrlNotAvailable(true);
                    return Promise.resolve();
                }

                const syntaxValid = isUrlSyntaxValid(url);

                if (!syntaxValid) {
                    setUrlNotAvailable(true);
                    return Promise.resolve();
                }

                return api
                    .checkUrl(siteId, url)
                    .then(() => {
                        setUrlNotAvailable(false);
                    })
                    .catch((e: AxiosError) => {
                        const data = e.response?.data as { error: string };
                        if (data.error === 'URL_NOT_AVAILABLE') {
                            setUrlNotAvailable(true);
                        }
                    });
            })
            .finally(() => setLoadingUrl(false));
    }

    return {
        url,
        onChangeUrl,
        loadingUrl,
        urlNotAvailable,
        urlReady: !!url && !loadingUrl && !urlNotAvailable,
    };
};

export default useCheckUrl;
