import React, { useEffect } from 'react';

import { useKeyPress } from '../../hooks/KeyPress';
import CloseIcon from '../../icons/Close';
import { stopScroll } from '../../utils/modal';
import { MicroCircleContainer } from '../CircleContainer/styles';
import { Background, Body, Container, Header, Title } from './styles';

type Props = {
    title?: string;
    outsideExit?: boolean;
    onExit?: () => void;
    children: React.ReactNode;
    hideExitButton?: boolean;
    className?: string;
};

const Modal = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { title, outsideExit, onExit, children, hideExitButton, className } =
        props;

    useKeyPress('Escape', () => {
        if (outsideExit && onExit) {
            onExit();
        }
    });

    useEffect(() => {
        return stopScroll();
    });

    return (
        <Background
            onClick={() => outsideExit && onExit && onExit()}
            className={className}
        >
            <Container ref={ref} className="modal-container">
                <Header $hasTitle={!!title}>
                    {title && <Title>{title}</Title>}
                    {!hideExitButton && (
                        <MicroCircleContainer
                            $color="var(--color-neutral-base)"
                            cursor="pointer"
                            onClick={() => onExit && onExit()}
                        >
                            <CloseIcon width={10} height={10} />
                        </MicroCircleContainer>
                    )}
                </Header>
                {/* @ts-ignore */}
                <Body onClick={(e) => e.stopPropagation()}>{children}</Body>
            </Container>
        </Background>
    );
});

Modal.displayName = 'Modal';

export default Modal;
