import styled from 'styled-components';

export const Container = styled.div``;

export const HelpMessageSection = styled.div`
    text-align: center;
`;

export const FieldsLabelSection = styled.div`
    margin: 0rem 2rem;
`;

export const FieldsSection = styled.div`
    display: flex;
    flex-direction: column;
`;

type FieldRowProps = {
    active: boolean;
};

export const FieldRow = styled.div<FieldRowProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 1.5rem;

    color: ${(props) => !props.active && 'var(--color-neutral-light)'};

    margin-top: 1rem;

    :first-child {
        margin-top: 0;
    }

    > div:last-child {
        margin-top: -12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const IntegrationItemsSection = styled.div`
    margin-top: 1rem;
    > div {
        margin-top: 0.5rem;
    }

    > div:first-child {
        margin-top: 0;
    }
`;
