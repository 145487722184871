import { useState } from 'react';

import { CircleSpinner } from '../../../../../../../../../components/CircleSpinner';
import { Container } from './styles';

type Props = {
    image?: string;
};

export const Image = ({ image }: Props) => {
    const [isLoading, setLoading] = useState(!!image);
    return (
        <Container $loading={isLoading}>
            {isLoading && <CircleSpinner size="40px" />}
            <img onLoad={() => setLoading(false)} src={image} />
        </Container>
    );
};
