import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #262a34;
    border-radius: 1rem;
    padding: 1rem;
`;
