import styled from 'styled-components';

export const Container = styled.div`
    .header {
        margin: 2rem 2rem 1rem 2rem;
        display: flex;
        flex-direction: row-reverse;
    }

    .banner {
        margin: 40px 0;
        display: flex;
        justify-content: center;
    }

    .message {
        margin: 0rem 2rem 2rem 2rem;
        text-align: center;

        > h2 {
            margin-bottom: 1rem;

            > span {
                color: var(--color-lemon);
            }
        }

        > p {
            > strong {
                font-weight: bold;
            }
        }
    }

    @media (max-width: 320px) {
        .banner {
            margin-top: 0px;
        }
        .message {
            margin-bottom: 0px;
        }
        .header {
            margin-bottom: 0px;
        }
    }

    .transactions {
        .title {
            margin-bottom: 0.5rem;
        }
        .transaction {
            width: 100%;
            display: flex;
            justify-content: space-between;

            border-top: 1px solid var(--color-neutral-base);
            padding: 0.5rem 0;

            .success {
                color: var(--color-lemon);
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }
`;
