import styled from 'styled-components';

import * as ModalStyles from '../../../../../../components/Modal/styles';

export const Background = styled(ModalStyles.Background)``;

export const Body = styled.div`
    background-color: var(--color-neutral-white);
    color: var(--color-neutral-dark);

    width: min(334px, 90%);
    position: relative;
    border-radius: 1rem;

    > .container {
        position: relative;

        display: flex;
        flex-direction: column;

        &.pro {
            height: min(512px, 80vh);
        }
    }

    .bottom-box {
        opacity: 0.8;
        width: 80%;
        height: 80px;
        position: absolute;
        bottom: 0;
        border-radius: 0rem 0rem 1rem 1rem;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 100%
        );
    }

    .hide {
        opacity: 0;
        z-index: -10;
        transition:
            opacity 0.6s ease,
            width 0.6s ease,
            z-index 0.6s;
    }

    .header {
        padding: 1rem;
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > h6 {
            margin-left: 8px;
        }
    }

    .sections {
        overflow: auto;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        .sections-group {
            padding: 1rem 0rem 1rem 1.5rem;
            border-bottom: 1px solid #f2f1f1;

            &.spt {
                padding-top: 0.5rem;
            }
            &.spt {
                border-bottom: 0;
            }
            &.spb {
                padding-bottom: 0.5rem;
            }
            &.no-border {
                border-color: transparent;
            }
            .section {
                opacity: 0.2;
                &.active {
                    cursor: pointer;
                    opacity: 1;
                }
                padding: 0.5rem 0;
                display: flex;
                align-items: center;
                > h6 {
                    margin-left: 1rem;
                }
            }
        }
    }
`;
