import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeyPress } from '../../../../../../../../../hooks/KeyPress';
import { stopScroll } from '../../../../../../../../../utils/modal';
import { Modal } from './styles';

type Props = {
    onSelect: (data: IEmojiData) => void;
    onCancel: () => void;
};

const i18nNamespace = `pages.editor.links.item.form.thumbnail.emojiPicker`;

export const EmojiModal = ({ onSelect, onCancel }: Props) => {
    const { t } = useTranslation();
    const groupNames = useMemo(
        () => ({
            smileys_people: t(`${i18nNamespace}.GROUP_NAME_PEOPLE`),
            animals_nature: t(`${i18nNamespace}.GROUP_NAME_NATURE`),
            food_drink: t(`${i18nNamespace}.GROUP_NAME_FOOD`),
            travel_places: t(`${i18nNamespace}.GROUP_NAME_TRAVEL`),
            activities: t(`${i18nNamespace}.GROUP_NAME_ACTIVITIES`),
            objects: t(`${i18nNamespace}.GROUP_NAME_OBJECTS`),
            symbols: t(`${i18nNamespace}.GROUP_NAME_SYMBOLS`),
            flags: t(`${i18nNamespace}.GROUP_NAME_FLAGS`),
            recently_used: t(`${i18nNamespace}.GROUP_NAME_RECENTLY_USED`),
        }),
        [t]
    );
    useKeyPress('Escape', () => {
        onCancel();
    });
    useEffect(() => {
        return stopScroll();
    });
    return (
        <Modal onClick={() => onCancel()}>
            <div onClick={(e) => e.stopPropagation()}>
                <EmojiPicker
                    disableAutoFocus
                    onEmojiClick={(_, data) => onSelect(data)}
                    native
                    pickerStyle={{ boxShadow: 'none' }}
                    groupNames={groupNames}
                />
            </div>
        </Modal>
    );
};
