import { Navigate } from 'react-router';
import { TailwindReset } from '../tailwindReset';
import { Container } from '../Container';

interface PrivateRouteProps {
    authenticated: boolean;
    children: JSX.Element;
    tailwind?: boolean;
}

export function ProtectedRoute({
    authenticated = false,
    tailwind = false,
    children,
}: PrivateRouteProps) {
    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }
    if (tailwind) {
        return <TailwindReset>{children}</TailwindReset>;
    }
    return <Container>{children}</Container>;
}
