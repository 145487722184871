import React from 'react';

interface IconProps extends React.ComponentProps<'svg'> {}

const GiftIcon = ({ ...rest }: IconProps) => {
    return (
        <svg
            {...rest}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 5V21M11 5H7.46429C6.94332 5 6.4437 4.78929 6.07533 4.41421C5.70695 4.03914 5.5 3.53043 5.5 3C5.5 2.46957 5.70695 1.96086 6.07533 1.58579C6.4437 1.21071 6.94332 1 7.46429 1C10.2143 1 11 5 11 5ZM11 5H14.5357C15.0567 5 15.5563 4.78929 15.9247 4.41421C16.293 4.03914 16.5 3.53043 16.5 3C16.5 2.46957 16.293 1.96086 15.9247 1.58579C15.5563 1.21071 15.0567 1 14.5357 1C11.7857 1 11 5 11 5ZM19 10V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21L6.2 21C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V10M1 6.6L1 8.4C1 8.96005 1 9.24008 1.10899 9.45399C1.20487 9.64215 1.35785 9.79513 1.54601 9.89101C1.75992 10 2.03995 10 2.6 10L19.4 10C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V6.6C21 6.03995 21 5.75992 20.891 5.54601C20.7951 5.35785 20.6422 5.20487 20.454 5.10899C20.2401 5 19.9601 5 19.4 5L2.6 5C2.03995 5 1.75992 5 1.54601 5.10899C1.35785 5.20487 1.20487 5.35785 1.10899 5.54601C1 5.75992 1 6.03995 1 6.6Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GiftIcon;
