import { ButtonSection } from '@keepoinc/site-page-react-component/dist/cjs/site';
import { useTranslation } from 'react-i18next';

import Input from '../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { sanitizeUrl } from '../../../../utils/url';
import { useSection } from '../../hooks/Section';
import { Container } from './styles';

export const ButtonPage = () => {
    const { t } = useTranslation();

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<ButtonSection>('button');

    const isSaveDisabled = !section.link || !section.text;

    const saveHandler = () => {
        saveSection({
            ...section,
            link: sanitizeUrl(section.link) as string,
        });
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.button.header.title')}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Form>
                <Box>
                    <FormGroup>
                        <Input
                            id="buttonTitle"
                            type="text"
                            value={section.text}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    text: e.target.value,
                                })
                            }
                            required
                            maxLength={65}
                            label={t('pages.editor.button.form.buttonTitle')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            autoCapitalize="none"
                            id="buttonLink"
                            type="text"
                            value={section.link}
                            required
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    link: e.target.value,
                                })
                            }
                            label={t('pages.editor.button.form.buttonLink')}
                        />
                    </FormGroup>
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    disabled={isSaveDisabled}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
        </Container>
    );
};
