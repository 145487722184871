import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '../../../../../../components/ConfirmModal';
import { Form } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import { LoadingModal } from '../../../../../../components/LoadingModal';
import SaveBar from '../../../../../../components/SaveBar';
import constants from '../../../../../../constants';
import { useAppState } from '../../../../../../contexts/App';
import { MailChimpStorage } from '../../../../../../model/site';
import { api, MailChimpAudienceList } from '../../../../../../services/api';
import { navigationState } from '../../../Sections/navigationState';
import { IntegrationConnectionButton } from '../../components/IntegrationConnectionButton';
import StorageIntegrationServiceInfo from '../../components/StorageIntegrationServiceInfo';
import { FormNavigationState } from '../../formNavigationState';
import MailChimpIcon from '../../icons/MailChimp';
import { authenticationFlow } from '../../services/authentication-flow';
import { AudienceSelectList } from './components/AudienceSelectList';
import { Container, SelectAudienceListContainer } from './styles';

const getTokenUrl = `https://login.mailchimp.com/oauth2/authorize?\
response_type=code&client_id=${constants.mailChimpClientId}\
&redirect_uri=${constants.site}/callback.html`;

export const MailChimpStoragePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const editingSiteId = useAppState().appState?.editingSiteId as string;

    const [audienceLists, setAudienceLists] = useState<MailChimpAudienceList[]>(
        []
    );
    const [isLoadingAudienceLists, setLoadingAudienceLists] = useState(false);
    const [isCreatingConfiguration, setCreatingConfiguration] = useState(false);
    const [isShowDisconnectConfirmModal, setShowDisconnectConfirmModal] =
        useState(false);

    const formNavigationState = navigationState.get() as FormNavigationState;

    const section = formNavigationState.section;

    const [mailChimp, setMailChimp] = useState({
        ...section.storage.mailChimp,
    });

    const hasUnsavedChanges =
        (mailChimp.active && !section.storage.mailChimp.active) ||
        (!!mailChimp.audienceList &&
            mailChimp.audienceList.id !==
                section.storage?.mailChimp?.audienceList?.id);

    const startConnectFlow = async () => {
        if (isCreatingConfiguration) {
            return;
        }
        const code = await authenticationFlow.getCodeFromModal({
            url: getTokenUrl,
            target: 'mailchimp',
        });
        setCreatingConfiguration(true);

        try {
            const { id } = await api.createMailChimpFormConnection(
                editingSiteId,
                code
            );
            const lists = await loadAudienceLists(id);
            setMailChimp({
                ...mailChimp,
                active: true,
                connectionId: id,
                audienceList: lists[0],
            });
        } finally {
            setCreatingConfiguration(false);
        }
    };

    const disconnect = () => setShowDisconnectConfirmModal(true);

    const confirmDisconnect = () => {
        const mailChimpCopy = { active: false };

        setMailChimp(mailChimpCopy);
        updateState(mailChimpCopy);
        setShowDisconnectConfirmModal(false);
    };

    const updateState = (mailChimp: MailChimpStorage) => {
        const state: FormNavigationState = {
            ...formNavigationState,
            section: {
                ...section,
                storage: {
                    ...section.storage,
                    mailChimp,
                },
            },
        };

        navigationState.set(state);
    };

    const loadAudienceLists = async (connectionId: string) => {
        setLoadingAudienceLists(true);

        const lists = await api.getAudienceListsMailChimpFormConnection(
            editingSiteId,
            connectionId
        );

        setLoadingAudienceLists(false);
        setAudienceLists(lists);

        return lists;
    };

    const saveHandler = () => {
        updateState(mailChimp);
        navigate(-1);
    };

    useEffect(() => {
        if (mailChimp.active) {
            loadAudienceLists(mailChimp.connectionId as string);
        }
        return () => authenticationFlow.reset();
         
    }, []);

    return (
        <>
            {(isLoadingAudienceLists || isCreatingConfiguration) && (
                <LoadingModal />
            )}
            <Container>
                <Header
                    title="Mailchimp"
                    hasUnsavedChanges={hasUnsavedChanges}
                />
                <StorageIntegrationServiceInfo
                    LogoIcon={MailChimpIcon}
                    logoContainerColor="#FFE01B"
                    info={t('pages.editor.form.form.storage.mailChimp.info')}
                />
                <Box borderBottom>
                    {mailChimp.active && (
                        <SelectAudienceListContainer>
                            <AudienceSelectList
                                value={mailChimp.audienceList}
                                isLoadingList={isLoadingAudienceLists}
                                audienceLists={audienceLists}
                                onChange={(audienceList) =>
                                    setMailChimp({ ...mailChimp, audienceList })
                                }
                            />
                        </SelectAudienceListContainer>
                    )}
                    <IntegrationConnectionButton
                        status={mailChimp.active ? 'CONNECTED' : 'DISCONNECTED'}
                        connectTitle={t(
                            'pages.editor.form.form.storage.mailChimp.connectButton'
                        )}
                        onConnect={startConnectFlow}
                        onDisconnect={disconnect}
                    />
                </Box>
                <Form>
                    <SaveBar
                        onSave={saveHandler}
                        disabled={!mailChimp.active}
                        cancelTitle={t('actions.goBack')}
                        hasUnsavedChanges={hasUnsavedChanges}
                    />
                </Form>
            </Container>
            {isShowDisconnectConfirmModal && (
                <ConfirmModal
                    type="DANGER"
                    title={t(
                        'pages.editor.form.form.storage.mailChimp.actions.disconnect.confirmModal.title'
                    )}
                    cancelTitle={t(
                        'pages.editor.form.form.storage.mailChimp.actions.disconnect.confirmModal.cancelButton'
                    )}
                    confirmButtonTitle={t(
                        'pages.editor.form.form.storage.mailChimp.actions.disconnect.confirmModal.confirmButton'
                    )}
                    onConfirm={confirmDisconnect}
                    onCancel={() => setShowDisconnectConfirmModal(false)}
                />
            )}
        </>
    );
};
