import styled from 'styled-components';

import { Box } from '../../../../../../components/Layout';

export const Container = styled.div`
    .add-new-section {
        display: flex;
        align-items: center;
        cursor: pointer;
        > h6 {
            margin-left: 12px;
        }
    }
`;

export const SectionsBox = styled(Box)`
    margin-top: -1.25rem;
    margin-bottom: 7.5rem;
`;

export const CardItemWrapper = styled.div`
    margin-top: 1.25rem;
`;

export const ContinueButtonSection = styled.div`
    width: 50%;
`;
