type Props = {
    width: number;
    height: number;
    color: string;
};
const PlusIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.30769 0.904488C6.25372 0.50664 5.91269 0.199997 5.50004 0.199997C5.04987 0.199997 4.68494 0.564927 4.68494 1.01509V4.18111H1.51505L1.40444 4.18855C1.00659 4.24252 0.699951 4.58355 0.699951 4.9962C0.699951 5.44637 1.06488 5.8113 1.51505 5.8113H4.68494V8.97732L4.69238 9.08792C4.74635 9.48577 5.08738 9.79241 5.50004 9.79241C5.9502 9.79241 6.31513 9.42748 6.31513 8.97732V5.8113H9.48485L9.59546 5.80385C9.99331 5.74988 10.2999 5.40885 10.2999 4.9962C10.2999 4.54604 9.93502 4.18111 9.48485 4.18111H6.31513V1.01509L6.30769 0.904488Z"
                fill={color}
            />
        </svg>
    );
};

export default PlusIcon;
