import { List, Title } from './styles';

type Props = {
    title: string;
    values: {
        value: string;
        count: number;
    }[];
    formatterNumber: (value: number) => string;
};

export const SummaryList = ({ title, values, formatterNumber }: Props) => {
    return values.length ? (
        <div>
            <Title>{title}</Title>
            <List>
                {values.map((item, numberIdx) => (
                    <li key={numberIdx}>
                        {item.value}
                        <span>{formatterNumber(item.count)}</span>
                    </li>
                ))}
            </List>
        </div>
    ) : (
        <></>
    );
};
