import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';

import { Button, LinkButton } from '../../components/Button';
import { Box } from '../../components/Layout';
import constants from '../../constants';
import { useAppState } from '../../contexts/App';
import { Site, createNewSite } from '../../model/site';
import { api } from '../../services/api';
import { classNames } from '../../utils/styles';
import { uuidv4 } from '../../utils/uuid';
import { Step1Icon } from './icons/Step1';
import { Step2Icon } from './icons/Step2';
import { Step3Icon } from './icons/Step3';
import { Step4Icon } from './icons/Step4';
import { Container } from './styles';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

type ContentProps = {
    title: string;
    description: string;
    buttonText?: string;
    pricing?: boolean;
    icon: React.ReactNode;
};

const useContents = (): ContentProps[] => {
    const { t } = useTranslation();

    return useMemo(() => {
        const steps = [
            <Step1Icon key="1" />,
            <Step2Icon key="2" />,
            <Step3Icon key="3" />,
            <Step4Icon key="4" />,
        ];
        return steps.map((icon, index) => ({
            title: t(`pages.onboarding.steps.${index + 1}.title`),
            description: t(`pages.onboarding.steps.${index + 1}.description`),
            pricing: index === steps.length - 1 ? true : false,
            buttonText:
                index === steps.length - 1
                    ? t(`pages.onboarding.steps.${index + 1}.buttonText`)
                    : undefined,
            icon,
        }));
    }, [t]);
};

const Content = memo(function Content({
    title,
    description,
    pricing,
    buttonText,
    icon,
}: ContentProps) {
    const navigate = useNavigate();

    const goPremium = useCallback(() => {
        navigate('/sites', { replace: true });
        navigate('/subscription/features');
    }, [navigate]);

    return (
        <Container>
            <div className="image-section">{icon}</div>
            <div className="text-section">
                <h3>{title}</h3>
                <p
                    dangerouslySetInnerHTML={{
                        __html: description.replace(
                            '${price}',
                            constants.price.month.value
                        ),
                    }}
                />
                {pricing && (
                    <div className="price-detail">
                        <p className="old-price line-through">
                            {constants.price.year.oldValue}
                        </p>
                        <p>{constants.price.year.value}</p>
                    </div>
                )}
            </div>
            {buttonText && (
                <Box>
                    <Button type="OUTLINE" size="LG" onClick={goPremium}>
                        {buttonText}
                    </Button>
                </Box>
            )}
        </Container>
    );
});

type ControlsProps = {
    activeIndex: number;
    onClick: (index: number) => void;
    contents: ContentProps[];
};

const Controls = memo(function Controls({
    activeIndex,
    onClick,
    contents,
}: ControlsProps) {
    return (
        <div className="controls">
            {contents.map((_, index) => (
                <div
                    key={index}
                    onClick={() => onClick(index)}
                    className={classNames('control', {
                        active: index === activeIndex,
                    })}
                />
            ))}
        </div>
    );
});

type OnboardingProps = {
    setSite: (site: Site) => void;
};

export const OnboardingPage = ({ setSite }: OnboardingProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { appState, setAppState } = useAppState();
    const contents = useContents();

    const [contentIndex, setContentIndex] = useState(0);
    const isShowingLastContent = !(contentIndex < contents.length - 1);

    const onChangeContentIndex = useCallback(
        (index: number, indexLatest: number) => {
            if (Math.abs(indexLatest - index) > 1) {
                return;
            }
            setContentIndex(index);
        },
        []
    );

    const goNextPage = useCallback(async () => {
        api.completeOnboarding(); //call and forget, dont care

        setSite(createNewSite());
        setAppState({
            ...appState,
            editingSiteId: uuidv4(),
            editingSiteModified: false,
            published: false,
            editingSiteDisabled: false,
            editingSiteSubscription: { type: 'FREE' },
        });

        navigate('/sites', { replace: true });
        navigate('/editor/title');
    }, [navigate, appState, setAppState, setSite]);

    const continueHandler = useCallback(async () => {
        if (!isShowingLastContent) {
            setContentIndex(contentIndex + 1);
            return;
        }
        goNextPage();
    }, [isShowingLastContent, contentIndex, goNextPage]);

    const onSwitchingHandler = useCallback((index: number, type: string) => {
        if (type === 'end') {
            setContentIndex(index);
        }
    }, []);

    return (
        <Container>
            {/* @ts-ignore */}
            <BindKeyboardSwipeableViews
                enableMouseEvents
                index={contentIndex}
                onChangeIndex={onChangeContentIndex}
                onSwitching={onSwitchingHandler}
            >
                {contents.map(
                    (
                        { title, description, pricing, icon, buttonText },
                        index
                    ) => (
                        <Content
                            key={index}
                            title={title}
                            description={description}
                            pricing={pricing}
                            buttonText={buttonText}
                            icon={icon}
                        />
                    )
                )}
            </BindKeyboardSwipeableViews>
            <Box className="box-controls">
                <Controls
                    activeIndex={contentIndex}
                    onClick={setContentIndex}
                    contents={contents}
                />
            </Box>
            <div className="footer">
                <Box className="box">
                    {isShowingLastContent ? (
                        <div />
                    ) : (
                        <LinkButton className="skip" onClick={goNextPage}>
                            {t('pages.onboarding.actions.skip')}
                        </LinkButton>
                    )}
                    <Button type="INFO" size="LG" onClick={continueHandler}>
                        {isShowingLastContent
                            ? t('pages.onboarding.actions.start')
                            : t('pages.onboarding.actions.continue')}
                    </Button>
                </Box>
            </div>
        </Container>
    );
};
