export const classNames = (
    first: string | Record<string, boolean>,
    obj?: Record<string, boolean>
): string => {
    const defaultValue = typeof first === 'string' ? first : '';
    const objToEvaluate = typeof first === 'object' ? first : obj || {};

    const result = Object.keys(objToEvaluate)
        .filter((key) => objToEvaluate[key])
        .join(' ');

    return `${defaultValue} ${result}`;
};
