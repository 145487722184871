import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import { useQueryUrl } from '../../../../hooks/QueryUrl';
import { Section, Site } from '../../../../model/site';
import { api } from '../../../../services/api';
import { clone, deepEqual } from '../../../../utils/objects';
import { uuidv4 } from '../../../../utils/uuid';

const getSectionById = <T extends Section>(
    sectionType: string,
    sectionId: string | null | undefined,
    site?: Site
) => {
    const section = site?.sections.find(
        ({ type, id }) => type === sectionType && id === sectionId
    );
    return section
        ? { section, present: true }
        : {
              present: false,
              section: {
                  id: uuidv4(),
                  type: sectionType,
                  active: true,
              } as T,
          };
};

export const updateSite = (section: Section, site: Site) => {
    const _section = JSON.parse(
        JSON.stringify(section, (_, value) =>
            typeof value === 'string' && !value.trim() ? undefined : value
        )
    );

    const index = site.sections.findIndex(
        ({ type, id }) => type === section.type && id === section.id
    );
    if (index > -1) {
        site.sections[index] = { ..._section, active: true };
    } else {
        site.sections.push({ ..._section, active: true });
    }
};

export function useSection<T extends Section>(
    type: string,
    modify?: (newly: boolean, section: T) => T
) {
    const navigate = useNavigate();
    const { site, setSite } = useSite();
    const { appState, setAppState } = useAppState();
    const sectionId = useQueryUrl().get('id');

    const initialSectionState = useMemo(() => {
        const { section, present } = getSectionById<T>(type, sectionId, site);
        const safeSection = clone<T>(section);
        return modify ? modify(!present, safeSection) : safeSection;
    }, [sectionId, site, type, modify]);

    const [section, setSection] = useState(initialSectionState);

    const saveSection = (updatedSection?: T) => {
        const copySite = { ...site };
        updateSite(updatedSection || section, copySite);
        setSite(copySite);

        setAppState({ ...appState, editingSiteModified: true });
        api.saveDraft(appState.editingSiteId as string, copySite);

        navigate(-1);
    };

    const hasUnsavedChanges = () => !deepEqual(initialSectionState, section);

    return {
        section,
        setSection,
        saveSection,
        hasUnsavedChanges,
    } as {
        section: T;
        setSection: (t: T) => void;
        saveSection: (t?: T) => void;
        hasUnsavedChanges: () => boolean;
    };
}
