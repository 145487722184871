import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Label } from '../../../../../../components/Fonts';
import {
    AlignOptionContainer,
    AlignOptionsContainer,
    Container,
} from './styles';

const AlignIcon = () => {
    return (
        <>
            <span></span>
            <span></span>
            <span></span>
        </>
    );
};

const AlignIconMemoized = memo(AlignIcon);

type Props = {
    value: string;
    onSelect: (value: 'LEFT' | 'CENTER') => void;
};

export const AlignSection = ({ value, onSelect }: Props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Label color="var(--color-neutral-white)">
                {t(
                    'pages.editor.settings.form.advancedCustomization.inputs.aligment.label'
                )}
            </Label>
            <AlignOptionsContainer>
                <AlignOptionContainer
                    $active={value === 'LEFT'}
                    $align="LEFT"
                    onClick={() => onSelect('LEFT')}
                >
                    <AlignIconMemoized />
                </AlignOptionContainer>
                <AlignOptionContainer
                    $active={value === 'CENTER'}
                    $align="CENTER"
                    onClick={() => onSelect('CENTER')}
                >
                    <AlignIconMemoized />
                </AlignOptionContainer>
            </AlignOptionsContainer>
        </Container>
    );
};
