type Props = {
    width?: number;
    height?: number;
    color: string;
};

const ChevronRightIcon = ({ width = 17, height = 16, color }: Props) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.05844 16L3.5 14.5455L10.0455 8L3.5 1.45455L5.05844 0L13.0584 8L5.05844 16Z"
                fill={color}
            />
        </svg>
    );
};

export default ChevronRightIcon;
