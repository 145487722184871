import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { SectionHelp } from '../../../../components/SectionHelp';
import { AppState, useAppState } from '../../../../contexts/App';
import { useSite } from '../../../../contexts/Site';
import { Site } from '../../../../model/site';
import {
    Theme,
    getCustomThemeFromSite,
    getThemeFromSite,
} from '../../../../model/theme';
import { api } from '../../../../services/api';
import { AlignSection } from './components/AlignSection';
import { ColorPreviewSection } from './components/ColorPreviewSection';
import { CustomColorSection } from './components/CustomColorSection';
import { PreDefinedColorSection } from './components/PreDefinedColorSection';
import { Container } from './styles';

const customThemeHasChanged = (savedTheme: Theme, theme: Theme) => {
    return (
        theme.id === 'custom' &&
        (theme.primary !== savedTheme.primary ||
            theme.secondary !== savedTheme.secondary ||
            theme.buttons !== savedTheme.buttons ||
            theme.textButtons !== savedTheme.textButtons)
    );
};

const StylePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();
    const { site, setSite } = useSite();

    const [activeTheme, setActiveTheme] = useState<Theme>(() =>
        getThemeFromSite(site as Site)
    );
    const [customTheme, setCustomTheme] = useState<Theme>(() =>
        getCustomThemeFromSite(site as Site)
    );

    const [layoutAlign, setLayoutAling] = useState(
        site?.layout?.align || 'LEFT'
    );

    const updateCustomThemeHandler = (theme: Theme) => {
        setActiveTheme(theme);
        setCustomTheme(theme);
    };

    const hasUnsavedChanges =
        site?.theme.id !== activeTheme.id ||
        (!!site?.layout?.align && site?.layout?.align !== layoutAlign) ||
        customThemeHasChanged(site?.theme as Theme, activeTheme);

    const saveHandler = () => {
        const copySite = {
            ...site,
            theme: activeTheme,
            layout: {
                align: layoutAlign,
            },
        } as Site;

        setSite(copySite);

        const copyAppState = {
            ...appState,
            editingSiteModified: true,
        } as AppState;

        api.saveDraft(copyAppState.editingSiteId as string, copySite);
        setAppState(copyAppState);

        navigate(-1);
    };

    return (
        <>
            <Container>
                <Header
                    title={t('pages.editor.settings.header.title')}
                    hasUnsavedChanges={hasUnsavedChanges}
                />

                <Box borderBottom className="npt">
                    <SectionHelp
                        message={t('pages.editor.settings.help.message')}
                    />

                    <ColorPreviewSection activeTheme={activeTheme} />

                    <PreDefinedColorSection
                        hideBorderBottom={
                            appState.editingSiteSubscription.type !== 'PLUS'
                        }
                        onSelectTheme={(theme) => setActiveTheme(theme)}
                        activeTheme={activeTheme}
                        customTheme={customTheme}
                    />

                    {appState.editingSiteSubscription.type === 'PLUS' && (
                        <>
                            {activeTheme.id === 'custom' && (
                                <CustomColorSection
                                    activeTheme={activeTheme}
                                    onUpdateTheme={updateCustomThemeHandler}
                                    customTheme={customTheme}
                                />
                            )}

                            <AlignSection
                                value={layoutAlign}
                                onSelect={setLayoutAling}
                            />
                        </>
                    )}
                </Box>
                <SaveBar
                    onSave={saveHandler}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Container>
        </>
    );
};

export default StylePage;
