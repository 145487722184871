import { useTranslation } from 'react-i18next';

import { Select } from '../../../../../../../../components/Form/Input/select';
import Label from '../../../../../../../../components/Form/Label';
import { FormGroup } from '../../../../../../../../components/Form/styles';
import { MailChimpAudienceList } from '../../../../../../../../services/api';

type Props = {
    value?: MailChimpAudienceList;
    onChange: (audience: MailChimpAudienceList) => void;
    isLoadingList: boolean;
    audienceLists: MailChimpAudienceList[];
};

export const AudienceSelectList = ({
    value,
    isLoadingList,
    onChange,
    audienceLists,
}: Props) => {
    const { t } = useTranslation();
    return (
        <FormGroup>
            <Select
                id="audienceList"
                name="audienceList"
                value={value?.id}
                disabled={audienceLists.length < 2}
                // @ts-ignore
                onChange={(e) => {
                    const audience = audienceLists.find(
                        ({ id }) => id === e.target.value
                    );
                    onChange(audience as MailChimpAudienceList);
                }}
            >
                {isLoadingList && value && (
                    <option key={value.id} value={value.id}>
                        {value.name}
                    </option>
                )}
                {audienceLists.map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ))}
            </Select>
            <Label
                htmlFor="category"
                text={t(
                    'pages.editor.form.form.storage.mailChimp.audienceList.label'
                )}
            />
        </FormGroup>
    );
};
