import styled, { css } from 'styled-components';

export const ThemeCardsSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 24px 0 32px 0;
    border-bottom: 1px solid var(--color-neutral-dark);
    margin: 0 -8px 32px -8px;

    &.no-border {
        border: 0;
    }
`;

type ThemeCardProps = {
    $active: boolean;
    $primary: string;
    $secondary: string;
};

export const ThemeCard = styled.div.attrs<ThemeCardProps>((props) => ({
    role: 'button',
    $active: props.$active,
    $primary: props.$primary,
    $secondary: props.$secondary,
    style: {
        backgroundColor: props.$primary,
        color: props.$secondary,
    },
}))`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    border-radius: 12px;
    width: calc(20% - 16px);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 8px 16px 8px;
    position: relative;

    &:nth-last-child(-n + 5) {
        margin-bottom: 0;
    }

    ${(props) =>
        props.$active &&
        css`
            &:before {
                content: '';
                position: absolute;
                display: block;
                border: 3px solid var(--color-brand-blue);
                width: calc(100% + 6px);
                height: 52px;
                border-radius: 15px;
            }
        `}
`;
