import styled from 'styled-components';

export const Container = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    color: var(--color-neutral-white);
    display: inline-flex;
    align-items: center;

    span {
        width: 100px;
        display: inline-block;
        margin: 0 8px;
        text-align: center;
    }

    > button[disabled] {
        cursor: unset;
    }
`;
