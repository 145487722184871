import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import padStart from 'string.prototype.padstart';
import App from './App';
import constants from './constants';
import reportWebVitals from './reportWebVitals';
import { configureAxiosInterceptors } from './services/api/axios';
import CognitoConfig from './services/auth/cognito-config';
import * as imagePrefetch from './services/prefetch/images';
import GlobalStyles from './styles/global';
import './styles/globals.css';
import '@keepoinc/site-page-react-component/globals.css';

import './services/i18ln';

padStart.shim();

if (constants.release) {
    Sentry.init({
        dsn: 'https://a4522c9a0eb34456b786ea07f860b80a@o1050525.ingest.sentry.io/6031790',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        release: constants.release,
    });
}

Amplify.configure(CognitoConfig);

configureAxiosInterceptors();

imagePrefetch.fetch();

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <GlobalStyles />
            <Suspense fallback={null}>
                <App />
            </Suspense>
        </React.StrictMode>
    );
} else {
    console.log('Root element not found');
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
