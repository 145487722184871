import styled from 'styled-components';

export const Container = styled.div`
    menu,
    ol,
    ul {
        list-style: unset;
    }
    .block,
    .top-block {
        padding: 2rem;
    }

    .top-block {
        padding-bottom: 0;
    }

    .standalone-block {
        padding: 0 2rem;
    }

    .title {
        padding: 0 2rem;
    }

    p {
        color: var(--color-neutral-150);
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p.subtitle {
        font-weight: bold;
        color: var(--color-neutral-white);
    }

    ul {
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
        color: var(--color-neutral-150);

        &:last-child {
            margin-bottom: 0;
        }

        p.subtitle {
            font-weight: bold;
            color: var(--color-neutral-150);
            margin-bottom: 1.5rem;
        }
    }

    p strong {
        font-weight: bold;
    }

    span {
        display: inline-block;
    }
`;
