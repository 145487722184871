import millify from 'millify';
import { useCallback, useEffect, useState } from 'react';

import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(BarElement, CategoryScale, LinearScale);

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from '../../components/Button';
import Header from '../../components/Header';
import { Box } from '../../components/Layout';
import { LoadingModal } from '../../components/LoadingModal';
import Tabs, {
    TabsContent,
    TabsHeader,
    TabsNavItem,
    TabsPane,
} from '../../components/Tabs';
import { useAppState } from '../../contexts/App';
import { api } from '../../services/api';
import { AnalyticsSummary } from '../../services/api/models';
import { classNames } from '../../utils/styles';
import { BigsNumbers } from './components/BigNumbers';
import { PeriodSelection } from './components/PeriodSelection';
import { SummaryList } from './components/SummaryList';
import { EmptyStateIcon } from './icons/EmptyState';
import {
    Container,
    EmptyStateBox,
    FooterBox,
    StatisticsContent,
    StatisticsContentBars,
    StatisticsContentBody,
    StatisticsContentHead,
    StatisticsContentSubTabs,
} from './styles';
import {
    useBarOptions,
    useDataPresentation,
    useMetrics,
    useMillifyOptions,
    usePeriods,
} from './utils';

const StatisticsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState } = useAppState();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [loadingPresentation, setLoadingPresentation] = useState(true);

    const [activeMetric, setActiveMetric] = useState(0);
    const [activePeriod, setActivePeriod] = useState(0);

    const [dataSummary, setDataSummary] = useState<AnalyticsSummary>();

    const millifyOptions = useMillifyOptions();
    const barOptions = useBarOptions(millifyOptions);

    const metrics = useMetrics();
    const periods = usePeriods();

    const dataPresentation = useDataPresentation(
        metrics,
        periods,
        activeMetric,
        activePeriod,
        dataLoaded,
        setLoadingPresentation,
        dataSummary
    );

    const getFormattedNumber = useCallback(
        (value: number) => millify(value, millifyOptions),
        [millifyOptions]
    );

    const loadDataHandler = useCallback(async () => {
        const data = await api.getAnalytics(appState.editingSiteId as string);
        setDataSummary(data);
        setDataLoaded(true);
    }, [appState]);

    useEffect(() => {
        loadDataHandler();
    }, [loadDataHandler]);

    return loadingPresentation ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title="Analytics" />
            <Box>
                <Tabs>
                    <TabsHeader>
                        {metrics.map((metric, index) => (
                            <TabsNavItem
                                key={index}
                                active={activeMetric === index}
                                onClick={() => setActiveMetric(index)}
                            >
                                {metric.title}
                            </TabsNavItem>
                        ))}
                    </TabsHeader>
                    <TabsContent>
                        {metrics.map((_, index) => (
                            <TabsPane
                                className={classNames({
                                    show: activeMetric === index,
                                })}
                                key={index}
                            >
                                {!dataPresentation ? (
                                    <EmptyStateBox>
                                        <EmptyStateIcon />
                                        <p>
                                            {t(
                                                `pages.analytics.emptyState.${metrics[activeMetric].type}`
                                            )}
                                        </p>
                                    </EmptyStateBox>
                                ) : (
                                    <>
                                        <StatisticsContentHead>
                                            <PeriodSelection
                                                periods={periods}
                                                activePeriod={activePeriod}
                                                onSelect={setActivePeriod}
                                            />
                                        </StatisticsContentHead>
                                        <StatisticsContentSubTabs>
                                            {periods.map((_, idx) => (
                                                <StatisticsContent
                                                    key={idx}
                                                    className={classNames({
                                                        show:
                                                            activePeriod ===
                                                            idx,
                                                    })}
                                                >
                                                    <StatisticsContentBody>
                                                        <BigsNumbers
                                                            formatterNumber={
                                                                getFormattedNumber
                                                            }
                                                            numbers={
                                                                dataPresentation.bigNumbers
                                                            }
                                                        />
                                                        <StatisticsContentBars>
                                                            <Bar
                                                                //@ts-ignore
                                                                data={
                                                                    dataPresentation.chart
                                                                }
                                                                //@ts-ignore
                                                                options={
                                                                    barOptions
                                                                }
                                                                type=""
                                                            />
                                                        </StatisticsContentBars>
                                                        <SummaryList
                                                            title={
                                                                metrics[
                                                                    activeMetric
                                                                ]
                                                                    .listDescription
                                                            }
                                                            values={
                                                                dataPresentation.list
                                                            }
                                                            formatterNumber={
                                                                getFormattedNumber
                                                            }
                                                        />
                                                    </StatisticsContentBody>
                                                </StatisticsContent>
                                            ))}
                                        </StatisticsContentSubTabs>
                                    </>
                                )}
                            </TabsPane>
                        ))}
                    </TabsContent>
                </Tabs>
            </Box>
            {dataPresentation && (
                <FooterBox>
                    <LinkButton
                        onClick={() => navigate(-1)}
                        className="go-back-button"
                    >
                        {t('actions.goBack')}
                    </LinkButton>
                </FooterBox>
            )}
        </Container>
    );
};

export default StatisticsPage;
