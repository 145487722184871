type Props = {
    letters?: boolean;
};

const SymbolKeepoIcon = ({ letters = true }: Props) => {
    return (
        <svg
            width={letters ? '131' : '38'}
            height="38"
            viewBox={`0 0 ${letters ? '131' : '38'} 38`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.38436 4.38436L5.34314 5.34315L4.38436 4.38436C2.91886 5.84986 2.26216 7.71228 1.94905 10.0412C1.64402 12.3099 1.64404 15.2131 1.64407 18.8972L1.64407 19L1.64407 19.1028C1.64404 22.7869 1.64402 25.6901 1.94905 27.9588C2.26199 30.2864 2.91813 32.1481 4.38192 33.6132C4.38273 33.614 4.38354 33.6148 4.38436 33.6156C4.38452 33.6158 4.38467 33.616 4.38483 33.6161C4.38505 33.6163 4.38526 33.6165 4.38547 33.6168C4.38602 33.6173 4.38657 33.6179 4.38711 33.6184L4.7815 34.0128H4.81397C6.20887 35.2031 7.9341 35.7677 10.0412 36.0509C12.31 36.356 15.2132 36.356 18.8973 36.3559H19H19.1027C22.7868 36.356 25.69 36.356 27.9588 36.0509C30.2877 35.7378 32.1501 35.0811 33.6156 33.6156C35.0811 32.1501 35.7378 30.2877 36.0509 27.9588C36.356 25.69 36.356 22.7868 36.3559 19.1027V19V18.8973C36.356 15.2132 36.356 12.31 36.0509 10.0412C35.7677 7.9341 35.2031 6.20887 34.0128 4.81397V4.7815L33.6184 4.38711C33.6179 4.38657 33.6173 4.38602 33.6168 4.38547C33.6164 4.3851 33.616 4.38473 33.6156 4.38436C33.6155 4.38418 33.6153 4.384 33.6151 4.38382C33.5892 4.35797 33.5662 4.33704 33.5484 4.32155C33.566 4.3369 33.5888 4.35759 33.6144 4.38312C32.1491 2.91849 30.2871 2.26207 27.9588 1.94905C25.6901 1.64402 22.7869 1.64404 19.1028 1.64407L19 1.64407L18.8972 1.64407C15.2131 1.64404 12.3099 1.64402 10.0412 1.94905C7.71228 2.26216 5.84986 2.91886 4.38436 4.38436Z"
                stroke="white"
                strokeWidth="2.71186"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9289 12.2385C26.3038 12.6262 26.2985 13.2381 25.9681 13.6643C24.7915 15.1821 24.0873 17.1076 24.0873 19.2005C24.0873 21.2933 24.7915 23.2189 25.9681 24.7366C26.2985 25.1629 26.3038 25.7747 25.9289 26.1624L24.2908 27.8568C23.8978 28.2634 23.2429 28.2655 22.8827 27.8295C20.9622 25.505 19.8011 22.4901 19.8011 19.2005C19.8011 15.9108 20.9622 12.8959 22.8827 10.5714C23.2429 10.1354 23.8978 10.1375 24.2908 10.5441L25.9289 12.2385ZM13 11.1996C13 10.6473 13.4477 10.1996 14 10.1996H16.4C16.9523 10.1996 17.4 10.6473 17.4 11.1996V27.1996C17.4 27.7519 16.9523 28.1996 16.4 28.1996H14C13.4477 28.1996 13 27.7519 13 27.1996V11.1996Z"
                fill="white"
            />
            {letters && (
                <>
                    <path
                        d="M59.6569 28.1632L54.695 22.0516V28.1632H51V8.99982H54.695V19.9022L59.6042 13.8166H64.4077L57.9678 21.0158L64.4605 28.1632H59.6569Z"
                        fill="white"
                    />
                    <path
                        d="M80.0056 20.6791C80.0056 21.197 79.9704 21.6632 79.9 22.0775H69.2108C69.2988 23.1134 69.6683 23.9248 70.3193 24.5118C70.9704 25.0988 71.771 25.3923 72.7211 25.3923C74.0936 25.3923 75.0701 24.8139 75.6508 23.6572H79.6361C79.2138 25.0383 78.4044 26.1778 77.2079 27.0755C76.0115 27.956 74.5422 28.3962 72.8003 28.3962C71.3927 28.3962 70.1258 28.0941 68.9997 27.4899C67.8912 26.8684 67.0202 25.9965 66.3868 24.8743C65.7709 23.7522 65.463 22.4573 65.463 20.9899C65.463 19.5051 65.7709 18.2017 66.3868 17.0795C67.0026 15.9573 67.8648 15.0941 68.9733 14.4899C70.0818 13.8856 71.3575 13.5835 72.8003 13.5835C74.1903 13.5835 75.4308 13.877 76.5217 14.464C77.6302 15.051 78.4836 15.8883 79.0819 16.9759C79.6977 18.0463 80.0056 19.2807 80.0056 20.6791ZM76.1786 19.6433C76.161 18.711 75.8179 17.9686 75.1493 17.4162C74.4807 16.8464 73.6625 16.5616 72.6947 16.5616C71.7798 16.5616 71.0056 16.8378 70.3721 17.3903C69.7563 17.9255 69.378 18.6765 69.2372 19.6433H76.1786Z"
                        fill="white"
                    />
                    <path
                        d="M96.2951 20.6791C96.2951 21.197 96.2599 21.6632 96.1895 22.0775H85.5003C85.5883 23.1134 85.9578 23.9248 86.6088 24.5118C87.2599 25.0988 88.0605 25.3923 89.0106 25.3923C90.3831 25.3923 91.3596 24.8139 91.9403 23.6572H95.9256C95.5033 25.0383 94.6939 26.1778 93.4974 27.0755C92.301 27.956 90.8317 28.3962 89.0898 28.3962C87.6822 28.3962 86.4153 28.0941 85.2892 27.4899C84.1807 26.8684 83.3097 25.9965 82.6763 24.8743C82.0604 23.7522 81.7525 22.4573 81.7525 20.9899C81.7525 19.5051 82.0604 18.2017 82.6763 17.0795C83.2921 15.9573 84.1543 15.0941 85.2628 14.4899C86.3713 13.8856 87.647 13.5835 89.0898 13.5835C90.4798 13.5835 91.7203 13.877 92.8112 14.464C93.9197 15.051 94.7731 15.8883 95.3714 16.9759C95.9872 18.0463 96.2951 19.2807 96.2951 20.6791ZM92.4681 19.6433C92.4505 18.711 92.1074 17.9686 91.4388 17.4162C90.7702 16.8464 89.952 16.5616 88.9842 16.5616C88.0693 16.5616 87.2951 16.8378 86.6616 17.3903C86.0458 17.9255 85.6675 18.6765 85.5267 19.6433H92.4681Z"
                        fill="white"
                    />
                    <path
                        d="M102.687 15.8883C103.162 15.2322 103.813 14.6884 104.64 14.2568C105.485 13.8079 106.444 13.5835 107.517 13.5835C108.766 13.5835 109.893 13.8856 110.895 14.4899C111.916 15.0941 112.717 15.9573 113.297 17.0795C113.895 18.1844 114.195 19.4706 114.195 20.9381C114.195 22.4055 113.895 23.709 113.297 24.8484C112.717 25.9706 111.916 26.8425 110.895 27.464C109.893 28.0855 108.766 28.3962 107.517 28.3962C106.444 28.3962 105.494 28.1804 104.667 27.7488C103.857 27.3172 103.197 26.7734 102.687 26.1174V34.9998H98.9922V13.8166H102.687V15.8883ZM110.42 20.9381C110.42 20.0749 110.236 19.3325 109.866 18.711C109.514 18.0722 109.039 17.5888 108.441 17.2608C107.86 16.9328 107.227 16.7687 106.541 16.7687C105.872 16.7687 105.239 16.9414 104.64 17.2867C104.06 17.6147 103.585 18.0981 103.215 18.7369C102.863 19.3757 102.687 20.1267 102.687 20.9899C102.687 21.8531 102.863 22.6041 103.215 23.2429C103.585 23.8816 104.06 24.3737 104.64 24.7189C105.239 25.047 105.872 25.211 106.541 25.211C107.227 25.211 107.86 25.0383 108.441 24.6931C109.039 24.3478 109.514 23.8557 109.866 23.217C110.236 22.5782 110.42 21.8185 110.42 20.9381Z"
                        fill="white"
                    />
                    <path
                        d="M123.372 28.3962C121.965 28.3962 120.698 28.0941 119.572 27.4899C118.446 26.8684 117.557 25.9965 116.906 24.8743C116.273 23.7522 115.956 22.4573 115.956 20.9899C115.956 19.5224 116.281 18.2276 116.932 17.1054C117.601 15.9832 118.507 15.12 119.651 14.5158C120.795 13.8942 122.07 13.5835 123.478 13.5835C124.886 13.5835 126.161 13.8942 127.305 14.5158C128.449 15.12 129.346 15.9832 129.997 17.1054C130.666 18.2276 131 19.5224 131 20.9899C131 22.4573 130.657 23.7522 129.971 24.8743C129.302 25.9965 128.387 26.8684 127.226 27.4899C126.082 28.0941 124.798 28.3962 123.372 28.3962ZM123.372 25.2369C124.041 25.2369 124.666 25.0815 125.246 24.7707C125.845 24.4427 126.32 23.9593 126.672 23.3205C127.023 22.6818 127.199 21.9049 127.199 20.9899C127.199 19.626 126.83 18.5815 126.091 17.8564C125.369 17.114 124.481 16.7429 123.425 16.7429C122.369 16.7429 121.481 17.114 120.759 17.8564C120.056 18.5815 119.704 19.626 119.704 20.9899C119.704 22.3537 120.047 23.4069 120.733 24.1492C121.437 24.8743 122.317 25.2369 123.372 25.2369Z"
                        fill="white"
                    />
                </>
            )}
        </svg>
    );
};

export default SymbolKeepoIcon;
