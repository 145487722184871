import { Chart, ChartType, TooltipModel } from 'chart.js';

const getOrCreateTooltip = (chart: Chart) => {
    let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'var(--color-neutral-darkness)';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.borderRadius = '8px';

        const divRoot = document.createElement('div');
        divRoot.style.padding = '16px 24px';
        divRoot.style.borderRadius = '8px';
        divRoot.style.border = '1px solid var(--color-neutral-base)';

        divRoot.id = 'root';

        tooltipEl.appendChild(divRoot);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
};

interface Context {
    chart: Chart;
    tooltip: TooltipModel<ChartType>;
}

export const externalTooltipHandler = (context: Context) => {
    // Tooltip Element
    const { chart, tooltip } = context;

    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltipEl && tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const bodyDiv = document.createElement('div');

        const titleLine = (titleLines as []).shift();
        const bodyLine = (bodyLines as []).shift();

        const lineWrapper = document.createElement('span');
        lineWrapper.style.marginLeft = '24px';
        lineWrapper.style.fontWeight = '600';
        lineWrapper.appendChild(document.createTextNode(`${bodyLine}`));

        bodyDiv.appendChild(document.createTextNode(`${titleLine}`));
        bodyDiv.appendChild(lineWrapper);

        const divRoot = tooltipEl.querySelector('#root');

        if (divRoot) {
            // Remove old children
            while (divRoot.firstChild) {
                divRoot.firstChild.remove();
            }

            // Add new children
            divRoot.appendChild(bodyDiv);
        }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.fontSize = '18px';
    tooltipEl.style.fontFamily = "'Poppins', sans-serif";
    tooltipEl.style.padding = '0';
};
