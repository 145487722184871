export const ContactIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00008 4H20.0001C21.6569 4 23.0001 5.34315 23.0001 7V7.42743C23.0002 7.43617 23.0002 7.44489 23.0001 7.45362V18C23.0001 19.6569 21.6569 21 20.0001 21H4.00008C2.34323 21 1.00009 19.6569 1.00009 18V7.45358C0.999972 7.44488 0.999972 7.43618 1.00009 7.42747V7C1.00009 5.34315 2.34323 4 4.00008 4ZM3.00008 9.36205V18C3.00008 18.5523 3.4478 19 4.00008 19H20.0001C20.5524 19 21.0001 18.5523 21.0001 18V9.36205L13.7205 14.4578C12.6875 15.1809 11.3127 15.1809 10.2797 14.4578L3.00008 9.36205ZM20.9972 6.9228L12.5735 12.8193C12.2292 13.0603 11.7709 13.0603 11.4266 12.8193L3.00302 6.9228C3.04243 6.40659 3.47378 6 4.00008 6H20.0001C20.5264 6 20.9577 6.40659 20.9972 6.9228Z"
                fill="#767B8F"
            />
        </svg>
    );
};
