import { Navigate, Route, Routes } from 'react-router-dom';

import AboutPage from './pages/About';
import { ButtonPage } from './pages/Button';
import CatalogPage from './pages/Catalog';
import ItemCatalogPage from './pages/Catalog/pages/Item';
import FormPage from './pages/Form';
import EmailStoragePage from './pages/Form/pages/EmailStorage';
import { GoogleDriveStoragePage } from './pages/Form/pages/GoogleDriveStorage';
import { MailChimpStoragePage } from './pages/Form/pages/MailChimpStorage';
import LinksPage from './pages/Links';
import ItemLinkPage from './pages/Links/pages/Item';
import { AudioMedia } from './pages/Media/Audio';
import { ImageMediaPage } from './pages/Media/Image';
import { VideoMedia } from './pages/Media/Video';
import PreviewPage from './pages/Preview';
import PublishPage from './pages/Publish';
import PublishSuccessPage from './pages/Publish/pages/Success';
import SectionsPage from './pages/Sections';
import SocialPage from './pages/Social';
import StylePage from './pages/Style';
import TitlePage from './pages/Title';
import WelcomePage from './pages/Welcome';

const EditorPage = () => {
    return (
        <Routes>
            <Route path="sections" element={<SectionsPage />} />
            <Route path="/title" element={<TitlePage />} />
            <Route path="welcome" element={<WelcomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="button" element={<ButtonPage />} />
            <Route path="form" element={<FormPage />} />
            <Route path="form/storage/email" element={<EmailStoragePage />} />
            <Route
                path="form/storage/mailchimp"
                element={<MailChimpStoragePage />}
            />
            <Route
                path="form/storage/google-drive"
                element={<GoogleDriveStoragePage />}
            />
            <Route path="catalog" element={<CatalogPage />} />
            <Route path="catalog/item" element={<ItemCatalogPage />} />
            <Route path="links" element={<LinksPage />} />
            <Route path="links/item" element={<ItemLinkPage />} />
            <Route path="social" element={<SocialPage />} />
            <Route path="media/video" element={<VideoMedia />} />
            <Route path="media/audio" element={<AudioMedia />} />
            <Route path="media/image" element={<ImageMediaPage />} />
            <Route path="preview" element={<PreviewPage />} />
            <Route path="style" element={<StylePage />} />
            <Route path="publish" element={<PublishPage />} />
            <Route path="publish/success" element={<PublishSuccessPage />} />
            <Route path="*" element={<Navigate to="/editor/sections" />} />
        </Routes>
    );
};

export default EditorPage;
