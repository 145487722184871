import styled, { css } from 'styled-components';

export const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
`;

export const Container = styled.div`
    width: 100%;
    max-width: min(80vw, 480px);
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-neutral-dark);
    border-radius: 1rem;
    padding: 1rem;
`;

export const Body = styled.div`
    padding-top: 2rem;
`;

type HeaderProps = {
    $hasTitle: boolean;
};

export const Header = styled.div<HeaderProps>`
    display: flex;

    ${(props) =>
        props.$hasTitle &&
        css`
            justify-content: space-between;
            width: 100%;
            align-items: center;
        `}

    ${(props) =>
        !props.$hasTitle &&
        css`
            justify-content: flex-end;
            margin-left: auto;
        `}
`;

export const Title = styled.span`
    color: var(--color-neutral-white);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
`;
