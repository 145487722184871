import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { inputCss } from '../../../../components/Form/Input/styles';

export const Container = styled.div``;

// @ts-ignore
export const PhoneInput = styled(InputMask)`
    ${inputCss}
`;
