import styled from 'styled-components';

export const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid var(--color-neutral-base);

    li {
        font-family: 'Inter', sans-serif;
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--color-neutral-white);
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--color-neutral-base);

        span {
            color: var(--color-lemon);
        }
    }
`;

export const Title = styled.h4`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--color-neutral-light);
    margin: 0 0 8px 0;
`;
