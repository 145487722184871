import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Input from '../../../../../../components/Form/Input/input';
import { TextArea } from '../../../../../../components/Form/Input/textArea';
import Label from '../../../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../../../components/Form/styles';
import Header from '../../../../../../components/Header';
import { Box } from '../../../../../../components/Layout';
import SaveBar from '../../../../../../components/SaveBar';
import { UploadImageBox } from '../../../../../../components/UploadImageBox';
import { ItemSectionCatalog } from '../../../../../../model/site';
import { clone, deepEqual } from '../../../../../../utils/objects';
import { sanitizeUrl } from '../../../../../../utils/url';
import { uuidv4 } from '../../../../../../utils/uuid';
import { navigationState } from '../../../Sections/navigationState';
import { CatalogNavigationState } from '../../catalogNavigationState';
import { Container } from './styles';

const ItemCatalogPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const catalogNavigationState =
        navigationState.get() as CatalogNavigationState;

    const headerTitle = useRef(
        catalogNavigationState.editingItem?.id
            ? t('pages.editor.catalog.item.header.title.edit')
            : t('pages.editor.catalog.item.header.title.add')
    );

    const initialItemState = useRef<ItemSectionCatalog>(
        clone<ItemSectionCatalog>(
            catalogNavigationState.editingItem || {
                id: uuidv4(),
            }
        )
    );

    const [item, setItem] = useState(initialItemState.current);

    const isSaveDisabled = !item.title || !item.link || !item.image;

    const hasUnsavedChanges = () => !deepEqual(initialItemState.current, item);

    const saveHandler = () => {
        const items = catalogNavigationState.currentSection.items || [];
        const itemIndex = items.findIndex(({ id }) => id === item.id);

        if (itemIndex >= 0) {
            items[itemIndex] = {
                ...item,
                link: sanitizeUrl(item.link) as string,
            };
        } else {
            items.push({
                ...item,
                link: sanitizeUrl(item.link) as string,
            });
        }

        catalogNavigationState.currentSection.items = items;
        navigate(-1);
    };

    return (
        <Container>
            <Header
                title={headerTitle.current}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="title"
                            required
                            type="text"
                            value={item.title}
                            maxLength={40}
                            autoFocus={!item.title}
                            onChange={(e) =>
                                setItem({ ...item, title: e.target.value })
                            }
                            label={t('pages.editor.catalog.item.form.title')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextArea
                            id="description"
                            name="description"
                            autoComplete="off"
                            rows={3}
                            value={item.description}
                            maxLength={80}
                            // @ts-ignore
                            onChange={(e) =>
                                setItem({
                                    ...item,
                                    description: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="description"
                            maxLenght={80}
                            usedLenght={item.description?.length || 0}
                            text={t(
                                'pages.editor.catalog.item.form.description'
                            )}
                        />
                    </FormGroup>
                </Box>
                <Box borderBottom>
                    <FormGroup>
                        <UploadImageBox
                            onUpload={(image) =>
                                setItem({ ...item, image: image as string })
                            }
                            required
                            image={item.image}
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <FormGroup>
                        <Input
                            id="linkTitle"
                            required
                            type="text"
                            value={item.linkTitle}
                            maxLength={16}
                            onChange={(e) =>
                                setItem({ ...item, linkTitle: e.target.value })
                            }
                            label={t(
                                'pages.editor.catalog.item.form.linkTitle.label'
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            autoCapitalize="none"
                            placeholder="https://"
                            id="link"
                            required
                            type="text"
                            value={item.link}
                            onChange={(e) =>
                                setItem({ ...item, link: e.target.value })
                            }
                            label={t('pages.editor.catalog.item.form.link')}
                        />
                    </FormGroup>
                </Box>
            </Form>

            <SaveBar
                onSave={saveHandler}
                disabled={isSaveDisabled}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        </Container>
    );
};

export default ItemCatalogPage;
