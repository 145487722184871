import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../../components/Form/Input/input';
import { TextArea } from '../../../../components/Form/Input/textArea';
import Label from '../../../../components/Form/Label';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { UploadImageBox } from '../../../../components/UploadImageBox';
import { AboutSection } from '../../../../model/site';
import { useSection } from '../../hooks/Section';
import { Container } from './styles';

const AboutPage = () => {
    const { t } = useTranslation();

    const setDefaultValues = useCallback(
        (newly: boolean, section: AboutSection) => {
            if (newly) {
                section.pageTitle = t('pages.editor.about.header.title');
            }
            return section;
        },
        [t]
    );

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<AboutSection>('about', setDefaultValues);

    const isSaveDisabled =
        !section.pageTitle &&
        !section.image &&
        !section.featuredText &&
        !section.completeDescription;

    return (
        <Container>
            <Header
                title={t('pages.editor.about.header.title')}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Form>
                <Box borderBottom>
                    <FormGroup>
                        <Input
                            id="pageTitle"
                            type="text"
                            value={section.pageTitle}
                            label={t('pages.editor.about.form.pageTitle')}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    pageTitle: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <Box borderBottom>
                    <UploadImageBox
                        onUpload={(image) =>
                            setSection({
                                ...section,
                                image,
                            })
                        }
                        image={section.image}
                    />
                </Box>
                <Box>
                    <FormGroup>
                        <TextArea
                            id="featuredText"
                            name="featuredText"
                            autoComplete="off"
                            rows={5}
                            value={section.featuredText}
                            // @ts-ignore
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    featuredText: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="supportingText"
                            text={t('pages.editor.about.form.featuredText')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextArea
                            id="completeDescription"
                            name="completeDescription"
                            autoComplete="off"
                            rows={5}
                            value={section.completeDescription}
                            // @ts-ignore
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    completeDescription: e.target.value,
                                })
                            }
                        />
                        <Label
                            htmlFor="supportingText"
                            text={t(
                                'pages.editor.about.form.completeDescription'
                            )}
                        />
                    </FormGroup>
                </Box>
                <SaveBar
                    onSave={saveSection}
                    disabled={isSaveDisabled}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </Form>
        </Container>
    );
};

export default AboutPage;
