import { Subscription } from '../../services/api';
import { TrackingIntegrations } from '../../services/api/models';
import { createStrictContext } from '../utils';

export interface AppState {
    editingSiteId?: string;
    editingSiteModified: boolean;
    editingSiteSubscription: Subscription;
    published: boolean;
    editingSiteDisabled: boolean;
    notRedirectOnEmptySites: boolean;
    isPROUser: boolean;
    subscriptionSiteIds: string[];
    editingSiteTrackingIntegrations?: TrackingIntegrations;
}

export type AppStateContextType = {
    appState: AppState;
    setAppState: (site: AppState) => void;
};

export const [AppContextProvider, useAppState] =
    createStrictContext<AppStateContextType>();

export const defaultAppState: AppState = {
    editingSiteSubscription: { type: 'FREE' },
    editingSiteModified: false,
    published: false,
    editingSiteDisabled: false,
    notRedirectOnEmptySites: false,
    isPROUser: false,
    subscriptionSiteIds: [],
};
