import styled from 'styled-components';

type Props = {
    active: boolean;
};

export const Card = styled.div<Props>`
    background-color: ${(props) =>
        props.active
            ? 'var(--color-neutral-white)'
            : 'var(--color-neutral-dark)'};

    border-radius: 0.5rem;
    padding: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
        color: ${(props) =>
            props.active
                ? 'var(--color-neutral-dark)'
                : 'var(--color-neutral-light)'};
    }
`;

export const LeftColumnSection = styled.div`
    display: flex;
    align-items: center;

    h6 {
        margin-left: 1rem;
    }

    > {
        :first-child {
            margin-right: 1rem;
        }
    }
`;

export const ButtonsSection = styled.div`
    display: flex;
    flex-direction: column;

    > {
        :first-child {
            margin-bottom: 1rem;
        }
    }
`;

export const IconCircle = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
        props.active
            ? 'var(--color-neutral-lightness)'
            : 'var(--color-neutral-base)'};

    border-radius: 100%;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
