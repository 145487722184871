import styled from 'styled-components';

type Props = {
    borderBottom?: boolean;
};

export const Box = styled.div<Props>`
    padding: 2rem 0rem;
    margin: 0rem 2rem;
    border-bottom: ${(props) =>
        props.borderBottom && '1px solid var(--color-neutral-dark)'};

    &.npt {
        padding-top: 0;
    }
    &.npb {
        padding-bottom: 0;
    }
`;
