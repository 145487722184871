import styled from 'styled-components';

type Props = {
    $active: boolean;
};

export const Container = styled.div<Props>`
    height: 24px;
    width: 24px;
    border: 2px solid
        ${(props) =>
            props.$active
                ? 'var(--color-brand-blue)'
                : 'var(--color-neutral-light)'};
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > div {
        height: 12px;
        width: 12px;
        background-color: ${(props) =>
            props.$active && 'var(--color-brand-blue)'};
        border-radius: 100%;
    }
`;
