import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: var(--color-neutral-150);
    display: flex;
    justify-content: center;

    > div {
        display: flex;
        align-items: center;
    }

    p {
        margin-right: 8px;
    }
`;
