export const EmptyStateIcon = () => {
    return (
        <svg
            width="166"
            height="160"
            viewBox="0 0 166 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M83.4102 150.118C122.301 150.118 153.83 118.588 153.83 79.5588C153.83 40.5292 122.301 9 83.4102 9C44.5195 9 12.9902 40.5292 12.9902 79.5588C12.9902 118.588 44.5195 150.118 83.4102 150.118Z"
                fill="#3A3F50"
            />
            <path
                d="M146.49 146C149.528 146 151.99 143.538 151.99 140.5C151.99 137.462 149.528 135 146.49 135C143.453 135 140.99 137.462 140.99 140.5C140.99 143.538 143.453 146 146.49 146Z"
                fill="#3A3F50"
            />
            <path
                d="M156.79 127.6C158.337 127.6 159.59 126.346 159.59 124.8C159.59 123.254 158.337 122 156.79 122C155.244 122 153.99 123.254 153.99 124.8C153.99 126.346 155.244 127.6 156.79 127.6Z"
                fill="#3A3F50"
            />
            <path
                d="M5.49023 128C7.97551 128 9.99023 125.985 9.99023 123.5C9.99023 121.015 7.97551 119 5.49023 119C3.00495 119 0.990234 121.015 0.990234 123.5C0.990234 125.985 3.00495 128 5.49023 128Z"
                fill="#323643"
            />
            <path
                d="M155.19 24.4C158.062 24.4 160.39 22.0719 160.39 19.2C160.39 16.3281 158.062 14 155.19 14C152.318 14 149.99 16.3281 149.99 19.2C149.99 22.0719 152.318 24.4 155.19 24.4Z"
                fill="#323643"
            />
            <path
                d="M123.99 43C128.409 43 131.99 46.5817 131.99 51V129.817C119.337 142.397 101.817 150 82.4902 150C63.302 150 45.7824 142.258 32.9902 129.817V51C32.9902 46.5817 36.572 43 40.9902 43H123.99Z"
                fill="white"
            />
            <path d="M61.9902 70H53.9902V94H61.9902V70Z" fill="#CED6E2" />
            <path d="M79.9902 66H69.9902V94H79.9902V66Z" fill="#CED6E2" />
            <path d="M95.9902 75H86.9902V94H95.9902V75Z" fill="#CED6E2" />
            <path d="M111.99 81H103.99V94H111.99V81Z" fill="#CED6E2" />
            <path
                d="M61.4179 107.273H54.3797C53.9574 107.273 53.5352 106.727 53.5352 106.182V105.091C53.5352 104.545 53.9574 104 54.3797 104H61.4179C61.8401 104 62.2624 104.545 62.2624 105.091V106.182C62.2624 106.727 61.8401 107.273 61.4179 107.273Z"
                fill="#CED6E2"
            />
            <path
                d="M111.57 107.273H68.5909C68.1539 107.273 67.7168 106.727 67.7168 106.182V105.091C67.7168 104.545 68.1539 104 68.5909 104H111.57C112.007 104 112.444 104.545 112.444 105.091V106.182C112.444 106.727 112.007 107.273 111.57 107.273Z"
                fill="#CED6E2"
            />
            <path
                d="M61.4179 116H54.3797C53.9574 116 53.5352 115.455 53.5352 114.909V113.818C53.5352 113.273 53.9574 112.728 54.3797 112.728H61.4179C61.8401 112.728 62.2624 113.273 62.2624 113.818V114.909C62.2624 115.455 61.8401 116 61.4179 116Z"
                fill="#CED6E2"
            />
            <path
                d="M111.57 116H68.5909C68.1539 116 67.7168 115.455 67.7168 114.909V113.818C67.7168 113.273 68.1539 112.728 68.5909 112.728H111.57C112.007 112.728 112.444 113.273 112.444 113.818V114.909C112.444 115.455 112.007 116 111.57 116Z"
                fill="#CED6E2"
            />
            <path
                d="M100.024 145.785C99.6723 147.279 99.1448 148.862 98.5295 150.18C96.8591 153.433 94.2218 155.983 90.969 157.653C87.6284 159.323 83.6723 160.026 79.7163 159.147C70.3976 157.213 64.4196 148.07 66.3536 138.752C68.2877 129.433 77.3426 123.367 86.6613 125.389C90.002 126.092 92.9031 127.763 95.3646 130.048C99.4965 134.18 101.255 140.158 100.024 145.785Z"
                fill="#4B10FF"
            />
            <path
                d="M93 138.108C93 137.804 92.7099 137.5 92.4198 137.5H87.1985C86.9084 137.5 86.6183 137.804 86.6183 138.108V139.323C86.6183 139.627 86.9084 139.93 87.1985 139.93H89.0356L84.7812 144.386L80.6234 140.032C80.1399 139.525 79.4631 139.525 78.9796 140.032L74.145 145.095C73.9517 145.297 73.9517 145.703 74.145 146.006L74.9186 146.918C75.112 147.12 75.4987 147.12 75.7888 146.918L79.7532 142.766L83.9109 147.12C84.3944 147.627 85.0712 147.627 85.5547 147.12L90.5827 141.753V143.677C90.5827 143.981 90.8728 144.285 91.1628 144.285H92.3232C92.6132 144.285 92.9033 143.981 92.9033 143.677L93 138.108Z"
                fill="white"
            />
        </svg>
    );
};
