import styled from 'styled-components';

export const Container = styled.div``;

export const ContentContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SelectSection = styled.div`
    display: flex;
    flex-direction: column;

    > div:first-child {
        margin-bottom: 1rem;
    }
`;

export const SelectionSectionInput = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    > h6 {
        margin-left: 1rem;
    }
`;

export const InputSection = styled.div`
    display: flex;
`;

type Props = {
    $hideBorder?: boolean;
};

export const PreviewBox = styled.div<Props>`
    width: 96px;
    height: 96px;
    border: ${(props) =>
        !props.$hideBorder && '2px dashed var(--color-neutral-light)'};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const InputButtons = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;

    @media (max-width: 320px) {
        margin-left: 1rem;
    }

    > div:last-child {
        margin-top: 1.5rem;
    }
`;

export const EmojiContainer = styled.div`
    cursor: pointer;
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--color-neutral-white);

    h1 {
        font-size: 32px;
    }
`;
