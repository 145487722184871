import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-neutral-base);

    padding: 1.25rem 2rem;
`;

export const TitleSection = styled.div`
    h3 {
        font-size: 1.125rem;
        font-weight: 600;
    }
`;

export const EmptySection = styled.div`
    width: 40px;
`;
